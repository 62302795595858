import * as RadixSelect from '@radix-ui/react-select';
import type {
  SelectContentProps as RadixSelectContentProps,
  SelectItemProps as RadixSelectItemProps,
  SelectProps as RadixSelectProps,
} from '@radix-ui/react-select';
import { Icon16 } from '@spaceduck/icons';
import { Icon24 } from '@spaceduck/icons';
import { clsx } from 'clsx';
import React, { Fragment } from 'react';

import Spinner from '@ui/Spinner';
import styles from './StatusSelect.module.scss';

const { Remove } = Icon16;
const { Check, Down, Right } = Icon24;

export const STATUS_SPECIAL_VALUES = {
  NO_STATUS: '-',
  CLEAR_STATUS: '--',
};

export type SelectItem = {
  description?: string;
  isDisabled?: boolean;
  label: string;
  icon?: React.ReactNode;
  value: string;
};

type SelectGroup = {
  label?: string;
  options: SelectItem[];
};

type SelectItemProps = {
  children?: React.ReactNode;
  className?: string;
  showIndicator?: boolean;
} & RadixSelectItemProps;

type SupportedTyped = 'project' | 'mediaGroup';

type SelectProps = {
  className?: string;
  contentClassName?: string;
  placeholder: React.ReactNode;
  selectContentProps?: RadixSelectContentProps;
  selectGroups: SelectGroup[];
  showGroupLabels?: boolean;
  showIndicator?: boolean;
  style?: React.CSSProperties;
  loading?: boolean;
  type?: SupportedTyped;
  showClear?: boolean;
  showNoStatus?: boolean;
  onValueChange: (value?: string | null) => void;
} & Exclude<RadixSelectProps, 'onValueChange'>;

export default function Select({
  className,
  contentClassName,
  placeholder,
  selectContentProps,
  selectGroups,
  showGroupLabels = false,
  showIndicator = false,
  style,
  loading = false,
  type,
  showClear = true,
  showNoStatus = false,
  onValueChange,
  ...radixProps
}: SelectProps) {
  const onValueChangeRadix = (value: string) => {
    if (value === STATUS_SPECIAL_VALUES.CLEAR_STATUS) {
      onValueChange();
    } else if (value === STATUS_SPECIAL_VALUES.NO_STATUS) {
      onValueChange(null);
    } else {
      onValueChange(value);
    }
  };

  return (
    <div className={className} style={style}>
      <RadixSelect.Root {...radixProps} onValueChange={onValueChangeRadix}>
        <RadixSelect.Trigger className={styles.selectTrigger}>
          <RadixSelect.Value
            placeholder={
              <div className={styles.placeholder}>
                <span className={styles.dotWrapper}>
                  <span className={styles.dot} />
                </span>
                {placeholder}
              </div>
            }
          />
          <RadixSelect.Icon className={styles.selectIcon}>
            {!loading && <Down size={16} />}
            {loading && <Spinner />}
          </RadixSelect.Icon>
        </RadixSelect.Trigger>
        <RadixSelect.Portal>
          <RadixSelect.Content
            {...selectContentProps}
            className={clsx(styles.selectContent, contentClassName)}
            position="popper"
            sideOffset={6}
          >
            <RadixSelect.ScrollUpButton className={styles.selectScrollButton}>
              <Right size={16} />
            </RadixSelect.ScrollUpButton>
            <RadixSelect.Viewport className={styles.selectViewport}>
              {showClear && (
                <SelectItem
                  value={STATUS_SPECIAL_VALUES.CLEAR_STATUS}
                  showIndicator={showIndicator}
                >
                  <div className={clsx(styles.option, styles.clear)}>
                    <span className={styles.dotWrapper}>
                      <Remove size={16} />
                    </span>
                    <div className={styles.label}>Clear</div>
                  </div>
                </SelectItem>
              )}
              {showNoStatus && (
                <SelectItem
                  value={STATUS_SPECIAL_VALUES.NO_STATUS}
                  showIndicator={showIndicator}
                >
                  <div className={clsx(styles.option, styles.clear)}>
                    <span className={styles.dotWrapper}>
                      <Remove size={16} />
                    </span>
                    <div className={styles.label}>No status</div>
                  </div>
                </SelectItem>
              )}
              {selectGroups.map((selectGroup, idx) => (
                <Fragment key={idx}>
                  {idx !== 0 && (
                    <RadixSelect.Separator className={styles.selectSeparator} />
                  )}
                  <SelectGroup
                    selectGroup={selectGroup}
                    showGroupLabel={showGroupLabels ?? selectGroups.length > 1}
                    showIndicator={showIndicator}
                    type={type}
                  />
                </Fragment>
              ))}
            </RadixSelect.Viewport>
            <RadixSelect.ScrollDownButton className={styles.selectScrollButton}>
              <Down size={20} />
            </RadixSelect.ScrollDownButton>
          </RadixSelect.Content>
        </RadixSelect.Portal>
      </RadixSelect.Root>
    </div>
  );
}

const SelectGroup = ({
  selectGroup,
  showGroupLabel,
  showIndicator,
  type,
}: {
  selectGroup: SelectGroup;
  showGroupLabel?: boolean;
  showIndicator?: boolean;
  type?: SupportedTyped;
}) => {
  return (
    <RadixSelect.Group className={styles.group}>
      {showGroupLabel && (
        <RadixSelect.Label className={styles.selectLabel}>
          {selectGroup.label}
        </RadixSelect.Label>
      )}
      {selectGroup.options.map(({ isDisabled, label, value, icon }, idx) => (
        <SelectItem
          value={value}
          key={idx}
          disabled={isDisabled}
          showIndicator={showIndicator}
        >
          <div className={styles.option}>
            <span className={styles.dotWrapper}>
              {icon}
              {!icon && (
                <span
                  className={clsx(type && styles[type], styles.dot, styles[value])}
                />
              )}
            </span>
            <div className={styles.label}>{label}</div>
          </div>
        </SelectItem>
      ))}
    </RadixSelect.Group>
  );
};

const SelectItem = React.forwardRef(
  (
    { children, className, showIndicator, ...props }: SelectItemProps,
    forwardedRef: React.Ref<HTMLDivElement>
  ) => {
    return (
      <RadixSelect.Item
        className={clsx(styles.selectItem, className)}
        {...props}
        ref={forwardedRef}
      >
        <RadixSelect.ItemText className={styles.selectItemText}>
          {children}
        </RadixSelect.ItemText>
        {showIndicator && (
          <RadixSelect.ItemIndicator className={styles.selectItemIndicator}>
            <Check />
          </RadixSelect.ItemIndicator>
        )}
      </RadixSelect.Item>
    );
  }
);
