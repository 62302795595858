import { safeUrl } from './api/util';
import type { DetailsModelTab } from './types/MediaGroup';

const home = (): string => '/';

const ACTIVE_TAB_TO_URL_NAME: Record<Exclude<DetailsModelTab, 'info'>, string> = {
  comment: 'comments',
  browseRepository: 'browse-repository',
  references: 'references',
  tableOfContent: 'table-of-content',
  activity: 'activity',
};

const mediaGroup = ({
  mediaGroupId,
  view = null,
}: { mediaGroupId: string; view?: string | null }): string =>
  view === null
    ? safeUrl`/media-groups/${mediaGroupId}`
    : safeUrl`/media-groups/${mediaGroupId}/${view}`;

const mediaGroupModal = ({
  mediaGroupId,
  view,
  activeTab,
}: { mediaGroupId: string; view: string | null; activeTab: DetailsModelTab }) => {
  if (activeTab === 'info') {
    return view === null
      ? safeUrl`/media-groups/${mediaGroupId}`
      : safeUrl`/media-groups/${mediaGroupId}/${view}`;
  }
  const name = ACTIVE_TAB_TO_URL_NAME[activeTab];
  if (view === null) {
    return safeUrl`/media-groups/${mediaGroupId}/${name}`;
  }
  return safeUrl`/media-groups/${mediaGroupId}/${view}/${name}`;
};

const activeTab = ({
  mediaGroupId,
  view = null,
  activeTab,
}: {
  mediaGroupId: string;
  view?: string | null;
  activeTab: keyof typeof ACTIVE_TAB_TO_URL_NAME;
}): string => {
  const name = ACTIVE_TAB_TO_URL_NAME[activeTab];
  return view === null
    ? safeUrl`/media-groups/${mediaGroupId}/${name}`
    : safeUrl`/media-groups/${mediaGroupId}/${view}/${name}`;
};
type activeTabParams = Parameters<typeof activeTab>[0];

const mediaGroupComments = (params: Omit<activeTabParams, 'activeTab'>): string =>
  activeTab({ ...params, activeTab: 'comment' });

const mediaGroupBrowseRepository = (
  params: Omit<activeTabParams, 'activeTab'>
): string => activeTab({ ...params, activeTab: 'browseRepository' });

const mediaGroupReferences = (params: Omit<activeTabParams, 'activeTab'>): string =>
  activeTab({ ...params, activeTab: 'references' });

const mediaGroupTableOfContent = (params: Omit<activeTabParams, 'activeTab'>): string =>
  activeTab({ ...params, activeTab: 'tableOfContent' });

const mediaGroupActivity = (params: Omit<activeTabParams, 'activeTab'>): string =>
  activeTab({ ...params, activeTab: 'activity' });

const workspace = ({ workspaceId }: { workspaceId: string }) =>
  safeUrl`/workspaces/${workspaceId}`;

const workspaceRepository = ({ workspaceId }: { workspaceId: string }) =>
  safeUrl`/workspaces/${workspaceId}/collections`;

const workspaceTrashItems = ({ workspaceId }: { workspaceId: string }) =>
  safeUrl`/workspaces/${workspaceId}/trash/content`;

const workspaceTrashSpaces = ({ workspaceId }: { workspaceId: string }) =>
  safeUrl`/workspaces/${workspaceId}/trash/spaces`;

const workspaceSpaces = ({ workspaceId }: { workspaceId: string }) =>
  safeUrl`/workspaces/${workspaceId}/spaces`;

const workspaceCategories = ({ workspaceId }: { workspaceId: string }) =>
  safeUrl`/workspaces/${workspaceId}/categories`;

const workspaceCategory = ({ categoryId }: { categoryId: string }) =>
  safeUrl`/category/${categoryId}`;

const workspaceInbox = ({ workspaceId }: { workspaceId: string }) =>
  safeUrl`/workspaces/${workspaceId}/inbox`;

const workspaceSettings = ({ workspaceId }: { workspaceId: string }) =>
  safeUrl`/workspaces/${workspaceId}/settings`;

const workspaceSettingsGeneral = ({ workspaceId }: { workspaceId: string }) =>
  safeUrl`/workspaces/${workspaceId}/settings/general`;

const workspaceSettingsPeople = ({ workspaceId }: { workspaceId: string }) =>
  safeUrl`/workspaces/${workspaceId}/settings/people`;

const workspaceSettingsTags = ({ workspaceId }: { workspaceId: string }) =>
  safeUrl`/workspaces/${workspaceId}/settings/tags`;

const workspaceSettingsSpaces = ({ workspaceId }: { workspaceId: string }) =>
  safeUrl`/workspaces/${workspaceId}/settings/spaces`;

const workspaceSettingsNotifications = ({
  workspaceId,
}: {
  workspaceId: string;
}) => safeUrl`/workspaces/${workspaceId}/settings/notifications`;

const workspaceSettingsMediaGroupCategories = ({
  workspaceId,
}: {
  workspaceId: string;
}) => safeUrl`/workspaces/${workspaceId}/settings/categories`;

const workspaceSettingsBillingAndPlan = ({
  workspaceId,
}: {
  workspaceId: string;
}) => safeUrl`/workspaces/${workspaceId}/settings/billing-and-plan`;

const workspaceSettingsIntegrations = ({
  workspaceId,
}: {
  workspaceId: string;
}) => safeUrl`/workspaces/${workspaceId}/settings/integrations`;

const userSettingsProfile = () => '/settings/profile';

const userSettingsWorkspaces = () => '/settings/workspaces';

const userSettingsCreateWorkspace = () => '/settings/workspaces/new';

const space = ({ projectId }: { projectId: string }) => safeUrl`/spaces/${projectId}`;

const spaceProgress = ({ projectId }: { projectId: string }) =>
  safeUrl`/spaces/${projectId}/progress`;

const spaceProgressList = ({ projectId }: { projectId: string }) =>
  safeUrl`/spaces/${projectId}/progress/list/`;

const spaceProgressKanban = ({ projectId }: { projectId: string }) =>
  safeUrl`/spaces/${projectId}/progress/kanban/`;

const spaceLibrary = ({ projectId }: { projectId: string }) =>
  safeUrl`/spaces/${projectId}/library`;

const routes = {
  home,
  mediaGroup,
  mediaGroupModal,
  mediaGroupComments,
  mediaGroupBrowseRepository,
  mediaGroupReferences,
  mediaGroupTableOfContent,
  mediaGroupActivity,
  space,
  spaceLibrary,
  spaceProgress,
  spaceProgressList,
  spaceProgressKanban,
  workspace,
  workspaceRepository,
  workspaceTrashItems,
  workspaceTrashSpaces,
  workspaceCategories,
  workspaceCategory,
  workspaceInbox,
  workspaceSettings,
  workspaceSettingsGeneral,
  workspaceSettingsPeople,
  workspaceSettingsSpaces,
  workspaceSettingsTags,
  workspaceSettingsNotifications,
  workspaceSettingsMediaGroupCategories,
  workspaceSettingsBillingAndPlan,
  workspaceSettingsIntegrations,
  workspaceSpaces,
  userSettingsProfile,
  userSettingsWorkspaces,
  userSettingsCreateWorkspace,
} as const;

type Routes = typeof routes;
type ParamFor<T extends keyof Routes> = Parameters<Routes[T]>[0] extends undefined
  ? never
  : Parameters<Routes[T]>[0];

export function urlFor<T extends keyof Routes>(
  name: ParamFor<T> extends undefined ? never : T,
  params: ParamFor<T>
): string;
export function urlFor<T extends keyof Routes>(
  name: ParamFor<T> extends undefined ? T : never,
  params?: unknown
): string;
export function urlFor<T extends keyof Routes>(name: T, params: any) {
  const resolver = routes[name];
  return resolver(params);
}

export function absoluteUrlFor<T extends keyof Routes>(name: T, params: any) {
  const resolver = routes[name];
  const url = new URL(window.location.toString());
  url.pathname = resolver(params);
  return url.toString();
}
