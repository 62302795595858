import { Icon16, Icon24 } from '@spaceduck/icons';
import { EditorContent, type FocusPosition, type JSONContent } from '@tiptap/react';
import type { SuggestionProps } from '@tiptap/suggestion';
import { clsx } from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useMakeComment } from '@api/comment';
import { useCommentsStore } from '@stores/useCommentsStore';
import Button from '@ui/Button';
import Spinner from '@ui/Spinner';
import type { ActionableInvitesInComment } from './Comment';
import styles from './CommentTextbox.module.scss';
import { EmojiPicker } from './EmojiButton';
import { MentionPopover, type Mention as MentionSuggestion } from './MentionPopover';
import { useTiptap } from './Tiptap';

const { Mention } = Icon16;
const { AddReaction, SendComment } = Icon24;

export function CommentTextBox({
  autofocus,
  className,
  document,
  emojiPickerAsTippy = false,
  isInEditMode,
  isLoading,
  onCancel,
  onSubmit,
  placeholder,
  value,
}: {
  autofocus?: FocusPosition;
  className?: string;
  document?: JSONContent;
  emojiPickerAsTippy?: boolean;
  isInEditMode?: boolean;
  isLoading: boolean;
  onCancel?: () => void;
  onSubmit: (content: string, document?: JSONContent) => Promise<void>;
  placeholder?: string;
  value?: string;
}) {
  const { scrollToRef } = useCommentsStore();
  const textContainerRef = useRef<HTMLDivElement | null>(null);

  const textAreaRef = useRef<HTMLDivElement | null>(null);
  const [isPosting, setIsPosting] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState<MentionSuggestion[]>(
    []
  );
  const [selectSuggestionHandler, setSuggestionHandler] =
    useState<(suggestion: MentionSuggestion) => void>();
  const updateSuggestionHandler = (props?: SuggestionProps<MentionSuggestion>) => {
    if (!props) {
      setSuggestionHandler(undefined);
      return;
    }
    const handler = (suggestion: MentionSuggestion) => {
      if (!suggestion) {
        return;
      }
      props.command(suggestion);
    };

    setSuggestionHandler(() => handler);
  };

  let content: JSONContent | string | undefined;
  if (document) {
    content = document;
  } else {
    content = value?.split('\n').join('<br/>');
  }
  const { register, handleSubmit, setValue, watch } = useForm<{
    content: string;
  }>({
    defaultValues: {
      content: '',
    },
  });
  register('content', {
    required: true,
  });
  const [suggestionsOpen, setSuggestionsOpen] = useState(false);

  // Check for CMD/CTRL + Enter to submit
  const handleKeyDown = (event: KeyboardEvent) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter' && !isPosting) {
      handleSubmit(submit)();
      return true;
    }

    if (event.key === 'Escape') {
      onCancel?.();
      return true;
    }

    if (event.key === 'Enter') {
      return true;
    }
    return false;
  };

  const editor = useTiptap({
    suggestionConfiguration: {
      open: () => setSuggestionsOpen(true),
      close: () => setSuggestionsOpen(false),
      updateSuggestionHandler,
      setFilteredSuggestions,
      handleKeyDown,
    },
    options: {
      content,
      autofocus,
      onUpdate: ({ editor }) => {
        if (editor.getText() === '') {
          setValue('content', '');
          return;
        }
        const text = JSON.stringify(editor.getJSON());
        setValue('content', text);
      },
    },
    placeholder,
  });

  const submit = async () => {
    setIsPosting(true);
    await onSubmit('', editor?.getJSON());
    editor?.commands.clearContent();
    setValue('content', '');
    setIsPosting(false);
  };

  const watchContent = watch('content');

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '0';
      const { scrollHeight } = textAreaRef.current;
      textAreaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [textAreaRef, watchContent]);

  useEffect(() => {
    if (textAreaRef.current) {
      scrollToRef(textAreaRef);
    }
  }, [textAreaRef]);

  const appendEmoji = (emoji: string) => {
    if (textAreaRef.current) {
      editor?.commands.insertContent(emoji);
      editor?.commands.focus();
    }
  };

  const appendAt = () => {
    if (textAreaRef.current && editor) {
      const beforeNode = editor.view.state.selection.$to.nodeBefore;
      const beforeText = beforeNode?.text;

      if (beforeText?.length && beforeText.charAt(beforeText.length - 1) !== ' ') {
        editor.commands.insertContent(' ');
      }
      editor.commands.insertContent('@');
      editor.commands.focus();
    }
  };

  useEffect(() => {
    if (editor && autofocus) {
      editor.commands.focus(autofocus);
    }
  }, [editor, autofocus]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div ref={textContainerRef}>
      <div className={clsx('commentBox', styles.commentBox, className)}>
        <EditorContent
          editor={editor}
          className={styles.textarea}
          ref={textAreaRef}
          onKeyDown={(event) => {
            handleKeyDown(event.nativeEvent);
          }}
          rows={1}
        />
        {suggestionsOpen && selectSuggestionHandler && (
          <MentionPopover
            isOpen={suggestionsOpen}
            selectSuggestion={selectSuggestionHandler}
            suggestions={filteredSuggestions}
            textAreaRef={textAreaRef}
            anchor={<span className={styles.popoverAnchor} />}
            containerRef={textContainerRef}
          />
        )}
        <div className={styles.actions}>
          <div className={styles.auxActions}>
            <EmojiPicker
              onClick={appendEmoji}
              asTippy={emojiPickerAsTippy}
              containerRef={emojiPickerAsTippy ? textContainerRef : undefined}
            >
              <Button size="sm" variant="icon">
                <AddReaction size={20} />
              </Button>
            </EmojiPicker>
            <Button size="sm" variant="icon" onClick={appendAt}>
              <Mention size={20} />
            </Button>
          </div>
          <div className={styles.mainActions}>
            {!!onCancel && (
              <Button
                type="button"
                onClick={() => onCancel()}
                size="sm"
                variant="ghost"
              >
                Cancel
              </Button>
            )}
            {isInEditMode ? (
              <Button
                disabled={isPosting || watchContent.length === 0}
                onClick={handleSubmit(submit)}
                size="sm"
                variant="secondary"
              >
                Save
              </Button>
            ) : (
              <Button
                className={styles.submitButton}
                disabled={isPosting || watchContent.length === 0}
                isSquare={true}
                onClick={handleSubmit(submit)}
                size="sm"
                variant="light"
              >
                <SendComment />
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function NewCommentTextBox({
  isLoading,
  mediaGroupId,
  onCancel,
  parentCommentId,
  placeholder,
  onComment,
}: {
  isLoading: boolean;
  mediaGroupId: string;
  onCancel?: () => void;
  parentCommentId?: string;
  placeholder?: string;
  onComment?: (invitable: ActionableInvitesInComment) => void;
}) {
  const { mutateAsync } = useMakeComment(mediaGroupId);

  const handleSubmit = async (content: string, document?: JSONContent) => {
    const { invitable, id, projectId } = await mutateAsync({
      content,
      parentId: parentCommentId,
      document,
    });

    if (projectId) {
      onComment?.({
        commentId: id,
        userIds: invitable,
        projectId,
      });
    }
  };

  return (
    <CommentTextBox
      isLoading={isLoading}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      placeholder={placeholder}
    />
  );
}
