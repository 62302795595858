import type { Editor } from '@tiptap/react';
import clsx from 'clsx';
import { useEffect } from 'react';

import { useNotesStore } from '@stores/useNotesStore';
import styles from './DetailsModalNotesContent.module.scss';
import SingleFieldEdit from './SingleFieldEdit';
import TipTapEditor from './tiptap/Editor';

export default function DetailsModalNotesContent({
  asQuickView = false,
  className,
  editor,
  handleImageUploadInputChange,
  imageUploadInputRef,
  mediaGroupId,
}: {
  asQuickView?: boolean;
  className?: string;
  editor: Editor | null;
  handleImageUploadInputChange?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => boolean | undefined;
  imageUploadInputRef?: React.RefObject<HTMLInputElement>;
  mediaGroupId: string;
}) {
  const { isNotesSaving, isQuickViewNotesSaving } = useNotesStore();

  useEffect(() => {
    if (isNotesSaving || isQuickViewNotesSaving) {
      const preventLeave = (event: BeforeUnloadEvent) => event.preventDefault();
      window.addEventListener('beforeunload', preventLeave);
      return () => window.removeEventListener('beforeunload', preventLeave);
    }
  }, [isNotesSaving, isQuickViewNotesSaving]);

  return (
    <div className={clsx(styles.container, asQuickView && 'quickView', className)}>
      <SingleFieldEdit
        displayAs="h1"
        displayStyle={styles.h1}
        enterToSubmit
        fallback="Untitled document"
        fieldName="label"
        fieldLabel="title"
        mediaGroupId={mediaGroupId}
        onKeyDown={(ev) => {
          if (ev?.key === 'ArrowDown') {
            const { selectionStart, selectionEnd, value } = ev.currentTarget;

            if (selectionStart === selectionEnd && selectionEnd === value.length) {
              ev.preventDefault();
              ev.stopPropagation();
              editor?.commands.focus();
              return true;
            }
          }
          return false;
        }}
        required={true}
        switchFocus={() => editor?.commands.focus()}
        textareaStyle={styles.h1}
      />
      <TipTapEditor
        mediaGroupId={mediaGroupId}
        className={styles.editor}
        editor={editor}
      />
      <input
        accept="image/png, image/gif, image/jpeg"
        className={styles.hiddenInput}
        id={asQuickView ? 'quickViewNotesImageUploadInput' : 'notesImageUploadInput'}
        multiple
        onChange={handleImageUploadInputChange}
        ref={imageUploadInputRef}
        type="file"
      />
    </div>
  );
}
