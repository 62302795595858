import ListItem from '@tiptap/extension-list-item';

export default ListItem.extend({
  addNodeView() {
    return () => {
      const listItem = document.createElement('li');
      const contentWrapper = document.createElement('div');
      contentWrapper.classList.add('listItem');
      listItem.append(contentWrapper);

      return {
        dom: listItem,
        contentDOM: contentWrapper,
      };
    };
  },
  addKeyboardShortcuts() {
    return {
      Enter: () => {
        const beforeNode = this.editor.view.state.selection.$to.nodeBefore;
        const beforeText = beforeNode?.text;

        if (beforeText?.length && beforeText.charAt(beforeText.length - 1) === '+') {
          return false;
        }

        if (beforeText === undefined) {
          const afterNode = this.editor.view.state.selection.$to.nodeAfter;
          const afterText = afterNode?.text;

          if (!afterText?.trim()) {
            return this.editor.commands.liftListItem(this.name);
          }
        }

        return this.editor.commands.splitListItem(this.name);
      },
      Tab: () => {
        this.editor.commands.sinkListItem(this.name);
        return true;
      },
      'Shift-Tab': () => this.editor.commands.liftListItem(this.name),
    };
  },
});
