export const sleep = (ms: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
};

export const runAfter = async <T>(fn: () => Promise<T>, ms: number): Promise<T> => {
  await sleep(ms);
  return fn();
};

export const runToCompletion = async <T>(awaitable: PromiseLike<T>): Promise<void> => {
  try {
    await awaitable;
  } catch (error) {
    // pass
  }
};

export const createInvalidate = <T>(refresh: () => Promise<T>) => {
  const state: { current: Promise<T> | null } = { current: null };

  return async () => {
    if (state.current !== null) {
      await runToCompletion(state.current);
    }

    if (state.current === null) {
      // We are the first call back in!
      // Wrap promise to clear out the state first
      state.current = new Promise((resolve, reject) => {
        refresh().then(
          (value) => {
            state.current = null;
            resolve(value);
          },
          (reason) => {
            state.current = null;
            reject(reason);
          }
        );
      });
    }
    // Return the new current value
    return state.current;
  };
};
