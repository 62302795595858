import { Icon24 } from '@spaceduck/icons';
import { type Table, flexRender } from '@tanstack/react-table';
import clsx from 'clsx';
import { useMemo } from 'react';

import type { Row, TableColumnMeta } from '@/types/Category';
import type {
  CellSelection,
  CellSelectionAction,
} from '@hooks/useCategoryCellSelection';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import type { ContextMenuItemProps } from '@ui/ContextMenu';
import TableBody, { MemoizedTableBody } from './TableBody';
import styles from './Table.module.scss';

const { Link, Pencil, TrashCan } = Icon24;

export default function RenderedTable({
  categoryProperties,
  clearSelectedCell,
  handleDelete,
  handleCopyLink,
  handleRenameItem,
  selectedCell,
  setSelectedCell,
  selectedRows,
  table,
}: {
  categoryProperties: TableColumnMeta[];
  clearSelectedCell: () => void;
  handleCopyLink: () => void;
  handleDelete: () => void;
  handleRenameItem: () => Promise<void>;
  selectedCell: CellSelection;
  setSelectedCell: React.Dispatch<CellSelectionAction>;
  selectedRows: string[];
  table: Table<Row>;
}) {
  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    const colSizes: { [key: string]: number } = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i]!;
      colSizes[`--header-${header.id}-size`] = header.getSize();
    }
    return colSizes;
  }, [
    table.getState().columnSizingInfo,
    table.getState().columnSizing,
    categoryProperties.length,
  ]);

  const singleSelectionMenuItems: ContextMenuItemProps[] = [
    {
      content: 'Rename',
      icon: <Pencil size={20} />,
      onClick: handleRenameItem,
    },
    {
      content: 'Copy link',
      icon: <Link size={20} />,
      onClick: handleCopyLink,
    },
    {
      content: 'Delete item',
      icon: <TrashCan size={20} />,
      onClick: handleDelete,
    },
  ];

  const multipleSelectionMenuItems: ContextMenuItemProps[] = [
    {
      content: 'Delete items',
      icon: <TrashCan size={20} />,
      onClick: handleDelete,
    },
  ];

  const { containerRef } = useOnClickOutside<HTMLTableElement>({
    callback: () => {
      // Timeout to allow persist to happen first
      setTimeout(() => {
        clearSelectedCell?.();
      }, 0);
    },
  });

  return (
    <table
      className={styles.table}
      ref={containerRef}
      style={{
        ...columnSizeVars,
        width: table.getTotalSize(),
      }}
    >
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <th
                  key={header.id}
                  style={{
                    width:
                      header.id === '_gap'
                        ? 'auto'
                        : `calc(var(--header-${header.id}-size) * 1px)`,
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                  {!header.isPlaceholder &&
                    !(header.id === '_addColumn' || header.id === '_gap') && (
                      <div
                        {...{
                          onDoubleClick: () => header.column.resetSize(),
                          onMouseDown: header.getResizeHandler(),
                          onTouchStart: header.getResizeHandler(),
                          className: clsx(
                            styles.resizer,
                            header.column.getIsResizing() && styles.isResizing
                          ),
                        }}
                      />
                    )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      {table.getState().columnSizingInfo.isResizingColumn ? (
        <MemoizedTableBody
          multipleSelectionMenuItems={multipleSelectionMenuItems}
          selectedCell={selectedCell}
          selectedRows={selectedRows}
          setSelectedCell={setSelectedCell}
          singleSelectionMenuItems={singleSelectionMenuItems}
          table={table}
        />
      ) : (
        <TableBody
          multipleSelectionMenuItems={multipleSelectionMenuItems}
          selectedCell={selectedCell}
          selectedRows={selectedRows}
          setSelectedCell={setSelectedCell}
          singleSelectionMenuItems={singleSelectionMenuItems}
          table={table}
        />
      )}
    </table>
  );
}
