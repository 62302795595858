import Providers from '@components/Providers';
import Router from '@components/Router';
import Toaster from '@lib/toast';
import { EnvironmentIndicator } from './components/EnvironmentIndicator';
import { AnalyticsConsentPopover } from './lib/analytics/AnalyticsConsentPopover';
import { css } from './lib/css';

export default function App() {
  return (
    <Providers>
      <EnvironmentIndicator />
      <Router />
      <Toaster
        className="globalToaster"
        position="bottom-right"
        richColors
        style={css({ '--width': 'calc(100% - var(--offset) * 2)' })}
      />
      <AnalyticsConsentPopover />
    </Providers>
  );
}
