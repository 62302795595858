import { useRef, useState } from 'react';

type TimeoutId = ReturnType<typeof setTimeout>;

const DEFAULT_MS = 400;

export const useDebouncedValue = <T>(value: T, ms?: number) => {
  const [currentValue, setCurrentValue] = useState(value);
  const timeoutId = useRef<TimeoutId | undefined>();

  if (value !== currentValue) {
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => setCurrentValue(value), ms ?? DEFAULT_MS);
  }

  return currentValue;
};
