import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu';
import { Icon24 } from '@spaceduck/icons';
import Button from '@ui/Button';
import DropdownMenu from '@ui/DropdownMenu';
import { clsx } from 'clsx';
import { useRef } from 'react';
import styles from './CommentToolbar.module.scss';
import { EmojiPicker } from './EmojiButton';
const { AddReaction, Comment, Menu, Pencil, Resolve, TrashCan } = Icon24;

export default function CommentToolbar({
  isReply,
  isResolved,
  onDelete,
  onEdit,
  onReact,
  onReply,
  onResolve,
  showEditTools,
}: {
  isReply?: boolean;
  isResolved?: boolean;
  onDelete?: () => void;
  onEdit?: () => void;
  onReact?: (emoji: string) => void;
  onReply?: () => void;
  onResolve?: (setOn: boolean) => void;
  showEditTools?: boolean;
}) {
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <div
      ref={ref}
      className={clsx(styles.commentToolbar, isResolved && styles.isResolved)}
    >
      <EmojiPicker onClick={onReact} containerRef={ref}>
        <Button size="sm" title="Add a reaction" variant="icon" type="button">
          <AddReaction size={20} />
        </Button>
      </EmojiPicker>
      {!isReply && (
        <>
          <Button
            size="sm"
            title={isResolved ? 'Mark as unresolved' : 'Mark as resolved'}
            variant="icon"
            onClick={() => onResolve?.(!isResolved)}
            type="button"
          >
            <Resolve className={clsx(isResolved && styles.resolved)} size={20} />
          </Button>
          <Button
            size="sm"
            title="Add a reply"
            variant="icon"
            type="button"
            onClick={onReply}
          >
            <Comment size={20} />
          </Button>
        </>
      )}
      {showEditTools && (
        <DropdownMenu
          align="end"
          alignOffset={-4}
          className={styles.dropdownMenu}
          modal={false}
          triggerContent={
            <Button size="sm" title="More actions" variant="icon">
              <Menu size={16} />
            </Button>
          }
          side="bottom"
          sideOffset={8}
        >
          <div className="menu">
            <DropdownMenuItem onSelect={onEdit} asChild>
              <Button variant="menu" iconBefore={<Pencil size={16} />}>
                Edit
              </Button>
            </DropdownMenuItem>
            <DropdownMenuItem onSelect={onDelete} asChild>
              <Button variant="menu" iconBefore={<TrashCan size={16} />}>
                Delete
              </Button>
            </DropdownMenuItem>
          </div>
        </DropdownMenu>
      )}
    </div>
  );
}
