import {
  type BillingInterval,
  type BillingPlan,
  getSubscriptionInfo,
  getUpgradeSubscriptionLink,
} from '@spaceduck/api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { QueryDisabledError } from './errors';

export const billingKeys = {
  info: (workspaceId: string | null) => ['billing', 'info', workspaceId] as const,
};

export const useGetSubscriptionInfo = (workspaceId: string | null) => {
  const enabled = !!workspaceId;
  return useQuery({
    enabled,
    queryKey: billingKeys.info(workspaceId),
    queryFn: () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      return getSubscriptionInfo(workspaceId);
    },
  });
};

export const useUpgradeSubscriptionLink = () => {
  return useMutation({
    mutationFn: ({
      workspaceId,
      plan,
      interval,
    }: {
      workspaceId: string;
      plan: BillingPlan;
      interval: BillingInterval;
    }) => getUpgradeSubscriptionLink(workspaceId, plan, interval),
  });
};
