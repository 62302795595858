import {
  type MediaGroupSearchFilterProperties,
  mediaGroupSearchFilterLabels,
} from '@spaceduck/api';
import clsx from 'clsx';
import { type RefObject, useCallback, useState } from 'react';

import { FilterIcon } from '@icons/FilterIcon';
import InputDropdownMenuPopover, { InputPopoverMenuItem } from '@ui/InputDropdownMenu';
import styles from './SearchFilters.module.scss';

export type SearchFilterProperties = MediaGroupSearchFilterProperties | 'date';

type SearchFilter = {
  property: SearchFilterProperties;
  helperText: string;
  icon: JSX.Element;
  index?: number;
};

type SearchFiltersProps = {
  onSelect: (value: SearchFilterProperties) => void;
  inputRef: RefObject<HTMLInputElement>;
  availableFilters: SearchFilterProperties[];
  query: string;
  active: boolean;
  onPreview: (value: SearchFilterProperties) => void;
};

const SearchFilterItem = ({
  filter,
  active,
}: {
  filter: SearchFilter;
  active: boolean;
}) => {
  return (
    <div className={clsx(styles.filterItem, active && styles.active)}>
      <div>
        <div className={styles.icon}>{filter.icon}</div>
      </div>
      <div>
        <h2>{mediaGroupSearchFilterLabels[filter.property]}:</h2>
        <p>{filter.helperText}</p>
      </div>
    </div>
  );
};

const SearchFilters = (props: SearchFiltersProps) => {
  const {
    onSelect,
    availableFilters,
    inputRef,
    query,
    active: open,
    onPreview,
  } = props;
  const [highlightedIndex, setHighlightedIndex] = useState<number>();
  const matchFilters = useCallback(
    (filterValue?: string) =>
      availableFilters.filter((filter) => {
        return (
          filterValue !== undefined &&
          mediaGroupSearchFilterLabels[filter].includes(filterValue)
        );
      }),
    availableFilters
  );

  const matchedFilters = matchFilters(query);
  const onFilterSelect = (filter: SearchFilter) => {
    onSelect(filter.property);
  };

  const filters: SearchFilter[] = [
    {
      helperText: 'Filter by content title',
      icon: <FilterIcon variant="filter" filter="title" />,
      property: 'title',
    },
    {
      helperText: 'Filter by tag',
      icon: <FilterIcon variant="filter" filter="tag" />,
      property: 'tag',
    },
    {
      helperText: 'Filter by content kind',
      icon: <FilterIcon variant="filter" filter="contentType" />,
      property: 'contentType',
    },
    {
      helperText: 'Filter by content source',
      icon: <FilterIcon variant="filter" filter="source" />,
      property: 'source',
    },
    {
      helperText: "Filter by the author's name",
      icon: <FilterIcon variant="filter" filter="author" />,
      property: 'author',
    },
    {
      helperText: 'Filter by text or image content (OCR)',
      icon: <FilterIcon variant="filter" filter="text" />,
      property: 'text',
    },
    {
      helperText: 'Filter by content category',
      icon: <FilterIcon variant="filter" filter="category" />,
      property: 'category',
    },
    {
      helperText: 'Filter by content status',
      icon: <FilterIcon variant="filter" filter="status" />,
      property: 'status',
    },
    {
      helperText: 'Filter by color',
      icon: <FilterIcon variant="filter" filter="color" />,
      property: 'color',
    },
    {
      helperText: 'Filter by date',
      icon: <FilterIcon variant="filter" filter="date" />,
      property: 'date',
    },
    {
      helperText: 'Filter by space name',
      icon: <FilterIcon variant="filter" filter="project" isPrivate={false} />,
      property: 'project',
    },
  ];

  const availableFilter = filters
    .filter((filter) => matchedFilters.includes(filter.property))
    .map((filter, i) => {
      filter.index = i;
      return filter;
    });

  return (
    <InputDropdownMenuPopover
      onIndexPreview={(index: number) => {
        const filter = availableFilter[index];
        if (filter) {
          onPreview(filter.property);
        }
      }}
      className={styles.filters}
      open={open && !!availableFilter.length}
      inputRef={inputRef}
      isLoading={false}
      numberOfItems={availableFilter.length}
      onSelectIndex={(index) => {
        const filter = availableFilter[index];
        if (filter) {
          onSelect(filter.property);
        }
      }}
      onHighlightedIndexChange={setHighlightedIndex}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
    >
      <div className={styles.helperText}>
        To exclude items in a search, prefix them with “-”. -tag: dark mode
      </div>
      <div className={styles.patterns}>
        <div className={styles.patternsLabel}>FILTER PATTERNS:</div>
        {availableFilter.map((suggestion, j) => (
          <InputPopoverMenuItem<SearchFilter>
            key={j}
            onSelect={onFilterSelect}
            suggestion={suggestion}
            onMouseOver={() => setHighlightedIndex(suggestion.index)}
          >
            <SearchFilterItem
              filter={suggestion}
              active={suggestion.index === highlightedIndex}
            />
          </InputPopoverMenuItem>
        ))}
      </div>
    </InputDropdownMenuPopover>
  );
};

export default SearchFilters;
