import type { ProjectDetailDTO, ProjectMode } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';
import { useNavigate } from 'react-router-dom';

import { urlFor } from '@/urls';
import { useDeleteProject } from '@api/project';
import {
  projectModes,
  useEditProjectModal,
} from '@components/admin/CreateProjectsModal';
import { ProjectMode as ProjectModeIcon } from '@components/icons';
import Button from '@ui/Button';
import { useDeleteConfirmModal } from '@ui/ConfirmModal';
import DropdownMenu, { MenuItem } from '@ui/DropdownMenu';
import Tooltip from '@ui/Tooltip';
import createToast from '@utils/createToast';
import ProjectMenuAccordion from './ProjectMenuAccordion';
import styles from './ProjectMenuDetails.module.scss';

type ProjectMenuDetailsProps = {
  project: ProjectDetailDTO;
};

const { MenuMore } = Icon16;
const { Pencil, TrashCan } = Icon24;

const getModeBadge = (access: ProjectMode | null) => {
  if (access === null) {
    return null;
  }

  const info = projectModes[access];

  return (
    <Tooltip content={info.description} size="medium" variant="tertiary">
      <Button
        className={styles.nonHoverButton}
        iconBefore={<ProjectModeIcon mode={access} set={16} />}
        size="xs"
        type="button"
        variant="outlined"
      >
        {info.label}
      </Button>
    </Tooltip>
  );
};

export default function ProjectMenuDetails({ project }: ProjectMenuDetailsProps) {
  const navigate = useNavigate();

  if (!project) return null;

  const { capabilities } = project;
  const editCapability = capabilities?.find(({ capability }) => capability === 'edit');
  const canEdit = editCapability?.capable ?? false;

  const { open: openEditProjectModal } = useEditProjectModal(project);
  const { mutateAsync: deleteProject } = useDeleteProject();
  const { open: openDeleteConfirmModal } = useDeleteConfirmModal({
    title: 'Delete space',
    subtitle: 'This action will delete this Space and move it to the trash.',
    confirmText: 'Yes, delete space',
    onConfirm: async () => {
      await deleteProject(project.id);
      createToast({
        bodyText: 'Space deleted',
        iconVariant: 'success',
      });
      navigate(urlFor('workspaceSpaces', { workspaceId: project.workspace.id }));
    },
  });

  const modeBadge = getModeBadge(project?.mode ?? null);

  return (
    <ProjectMenuAccordion
      label="Details"
      alternateAction={
        canEdit && (
          <DropdownMenu
            align="end"
            className={styles.dropdownMenu}
            triggerContent={
              <Button
                isSquare
                onClick={(ev) => {
                  ev.preventDefault();
                }}
                size="xs"
                type="button"
                variant="icon"
              >
                <MenuMore />
              </Button>
            }
          >
            <MenuItem
              iconBefore={<Pencil size={20} />}
              onClick={(ev) => {
                ev.stopPropagation();
                openEditProjectModal?.();
              }}
            >
              Edit space...
            </MenuItem>
            <MenuItem
              iconBefore={<TrashCan size={20} />}
              onClick={(ev) => {
                ev.stopPropagation();
                openDeleteConfirmModal();
              }}
            >
              Delete
            </MenuItem>
          </DropdownMenu>
        )
      }
    >
      <h4 className="title4">{project.label}</h4>
      {!project.description ? (
        <div className={styles.descriptionPlaceholder}>
          <span onClick={() => openEditProjectModal?.()}>Add description</span>
        </div>
      ) : (
        <div>{project.description}</div>
      )}
      {!!modeBadge && <div className={styles.mode}>{modeBadge}</div>}
    </ProjectMenuAccordion>
  );
}
