import { Icon24 } from '@spaceduck/icons';

import { NewMediaGroupDropdown } from '@components/NewMediaGroupDropdown';
import styles from './EmptyCategoryButton.module.scss';

const { Empty } = Icon24;

export default function EmptyTableButton({
  categoryId,
  shouldShow,
}: {
  categoryId: string;
  shouldShow: boolean;
}) {
  if (!shouldShow) return null;

  return (
    <div className={styles.noData}>
      <NewMediaGroupDropdown align="start" mediaGroupAttributes={{ categoryId }}>
        <button>
          <Empty size={20} />
          <span>Empty. Click to add content.</span>
        </button>
      </NewMediaGroupDropdown>
    </div>
  );
}
