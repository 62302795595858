import {
  type BillingInterval,
  type BillingPlan,
  type UpgradeablePlan,
  getManageSubscriptionLink,
} from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';
import { clsx } from 'clsx';
import capitalize from 'lodash/capitalize';
import { Fragment, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { useGetSubscriptionInfo, useUpgradeSubscriptionLink } from '@api/billing';
import { catchApiErrorIntoToast } from '@api/util';
import useWorkspaceId from '@hooks/useWorkspaceId';
import Button, { ButtonLink } from '@ui/Button';
import Spinner from '@ui/Spinner';
import Tooltip from '@ui/Tooltip';
import createToast from '@utils/createToast';
import { openMailTo } from '@utils/openMailTo';
import styles from './BillingAndPlan.module.scss';
import { Container, Header, TopNav } from './common';

const { Info, Open2 } = Icon16;
const { Check, Open, PricingEnterprise, PricingPro, PricingTeam } = Icon24;

type Feature = {
  title: string;
  tooltip?: string;
};

// TODO: Export type from schema
type SubscriptionInfoType = {
  kind: 'success';
  plan: BillingPlan;
  interval: string | null;
  seats: number;
  assignedSeats: number;
};

// TODO: Get data from backend
const proPlanFeatures: Feature[] = [
  {
    title: 'Unlimited items',
  },
  {
    title: 'Unlimited spaces',
  },
  {
    title: '200GB workspace storage',
  },
  {
    title: '500MB maximum upload size',
  },
  {
    title: 'AI Tagging & Organization',
  },
  {
    title: 'AI-Powered search',
  },
];

const teamPlanFeatures: Feature[] = [
  {
    title: 'Unlimited items',
  },
  {
    title: 'Unlimited spaces',
  },
  {
    title: '500GB workspace storage',
  },
  {
    title: 'Unlimited upload size',
  },
  {
    title: 'AI Tagging & Organization',
  },
  {
    title: 'AI-Powered search',
  },
  {
    title: 'AI Edit (Coming soon)',
  },
  {
    title: 'AI Chat (Coming soon)',
  },
];

const enterprisePlanFeatures: Feature[] = [
  {
    title: 'Unlimited items',
  },
  {
    title: 'Unlimited spaces',
  },
  {
    title: '500GB workspace storage',
  },
  {
    title: 'Unlimited upload size',
  },
  {
    title: 'AI Tagging & Organization',
  },
  {
    title: 'AI-Powered search',
  },
  {
    title: 'AI Edit (Coming soon)',
  },
  {
    title: 'AI Chat (Coming soon)',
  },
  {
    title: 'Live collaboration (Coming soon)',
  },
  {
    title: 'SSO',
  },
  {
    title: 'Private Support Channel',
  },
  {
    title: 'Custom onboarding & rollout',
  },
];

const planDisplayName: Record<BillingPlan, string> = {
  enterprise: 'team',
  team: 'ultra',
  pro: 'pro',
  starter: 'duckling',
};

const standardPlans: BillingPlan[] = ['pro', 'team'] as const;
const getFeaturesTitle = (plan: BillingPlan) => {
  switch (plan) {
    case 'pro':
      return 'Pro includes...';
    case 'team':
      return 'Everything in Pro, plus';
    case 'enterprise':
      return 'Everything in Ultra, plus';
    default:
      return '';
  }
};

function getFeatures(plan: BillingPlan): Feature[] | null {
  switch (plan) {
    case 'pro':
      return proPlanFeatures;
    case 'team':
      return teamPlanFeatures;
    case 'enterprise':
      return enterprisePlanFeatures;
    default:
      return null;
  }
}

function getPricing(plan: BillingPlan) {
  switch (plan) {
    case 'pro':
      return {
        monthlyPricePerMonth: '12.50',
        yearlyPricePerMonth: '10',
        yearlyDiscount: 'Save 20%',
      };
    case 'team': {
      return {
        monthlyPricePerMonth: '18.75',
        yearlyPricePerMonth: '15',
        yearlyDiscount: 'Save 20%',
      };
    }
    case 'enterprise': {
      return {
        monthlyPricePerMonth: '25',
        yearlyPricePerMonth: '20',
        yearlyDiscount: 'Save 20%',
      };
    }
    default:
      return null;
  }
}

const navigateToManageSubscriptionLink = async (workspaceId: string) => {
  try {
    await catchApiErrorIntoToast(async () => {
      const subscriptionLink = await getManageSubscriptionLink(workspaceId);
      window.location.href = subscriptionLink.redirectUrl;
    })();
  } catch (e) {
    console.error(e);
  }
};

export default function BillingAndPlanPage() {
  const workspaceId = useWorkspaceId();
  const { data: subscriptionInfo } = useGetSubscriptionInfo(workspaceId);
  const [subscriptionLinkLoading, setSubscriptionLinkLoading] = useState(false);

  const { mutateAsync: upgradeSubscriptionLink } = useUpgradeSubscriptionLink();
  const [paymentInterval, _setPaymentInterval] = useState<BillingInterval>('year');

  const upgradePlan = async (plan: BillingPlan, interval: BillingInterval) => {
    if (!workspaceId) {
      console.error('Failed to get upgrade link due to missing workspaceId');
      createToast({
        titleText: 'Upgrade error',
        bodyText: 'Failed to prepare for upgrade. Please try again later.',
        iconVariant: 'warning',
      });
      return;
    }
    const data = await upgradeSubscriptionLink({ workspaceId, plan, interval });

    window.location.href = data.redirectUrl;
  };

  const upgradePlanCallback = useCallback(upgradePlan, [workspaceId]);
  return (
    <>
      <TopNav
        title="Manage Billing and Plan"
        currentBreadcrumb="Billing & Plan"
        owner="workspace"
      >
        {['pro', 'team'].includes(subscriptionInfo?.plan || 'starter') && (
          <Button
            size="sm"
            type="button"
            variant="outlined"
            onClick={async () => {
              if (!workspaceId) {
                return;
              }
              setSubscriptionLinkLoading(true);
              await navigateToManageSubscriptionLink(workspaceId);
              setSubscriptionLinkLoading(false);
            }}
            disabled={subscriptionLinkLoading}
          >
            Manage subscription
            {!subscriptionLinkLoading && <Open size={16} />}
            {subscriptionLinkLoading && <Spinner size={16} />}
          </Button>
        )}
      </TopNav>
      {workspaceId && (
        <Container className={styles.container}>
          <Header>
            <h1>Billing & Plan</h1>
            <p>Manage your organization billing & plan.</p>
          </Header>
          {subscriptionInfo?.plan === 'enterprise' ? (
            <EnterprisePlan
              setSubscriptionLinkLoading={setSubscriptionLinkLoading}
              subscriptionLinkLoading={subscriptionLinkLoading}
              workspaceId={workspaceId}
            />
          ) : (
            <StandardPlans
              paymentInterval={paymentInterval}
              setSubscriptionLinkLoading={setSubscriptionLinkLoading}
              subscriptionLinkLoading={subscriptionLinkLoading}
              subscriptionInfo={subscriptionInfo}
              upgradePlanCallback={upgradePlanCallback}
              workspaceId={workspaceId}
            />
          )}
        </Container>
      )}
    </>
  );
}

type PlanProps = {
  paymentInterval: BillingInterval;
  setSubscriptionLinkLoading: React.Dispatch<React.SetStateAction<boolean>>;
  subscriptionLinkLoading: boolean;
  subscriptionInfo?: SubscriptionInfoType;
  upgradePlanCallback: (plan: UpgradeablePlan, interval: BillingInterval) => void;
  workspaceId: string;
};

const EnterprisePlan = ({
  setSubscriptionLinkLoading,
  subscriptionLinkLoading,
  workspaceId,
}: Pick<
  PlanProps,
  'setSubscriptionLinkLoading' | 'subscriptionLinkLoading' | 'workspaceId'
>) => {
  return (
    <div className={styles.plansWrapper}>
      <div className={clsx(styles.plansTable, styles.enterprisePlanTable)}>
        <article className={styles.planDetails}>
          <div className={styles.upgrade}>
            <PricingEnterprise color="#0C8D62" /> Enterprise
          </div>
          <div className={styles.enterprisePlanHeader}>
            <h2>Custom Plan</h2>
            <Button disabled={true} size="md" type="button" variant="outlined">
              Current Plan
            </Button>
          </div>
          <div className={styles.features}>
            <h4>{getFeaturesTitle('enterprise')}</h4>
            <div className={styles.twoColumns}>
              {enterprisePlanFeatures?.map((feature, idx) => (
                <Feature key={idx} feature={feature} />
              ))}
            </div>
          </div>
        </article>
      </div>
      <div>
        <div className={styles.goEnterprise}>
          <div className={styles.text}>
            <h5>Need more capabilities or having an issue?</h5>
          </div>
          <Button
            onClick={() => openMailTo('[SALES]', '')}
            size="sm"
            type="button"
            variant="secondary"
          >
            Get in touch
          </Button>
        </div>
      </div>
      <div className={styles.footer}>
        <ul>
          <li>
            {!subscriptionLinkLoading && (
              <Link
                to="#"
                onClick={async () => {
                  setSubscriptionLinkLoading(true);
                  await navigateToManageSubscriptionLink(workspaceId);
                  setSubscriptionLinkLoading(false);
                }}
              >
                Manage my subscription
              </Link>
            )}
            {subscriptionLinkLoading && <span>Manage my subscription</span>}
          </li>
          <li>
            {/* TODO: Update link */}
            <a
              href="#0"
              onClick={(ev) => {
                ev.preventDefault();
                openMailTo('I need help with a billing issue', '');
              }}
            >
              I need help with a billing issue
            </a>
          </li>

          <li>
            <ButtonLink
              to={'#0'}
              onClick={() => {
                // TODO: Add downgrade action
              }}
              variant="link"
            >
              Downgrade my plan
            </ButtonLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

const StandardPlans = ({
  paymentInterval,
  setSubscriptionLinkLoading,
  subscriptionLinkLoading,
  subscriptionInfo,
  upgradePlanCallback,
  workspaceId,
}: PlanProps) => {
  if (!(workspaceId && subscriptionInfo?.plan)) return null;

  return (
    <div className={styles.plansWrapper}>
      <div className={styles.plansHeader}>
        <h2>Upgrade or manage your plan</h2>
      </div>
      <div className={styles.plansTable}>
        {standardPlans.map((upgradeToPlan, i) => (
          <Fragment key={i}>
            {i > 0 && <div className={styles.divider} />}
            <PlanDetails
              currentPlan={subscriptionInfo.plan}
              upgradeToPlan={upgradeToPlan}
              paymentInterval={paymentInterval}
              upgradePlan={upgradePlanCallback}
              subscriptionLinkLoading={subscriptionLinkLoading}
              setSubscriptionLinkLoading={setSubscriptionLinkLoading}
            />
          </Fragment>
        ))}
      </div>
      <div className={styles.actions}>
        <div>Want to see all features?</div>
        <ButtonLink
          className={styles.iconButton}
          iconAfter={<Open2 />}
          size="sm"
          type="button"
          variant="secondary"
          to="https://www.spaceduck.com/pricing"
          target="_blank"
        >
          Compare plans
        </ButtonLink>
      </div>
      <div>
        <div className={styles.goEnterprise}>
          <div className={styles.text}>
            <h5>Need more capabilities...</h5>
            {/* TODO: Add learn more link */}
            <p>
              See Spaceduck Enterprise.{' '}
              <a
                href="https://www.spaceduck.com/pricing"
                className="infoLink"
                target="_blank"
                rel="noreferrer"
              >
                Learn more
              </a>
            </p>
          </div>
          <Button
            onClick={() => openMailTo('[SALES]', '')}
            size="sm"
            type="button"
            variant="secondary"
          >
            Get in touch
          </Button>
        </div>
      </div>
      <div className={styles.footer}>
        <ul>
          <li>
            {!subscriptionLinkLoading && (
              <Link
                to="#"
                onClick={async () => {
                  setSubscriptionLinkLoading(true);
                  await navigateToManageSubscriptionLink(workspaceId);
                  setSubscriptionLinkLoading(false);
                }}
              >
                Manage my subscription
              </Link>
            )}
            {subscriptionLinkLoading && <span>Manage my subscription</span>}
          </li>
          <li>
            {/* TODO: Update link */}
            <a
              href="#0"
              onClick={(ev) => {
                ev.preventDefault();
                openMailTo('I need help with a billing issue', '');
              }}
            >
              I need help with a billing issue
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

const PlanDetails = ({
  currentPlan,
  paymentInterval,
  upgradePlan,
  upgradeToPlan,
  setSubscriptionLinkLoading,
  subscriptionLinkLoading,
}: {
  currentPlan: BillingPlan;
  paymentInterval: BillingInterval;
  upgradeToPlan: BillingPlan;
  upgradePlan: (plan: UpgradeablePlan, interval: BillingInterval) => void;
  setSubscriptionLinkLoading: React.Dispatch<React.SetStateAction<boolean>>;
  subscriptionLinkLoading: boolean;
}) => {
  const onUpgradePlan = () => {
    if (upgradeToPlan === 'team' || upgradeToPlan === 'pro') {
      upgradePlan(upgradeToPlan, paymentInterval);
    }
  };
  const features = upgradeToPlan
    ? getFeatures(upgradeToPlan)
    : currentPlan === 'enterprise'
      ? getFeatures(currentPlan)
      : null;
  const pricingData = upgradeToPlan ? getPricing(upgradeToPlan) : null;

  const workspaceId = useWorkspaceId();
  const currentPlanIsEnterprise = upgradeToPlan === null;
  const nextPlanIsEnterprise = upgradeToPlan === 'enterprise';

  return (
    <article className={styles.planDetails}>
      {upgradeToPlan && (
        <div className={styles.upgrade}>
          {upgradeToPlan === 'pro' && <PricingPro color="#0267CA" />}
          {upgradeToPlan === 'team' && <PricingTeam color="#804CF5" />}{' '}
          {capitalize(planDisplayName[upgradeToPlan] ?? '')}
        </div>
      )}
      {!(nextPlanIsEnterprise || currentPlanIsEnterprise) && (
        <div className={clsx('body6', styles.pricing)}>
          <span className={clsx(styles.highlight)}>
            {paymentInterval === 'month' &&
              !!pricingData?.monthlyPricePerMonth &&
              `$${pricingData.monthlyPricePerMonth}`}
            {paymentInterval === 'year' &&
              !!pricingData?.yearlyPricePerMonth &&
              `$${pricingData.yearlyPricePerMonth}`}
          </span>
          <span>
            per user / per month
            <br />
            billed {paymentInterval === 'month' ? 'monthly' : 'yearly'}
          </span>
        </div>
      )}
      <div className={styles.upgradeCTA}>
        {upgradeToPlan === currentPlan ? (
          <Button disabled={true} size="md" type="button" variant="outlined">
            Current Plan
          </Button>
        ) : upgradeToPlan === 'pro' && currentPlan === 'team' ? (
          <Button
            onClick={async () => {
              if (!workspaceId) {
                return;
              }
              setSubscriptionLinkLoading(true);
              try {
                await navigateToManageSubscriptionLink(workspaceId);
              } finally {
                setSubscriptionLinkLoading(false);
              }
            }}
            size="md"
            type="button"
            variant="secondary"
            disabled={subscriptionLinkLoading}
          >
            Downgrade
          </Button>
        ) : upgradeToPlan === 'team' || upgradeToPlan === 'enterprise' ? (
          <Button disabled size="md" type="button" variant="ghost">
            Coming soon
          </Button>
        ) : (
          <Button
            onClick={() => onUpgradePlan()}
            size="md"
            type="button"
            variant="primary"
          >
            Upgrade
          </Button>
        )}
      </div>
      {!!features?.length && (
        <div className={styles.features}>
          <h4>{getFeaturesTitle(upgradeToPlan)}</h4>
          {features?.map((feature, idx) => (
            <Feature key={idx} feature={feature} />
          ))}
        </div>
      )}
    </article>
  );
};

const Feature = ({ feature }: { feature: Feature }) => {
  return (
    <div className={styles.feature}>
      <Check className={styles.check} />
      {feature.title}
      {!!feature?.tooltip && (
        <Tooltip content={feature.tooltip}>
          <span className={styles.tooltip}>
            <Info />
          </span>
        </Tooltip>
      )}
    </div>
  );
};
