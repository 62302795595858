import { useCallback, useMemo } from 'react';

import googleIcon from '@/assets/img/icn-google.svg';
import { useIsWebView } from '@/hooks/useIsWebView';
import { useSession } from '@hooks/useAuth';
import { filterOAuth2Methods } from '@spaceduck/api';
import Button from '@ui/Button';
import AlertBanner from '../ui/AlertBanner';
import styles from './AuthSelector.module.scss';

const getStartURL = (
  base: string,
  nextUrl: string | undefined,
  userHint: string | null | undefined
) => {
  const url = new URL(base, window.location?.toString());
  if (nextUrl) {
    url.searchParams.set('next', nextUrl);
  }
  if (userHint) {
    url.searchParams.set('user_hint', userHint);
  }
  return url.toString();
};

type AuthSelectorProps = {
  nextUrl?: string;
  userHint?: string | null;
  disableGoogleLogin?: boolean;
  disableAppleLogin?: boolean;
  disableMSLogin?: boolean;
  disableEmailLogin?: boolean;
};

const AuthSelector = ({
  nextUrl,
  userHint,
  disableAppleLogin,
  disableGoogleLogin,
  disableMSLogin,
}: AuthSelectorProps) => {
  const session = useSession();

  const isWebView = useIsWebView();

  const oauthMethods = useMemo(() => {
    if (session?.kind === 'not-authenticated') {
      const methods = filterOAuth2Methods(session.methods);
      return new Map(filterOAuth2Methods(methods).map((m) => [m.namespace, m]));
    }
    return null;
  }, [session]);

  const googleLogin = useMemo(() => oauthMethods?.get('google'), [oauthMethods]);

  const appleLogin = useMemo(() => oauthMethods?.get('apple'), [oauthMethods]);

  const microsoftLogin = useMemo(() => oauthMethods?.get('microsoft'), [oauthMethods]);

  const start = useCallback(
    ({ href }: { href: string }) => {
      window.open(getStartURL(href, nextUrl, userHint), '_self');
    },
    [nextUrl, userHint]
  );

  return (
    <div className={styles.container}>
      {isWebView && (
        <AlertBanner variant="warning">
          It appears you are using a webview. For the best experience, please use your
          browser.
        </AlertBanner>
      )}
      {!disableGoogleLogin && googleLogin ? (
        <Button
          disabled={isWebView}
          onClick={() => start(googleLogin)}
          size="lg"
          variant="secondary"
        >
          <img src={googleIcon} alt="Google 'G' logo" width={24} height={24} />
          Continue with Google
        </Button>
      ) : null}

      {!disableAppleLogin && appleLogin ? (
        <Button
          disabled={isWebView}
          onClick={() => start(appleLogin)}
          size="lg"
          variant="secondary"
        >
          Continue with Apple
        </Button>
      ) : null}

      {!disableMSLogin && microsoftLogin ? (
        <Button
          disabled={isWebView}
          onClick={() => start(microsoftLogin)}
          size="lg"
          variant="secondary"
        >
          Continue with Microsoft
        </Button>
      ) : null}
    </div>
  );
};

export default AuthSelector;
