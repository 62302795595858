import type { MediaGroupDTO } from '@spaceduck/api';
import type { SuggestionOptions, SuggestionProps } from '@tiptap/suggestion';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useInView } from 'react-intersection-observer';

import { useListMediaGroups } from '@api/mediaGroup';
import { ContentType } from '@components/icons';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { css } from '@lib/css';
import { useSearchStore } from '@stores/useSearchStore';
import Card from '@ui/Card';
import ScrollArea from '@ui/ScrollArea';
import Spinner from '@ui/Spinner';
import styles from './ContentBlockList.module.scss';

export type CommandListRef = {
  onKeyDown: NonNullable<
    ReturnType<NonNullable<SuggestionOptions['render']>>['onKeyDown']
  >;
};

type EmbeddableElement = { id: string; type: 'mediaGroup' };

const ContentBlockList = forwardRef<CommandListRef, SuggestionProps>((props, ref) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentBlockListRef = useRef<HTMLDivElement>(null);
  const { ref: loadMoreRef, inView } = useInView();
  const [activeEmbeddable, setActiveEmbeddable] = useState<string | null>(null);
  const [embeddableList, setEmbeddableList] = useState<EmbeddableElement[]>();
  const [debounceIsLoading, setDebounceIsLoading] = useState(false);

  const [searchValue, setSearchvalue] = useState('');
  const debouncedSearch = useCallback(
    debounce(async (value: string) => {
      setDebounceIsLoading(false);
      setSearchvalue(value);
    }, 150),
    []
  );

  useEffect(() => {
    setDebounceIsLoading(true);
    debouncedSearch(props.query);
  }, [props.query]);

  const { excludeProjectLibraries } = useSearchStore();

  const workspaceId = useWorkspaceId();
  const {
    data: mediaGroupData,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    enabled,
  } = useListMediaGroups(workspaceId, {
    excludeProjectLibraries,
    title: [searchValue],
  });

  const mediaGroups = mediaGroupData?.pages.flatMap((page) => page.mediaGroups);

  useEffect(() => {
    if (enabled && inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [enabled, inView, isFetchingNextPage, hasNextPage]);

  useEffect(() => {
    const activeElement = contentBlockListRef.current?.querySelector('.active');
    activeElement?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
  }, [activeEmbeddable]);

  useEffect(() => {
    if (!mediaGroups) return;
    setEmbeddableList([
      ...(mediaGroups?.map((mg) => {
        return {
          id: mg.id,
          type: 'mediaGroup',
        } as EmbeddableElement;
      }) || []),
    ]);
  }, [mediaGroupData?.pages]);

  useEffect(() => {
    const firstEmbeddable =
      embeddableList?.length && embeddableList.length > 0
        ? embeddableList[0]
        : undefined;
    if (!firstEmbeddable) {
      setActiveEmbeddable(null);
    } else if (!activeEmbeddable && firstEmbeddable) {
      setActiveEmbeddable(firstEmbeddable.id);
    } else if (activeEmbeddable) {
      const found = embeddableList?.find(
        (embeddable) => embeddable.id === activeEmbeddable
      );
      if (!found) {
        setActiveEmbeddable(firstEmbeddable.id);
      }
    }
  }, [embeddableList]);

  const insertBackLink = (mediaGroupId: string) => {
    props.editor
      .chain()
      .focus()
      .insertContentAt(props.range, [
        {
          type: 'outgoing-link',
          attrs: {
            id: mediaGroupId,
          },
        },
      ])
      .run();
  };

  const insertContentBlock = (mediaGroupId: string) => {
    props.editor
      .chain()
      .focus()
      .insertContentAt(props.range, [
        {
          type: 'content-block',
          attrs: {
            id: mediaGroupId,
          },
        },
      ])
      .run();
  };

  const upHandler = () => {
    if (embeddableList && embeddableList.length > 0 && activeEmbeddable) {
      const activeIndex = embeddableList.findIndex(
        (embeddable) => embeddable.id === activeEmbeddable
      );

      if (activeIndex > 0 && activeEmbeddable?.[activeIndex - 1]) {
        setActiveEmbeddable(embeddableList[activeIndex - 1]?.id ?? null);
      }
    }
  };

  const downHandler = () => {
    if (embeddableList && embeddableList.length > 0) {
      if (activeEmbeddable) {
        const activeIndex = embeddableList.findIndex(
          (embeddable) => embeddable.id === activeEmbeddable
        );

        const LOAD_MORE_ITEMS_THRESHOLD = 3;

        if (
          embeddableList.length - activeIndex < LOAD_MORE_ITEMS_THRESHOLD &&
          enabled &&
          hasNextPage &&
          !isFetchingNextPage
        ) {
          fetchNextPage();
        }

        if (activeIndex < 0) return;

        if (activeIndex + 1 < embeddableList.length) {
          const nextActiveMediaGroup = embeddableList[activeIndex + 1];
          if (nextActiveMediaGroup) {
            setActiveEmbeddable(nextActiveMediaGroup.id);
          }
        }
      }
    }
  };

  const enterHandler = () => {
    if (activeEmbeddable) {
      if (
        mediaGroups?.find((mediaGroup) => mediaGroup.id === activeEmbeddable)
          ?.contentType === 'document'
      ) {
        insertBackLink(activeEmbeddable);
      } else {
        insertContentBlock(activeEmbeddable);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (containerRef.current?.checkVisibility?.()) {
        if (event.key === 'ArrowUp') {
          upHandler();
          return true;
        }

        if (event.key === 'ArrowDown') {
          downHandler();
          return true;
        }

        if (event.key === 'Enter') {
          enterHandler();
          return true;
        }
      }

      return false;
    },
  }));

  const handleListItemClick = (embeddableId: string | null) => {
    setActiveEmbeddable(embeddableId || null);
  };

  const handleCardClick = (embeddableId: string) => {
    if (
      mediaGroups?.find((mediaGroup) => mediaGroup.id === embeddableId)?.contentType ===
      'document'
    ) {
      insertBackLink(embeddableId);
    } else {
      insertContentBlock(embeddableId);
    }
  };

  if (isError || !props.editor.isEditable) return null;

  if (isLoading || debounceIsLoading)
    return (
      <div className={clsx(styles.container, styles.isLoading)}>
        <Spinner />
      </div>
    );

  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.contentBlockList} ref={contentBlockListRef}>
        <ScrollArea
          className={styles.scrollArea}
          orientation="vertical"
          style={css({
            '--width': '100%',
            '--maxHeight': '100%',
          })}
        >
          {mediaGroups?.map((mediaGroup) => {
            return (
              <div
                className={clsx(
                  styles.contentBlockListItem,
                  activeEmbeddable === mediaGroup.id && `${styles.active} active`
                )}
                key={mediaGroup.id}
                onClick={() => handleListItemClick(mediaGroup.id)}
                onDoubleClick={() => {
                  handleListItemClick(mediaGroup.id);
                  handleCardClick(mediaGroup.id);
                }}
              >
                <ContentType contentType={mediaGroup.contentType} />
                <div className={styles.contentBlockInfo}>
                  <div className={styles.label}>
                    {mediaGroup.label || mediaGroup.linkUrlSource}
                  </div>
                  <div className={styles.bucket}>{getBucketLabel(mediaGroup)}</div>
                </div>
              </div>
            );
          })}
          {hasNextPage && (
            <div ref={loadMoreRef} style={{ width: '100%', height: '10px' }}>
              {/* Triggers page fetch when in view */}
            </div>
          )}
        </ScrollArea>
      </div>
      <div className={styles.focusedItem}>
        {activeEmbeddable &&
          mediaGroups?.find((mediaGroup) => mediaGroup.id === activeEmbeddable) && (
            <div onClick={() => handleCardClick(activeEmbeddable)}>
              <Card
                mediaGroup={
                  mediaGroups.find((mediaGroup) => mediaGroup.id === activeEmbeddable)!
                }
                hideFooter
                className={styles.card}
              />
            </div>
          )}
      </div>
    </div>
  );
});

ContentBlockList.displayName = 'ContentBlockList';

const getBucketLabel = (mediaGroup: MediaGroupDTO) => {
  if (mediaGroup.project) return mediaGroup.project.label;
  return 'Repository';
};

export default ContentBlockList;
