import { Icon24 } from '@spaceduck/icons';

import { FileIcon } from '@components/FileIcon';
import styles from './FilePreview.module.scss';

const { Download } = Icon24;

export type FilePreviewProps = {
  mediaType: string;
  name?: string;
  url: string;
};

export const FilePreview = ({ mediaType, name, url }: FilePreviewProps) => {
  return (
    <div className={styles.wrapper}>
      <FileIcon mediaType={mediaType} />
      <div className={styles.name}>{name}</div>
      <a
        className={styles.downloadButton}
        href={url}
        target="_blank"
        download
        rel="noreferrer"
      >
        <Download />
        <div>Download</div>
      </a>
    </div>
  );
};
