import { Icon16, type IconProps } from '@spaceduck/icons';

import type { SelectableTypes } from '@/types/Category';

const {
  CalendarDate,
  Checkbox,
  Email,
  MultiSelect,
  Numeric,
  Phone,
  Relationship,
  Select,
  Title,
  Url,
} = Icon16;

export const CategoryKind = ({
  kind,
  iconProps,
}: {
  kind: SelectableTypes;
  iconProps?: IconProps;
}) => {
  switch (kind) {
    case 'checkbox':
      return <Checkbox {...iconProps} />;
    case 'date':
      return <CalendarDate {...iconProps} />;
    case 'email':
      return <Email {...iconProps} />;
    case 'multi-select':
      return <MultiSelect {...iconProps} />;
    case 'number':
      return <Numeric {...iconProps} />;
    case 'phone':
      return <Phone {...iconProps} />;
    case 'relationship':
      return <Relationship {...iconProps} />;
    case 'select':
      return <Select {...iconProps} />;
    case 'text':
      return <Title {...iconProps} />;
    case 'url':
      return <Url {...iconProps} />;
    default:
      return null;
  }
};
