import type { MediaDTO, MediaGroupDTO } from '@spaceduck/api';
import { MediaType } from '@spaceduck/utils';
import clsx from 'clsx';
import prettyBytes from 'pretty-bytes';

import { FileIcon } from '@components/FileIcon';
import styles from './FileBlock.module.scss';

function getFileTypeLabel(mediaType: string) {
  switch (mediaType) {
    case 'application/pdf':
      return 'PDF';
    case 'application/zip':
      return 'Zip';
    default:
      return 'File';
  }
}

function getFileSize(size?: number) {
  if (!size) return null;

  return prettyBytes(size);
}

export default function FileBlock({
  mediaGroup,
}: {
  mediaGroup: MediaGroupDTO;
}) {
  if (!mediaGroup.media?.length) return <NoMedia mediaGroup={mediaGroup} />;

  const isBookmark = mediaGroup.contentType === 'bookmark';

  return (
    <div className={clsx(styles.container, styles.single)}>
      {mediaGroup.media.map((media) => (
        <Media
          label={mediaGroup.label}
          isBookmark={isBookmark}
          media={media}
          key={media.id}
        />
      ))}
    </div>
  );
}

const NoMedia = ({ mediaGroup }: { mediaGroup: MediaGroupDTO }) => {
  const isBookmark = mediaGroup.contentType === 'bookmark';
  if (!isBookmark) return null;

  return (
    <div className={clsx(styles.container, styles.single)}>
      <div
        className={styles.media}
        onDoubleClick={() => {
          if (mediaGroup.linkUrl) {
            window.open(mediaGroup.linkUrl);
          }
        }}
      >
        <div className={styles.info}>
          <div className={styles.label}>{mediaGroup.linkUrlSource}</div>
          <div className={styles.fileInfo}>
            <span>Bookmark</span>
          </div>
        </div>
        <div className={styles.icon}>
          <FileIcon mediaType={MediaType.URI_LIST} />
        </div>
      </div>
    </div>
  );
};

const Media = ({
  isBookmark,
  media,
  label,
}: {
  isBookmark?: boolean;
  media: MediaDTO;
  label: string;
}) => {
  const { assetUrl, mediaType, sizeBytes } = media;
  const isVideo = mediaType.startsWith('video/');

  let fileType = getFileTypeLabel(mediaType);
  if (isBookmark) {
    fileType = 'Bookmark';
  } else if (isVideo) {
    fileType = 'Video';
  }

  return (
    <div className={styles.media} onDoubleClick={() => window.open(assetUrl)}>
      <div className={styles.info}>
        <div className={styles.label}>{label}</div>
        <div className={styles.fileInfo}>
          <span>{fileType}</span>
          {sizeBytes && (
            <>
              •<span>{getFileSize(sizeBytes)}</span>
            </>
          )}
        </div>
      </div>
      <div className={styles.icon}>
        <FileIcon mediaType={isBookmark ? MediaType.URI_LIST : mediaType} />
      </div>
    </div>
  );
};
