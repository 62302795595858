import ErrorEgg from '@assets/img/ErrorEgg';
import { OuterSpace } from '@layouts/Stars';
import sharedProjectStyles from '@pages/projects/Shared.module.scss';
import Button from '@ui/Button';
import styles from './GhostTown.module.scss';

const TITLES: [string, ...string[]] = [
  "It's a ghost town here!",
  "I'm lonely...",
  'Where is everyone?',
  "It's a little quiet here.",
  'Hello? Anyone there?',
];

import { choice } from '@/utils/random';
import { useEffect, useState } from 'react';

export default function GhostTown({
  description,
  ctaText,
  onCtaClick,
  children,
}: {
  description: string;
  ctaText?: string;
  onCtaClick?: () => void;
  children?: React.ReactNode;
}) {
  const [title, setTitle] = useState('');

  useEffect(() => {
    const title = choice(TITLES);
    setTitle(title);
  }, []);

  return (
    <OuterSpace className={styles.noResultsWrapper} light={false}>
      <div className={styles.noResults}>
        <ErrorEgg />
        <h1 className="h6">{title}</h1>
        <h2 className="body5">{description}</h2>
        {children}
        {ctaText && (
          <Button
            className={sharedProjectStyles.createbutton}
            onClick={onCtaClick}
            size="sm"
            variant="primary"
          >
            {ctaText}
          </Button>
        )}
      </div>
    </OuterSpace>
  );
}
