import type { MediaDTO } from '@spaceduck/api';
import type { GenericSlide, SlideImage } from 'yet-another-react-lightbox';

export interface SlideVideo extends GenericSlide {
  src: string;
  height?: number;
  poster?: string | null;
  width?: number;
}

export const createLightboxSlide = (media: MediaDTO[]) => {
  return media.map(({ assetUrl, posterUrl, mediaType, height, source, width }) => {
    if (mediaType.startsWith('video/')) {
      return {
        height,
        src: source?.embedUrl ?? '',
        poster: posterUrl ?? undefined,
        width,
      } as SlideVideo;
    }
    return {
      height,
      src: assetUrl,
      width,
      type: 'image',
    } as SlideImage;
  });
};
