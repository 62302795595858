import type { BooleanFieldInstance } from '@spaceduck/api';

import Checkbox from '@ui/Checkbox';
import type { CommonProps } from '../InfoCategories';
import styles from './Checkbox.module.scss';

export default function CheckboxField({
  canEdit,
  instances,
  updateField,
}: CommonProps) {
  const checkboxInstance = (instances as BooleanFieldInstance[])[0];
  const handleChange = async (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (!canEdit) return;

    const checked = !!ev.currentTarget.checked;
    await updateField?.([{ ...checkboxInstance, value: checked }]);
  };

  return (
    <div className={styles.wrapper}>
      <Checkbox
        className={styles.checkbox}
        checked={!!checkboxInstance?.value}
        disabled={!canEdit}
        onChange={handleChange}
      />
    </div>
  );
}
