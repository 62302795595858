import type { IconProps } from '../types/IconProps';

export default function Icon({ className, color = '#F0F1F4', size = 16 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.77778C0 1.34822 0.346616 1 0.774188 1H3.40173C3.82931 1 4.17592 1.34822 4.17592 1.77778C4.17592 2.20733 3.82931 2.55556 3.40173 2.55556H1.54838V4.26667C1.54838 4.69622 1.20176 5.04444 0.774188 5.04444C0.346616 5.04444 0 4.69622 0 4.26667V1.77778Z"
      />
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8242 1.77778C11.8242 1.34822 12.1708 1 12.5984 1H15.226C15.6535 1 16.0001 1.34822 16.0001 1.77778V4.26667C16.0001 4.69622 15.6535 5.04444 15.226 5.04444C14.7984 5.04444 14.4518 4.69622 14.4518 4.26667V2.55556H12.5984C12.1708 2.55556 11.8242 2.20733 11.8242 1.77778Z"
      />
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.91016 1.77778C5.91016 1.34822 6.25677 1 6.68434 1H9.31189C9.73946 1 10.0861 1.34822 10.0861 1.77778C10.0861 2.20733 9.73946 2.55556 9.31189 2.55556H6.68434C6.25677 2.55556 5.91016 2.20733 5.91016 1.77778Z"
      />
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.774188 10.957C1.20176 10.957 1.54838 11.3053 1.54838 11.7348V13.4459H3.40173C3.82931 13.4459 4.17592 13.7941 4.17592 14.2237C4.17592 14.6533 3.82931 15.0015 3.40173 15.0015H0.774188C0.346616 15.0015 0 14.6533 0 14.2237V11.7348C0 11.3053 0.346616 10.957 0.774188 10.957Z"
      />
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.226 10.957C15.6535 10.957 16.0001 11.3053 16.0001 11.7348V14.2237C16.0001 14.6533 15.6535 15.0015 15.226 15.0015H12.5984C12.1708 15.0015 11.8242 14.6533 11.8242 14.2237C11.8242 13.7941 12.1708 13.4459 12.5984 13.4459H14.4518V11.7348C14.4518 11.3053 14.7984 10.957 15.226 10.957Z"
      />
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.91016 14.2231C5.91016 13.7935 6.25677 13.4453 6.68434 13.4453H9.31189C9.73946 13.4453 10.0861 13.7935 10.0861 14.2231C10.0861 14.6526 9.73946 15.0009 9.31189 15.0009H6.68434C6.25677 15.0009 5.91016 14.6526 5.91016 14.2231Z"
      />
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.774188 6.60156C1.20176 6.60156 1.54838 6.94979 1.54838 7.37934V8.62378C1.54838 9.05334 1.20176 9.40156 0.774188 9.40156C0.346616 9.40156 0 9.05334 0 8.62378V7.37934C0 6.94979 0.346616 6.60156 0.774188 6.60156Z"
      />
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2273 6.60156C15.6549 6.60156 16.0015 6.94979 16.0015 7.37934V8.62378C16.0015 9.05334 15.6549 9.40156 15.2273 9.40156C14.7997 9.40156 14.4531 9.05334 14.4531 8.62378V7.37934C14.4531 6.94979 14.7997 6.60156 15.2273 6.60156Z"
      />
    </svg>
  );
}
