import clsx from 'clsx';
import type { ReactNode } from 'react';

import styles from './TwoColumnContent.module.scss';

type TwoColumnContentProps = {
  children?: ReactNode;
  className?: string;
  contentClassName?: string;
  contentToolbar: ReactNode;
  sidebar?: ReactNode;
};

export default function TwoColumnContent({
  children,
  className,
  contentClassName,
  contentToolbar,
  sidebar,
}: TwoColumnContentProps) {
  return (
    <div className={clsx(styles.container, !!sidebar && styles.hasSidebar, className)}>
      <div className={clsx(styles.content, contentClassName)}>
        {!!contentToolbar && (
          <div className={styles.stickyToolbar}>{contentToolbar}</div>
        )}
        {children}
      </div>
      {!!sidebar && (
        <div className={styles.sidebar}>
          <div className={styles.stickyWrapper}>{sidebar}</div>
        </div>
      )}
    </div>
  );
}
