import SpaceMenu from '@layouts/sidebar/spaces/SpaceMenu';
import { NotificationProvider } from '../NotificationProvider';
import TwoColumnLayout from './TwoColumn';

export default function SpacesLayout() {
  return (
    <NotificationProvider>
      <TwoColumnLayout>
        <SpaceMenu />
      </TwoColumnLayout>
    </NotificationProvider>
  );
}
