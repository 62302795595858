import { NavLink, useParams } from 'react-router-dom';

import { NewMediaGroupDropdown } from '@/components/NewMediaGroupDropdown';
import { urlFor } from '@/urls';
import { useProject } from '@api/project';
import styles from './ProjectTabs.module.scss';

export default function ProjectTabs() {
  const projectId = useParams<{ projectId: string }>().projectId!;
  const { data } = useProject(projectId);
  const { project } = data || {};

  if (!project) return null;

  return (
    <div className={styles.projectTabs}>
      <ul>
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? styles.isActive : undefined)}
            to={urlFor('spaceLibrary', { projectId: project.id })}
          >
            Library
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? styles.isActive : undefined)}
            to={urlFor('spaceProgress', { projectId: project.id })}
          >
            Progress
          </NavLink>
        </li>
      </ul>
      <div className={styles.newMediaGroupButton}>
        <NewMediaGroupDropdown />
      </div>
    </div>
  );
}
