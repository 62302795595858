import { z } from 'zod';
import { ApiClient } from '../client';
import { successfulResponseSchema } from '../schema';
import { apiUrl } from '../util';

export const workspaceInviteKeys = {
  all: ['invite'] as const,
  one: (inviteId: string) => [...workspaceInviteKeys.all, inviteId] as const,
};

const deleteWorkspaceInvitePayloadSchema = successfulResponseSchema.extend({
  workspaceId: z.string(),
});

type DeleteWorkspaceInviteOptions = { inviteId: string };
export const deleteWorkspaceInvite = async ({
  inviteId,
}: DeleteWorkspaceInviteOptions) =>
  ApiClient.call({
    method: 'DELETE',
    endpoint: apiUrl`/w/auth/invite/${inviteId}/`,
    responseSchema: deleteWorkspaceInvitePayloadSchema,
  });

type ResendWorkspaceInviteOptions = { inviteId: string };
export const resendWorkspaceInvite = async ({
  inviteId,
}: ResendWorkspaceInviteOptions) =>
  ApiClient.call({
    method: 'POST',
    endpoint: apiUrl`/w/auth/invite/${inviteId}/resend/`,
    responseSchema: successfulResponseSchema,
  });
