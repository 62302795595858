import ErrorDuck from '@assets/img/ErrorDuck';
import stars from '@assets/img/stars-sm.png';
import styles from './SettingsError.module.scss';

export default function SettingsError({
  children,
}: {
  children?: React.ReactNode;
}) {
  return (
    <div className={styles.altWrapper}>
      <div className={styles.starsWrapper}>
        <img src={stars} className={styles.stars} width={645} height={451} />
      </div>
      <div className={styles.content}>
        {children ?? (
          <>
            <ErrorDuck />
            <h2 className="h6">Uh-oh something went wrong.</h2>
            <p>Try again in a little while.</p>
          </>
        )}
      </div>
    </div>
  );
}
