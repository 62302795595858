import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu';
import { Icon16, Icon24 } from '@spaceduck/icons';
import clsx from 'clsx';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';

import { activeProjectKeys } from '@/const';
import { useCreateDocumentMediaGroup } from '@api/mediaGroup';
import { isCapable } from '@api/util';
import { useWorkspace, useWorkspaceProjects } from '@api/workspace';
import { useCreateProjectModal } from '@components/admin/CreateProjectsModal';
import { useFileUploadWrapper } from '@hooks/useFileUploadWrapper';
import useWorkspaceId from '@hooks/useWorkspaceId';
import sharedProjectStyles from '@pages/projects/Shared.module.scss';
import Button from '@ui/Button';
import DropdownMenu from '@ui/DropdownMenu';
import styles from './MenuCreateSection.module.scss';

const { Add, Project, Document } = Icon16;
const { ImageUpload } = Icon24;

export default function MenuCreateSection() {
  const projectId = useParams<{ projectId: string }>().projectId!;

  const workspaceId = useWorkspaceId();
  const { data: workspace } = useWorkspace(workspaceId);
  const { data: workspaceProjects } = useWorkspaceProjects(workspaceId, {
    status: activeProjectKeys,
  });
  const createDocument = useCreateDocumentMediaGroup();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { handleChange } = useFileUploadWrapper({ workspaceId, projectId });

  const { open: openCreateProjectModal } = useCreateProjectModal({
    canCreateProject: isCapable('create', workspaceProjects?.capabilities),
    redirectOnCreate: true,
  });

  const createProjectCapable = isCapable('create', workspaceProjects?.capabilities);
  const createMediaGroupCapable = isCapable(
    'create',
    workspace?.workspace.capabilities
  );
  const canCreateDocument = isCapable(
    'createDocument',
    workspace?.workspace.capabilities
  ).capable;
  const canRoleCreateProject =
    createProjectCapable.capable || createProjectCapable.reason !== 'role';
  const canRoleCreateMediaGroup =
    createMediaGroupCapable.capable || createMediaGroupCapable.reason !== 'role';

  return (
    <div className={styles.creationSection}>
      {(canRoleCreateMediaGroup || canRoleCreateProject) && (
        <DropdownMenu
          align="start"
          className={clsx('menu', sharedProjectStyles.dropdownMenu)}
          triggerContent={
            <div>
              <Button
                className={clsx(sharedProjectStyles.createButton, styles.button)}
                iconBefore={<Add size={16} />}
                size="sm"
                type="button"
                variant="ghost"
              >
                New item
              </Button>
              <input
                type="file"
                multiple
                ref={fileInputRef}
                onChange={handleChange}
                style={{ display: 'none' }}
              />
            </div>
          }
        >
          {canRoleCreateMediaGroup && (
            <DropdownMenuItem
              className={sharedProjectStyles.dropdownMenuItem}
              onSelect={() => fileInputRef?.current?.click()}
            >
              <div className={sharedProjectStyles.icon}>
                <ImageUpload />
              </div>
              <div className={sharedProjectStyles.text}>
                <div className={sharedProjectStyles.title}>Media & Files</div>
                <div className={sharedProjectStyles.description}>
                  Images, Videos, PDFs, etc
                </div>
              </div>
            </DropdownMenuItem>
          )}
          {canCreateDocument && (
            <DropdownMenuItem
              className={sharedProjectStyles.dropdownMenuItem}
              onSelect={() =>
                createDocument({
                  projectId,
                  workspaceId: workspace?.workspace.id,
                })
              }
            >
              <div className={sharedProjectStyles.icon}>
                <Document />
              </div>
              <div className={sharedProjectStyles.text}>
                <div className={sharedProjectStyles.title}>Document</div>
                <div className={sharedProjectStyles.description}>Notes</div>
              </div>
            </DropdownMenuItem>
          )}
          {canRoleCreateProject && (
            <DropdownMenuItem
              className={sharedProjectStyles.dropdownMenuItem}
              onSelect={openCreateProjectModal}
            >
              <div className={sharedProjectStyles.icon}>
                <Project />
              </div>
              <div className={sharedProjectStyles.text}>
                <div className={sharedProjectStyles.title}>Space</div>
                <div className={sharedProjectStyles.description}>
                  New design or research space
                </div>
              </div>
            </DropdownMenuItem>
          )}
        </DropdownMenu>
      )}
    </div>
  );
}
