import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      width={size}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke={color} strokeLinecap="round" strokeLinejoin="round" clipPath="url(#a)">
        <path d="M13.5 0.5h-11v8h11zM.5 15.5v-5M15.5 15.5v-5M8 15.5v-5M.5 11.5A7.35 7.35 0 0 0 4 12.509c1.444 0 2.848-.47 4-1.342a6.63 6.63 0 0 0 4 1.342 7.35 7.35 0 0 0 3.5-1.009" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
