import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

import TagSuggestions from '@components/TagSuggestions';
import { useTagSuggestions } from '@hooks/useTagSuggestions';
import { Icon24 } from '@spaceduck/icons';
import Button from '@ui/Button';
import styles from './TagInput.module.scss';
const { New } = Icon24;

type TagInputProps = {
  autoFocus?: boolean;
  className?: string;
  onAddTag: (tag: string) => void;
  onRemoveTag?: (tag: string) => void;
  placeholder?: string;
  showSelectedTags?: boolean;
  showSuggestions?: boolean;
  tags?: string[];
};

const BOTTOM_OFFSET = 20;

export default function TagInput({
  autoFocus = false,
  className,
  onAddTag,
  onRemoveTag,
  placeholder = 'Tag name...',
  showSelectedTags = true,
  showSuggestions = false,
  tags,
}: TagInputProps) {
  const hasSuggestions = onRemoveTag && showSuggestions && tags;
  const [showTagsMenu, setShowTagsMenu] = useState(false);
  const [menuMaxHeight, setMenuMaxHeight] = useState<null | number>(null);
  const tagsRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { ref, results, setTagInput, tagInput } = useTagSuggestions();

  useEffect(() => {
    calcMenuHeight();
    window.addEventListener('resize', calcMenuHeight);

    return () => window.removeEventListener('resize', calcMenuHeight);
  }, []);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined = undefined;
    if (inputRef.current && autoFocus) {
      timer = setTimeout(() => {
        inputRef.current?.focus();
      }, 300);
    }

    return () => clearTimeout(timer);
  }, [autoFocus, inputRef.current]);

  const calcMenuHeight = () => {
    if (tagsRef.current) {
      const { bottom } = tagsRef.current.getBoundingClientRect();
      setMenuMaxHeight(Math.max(window.innerHeight - bottom) - BOTTOM_OFFSET);
    }
  };

  const handleNewTag = () => {
    onAddTag(tagInput);
    setTagInput('');
  };

  const handleSuggestionClick = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = ev.currentTarget;
    if (checked) {
      onAddTag(value);
    } else {
      const tagIndex = tags?.indexOf(value);
      if (tagIndex) {
        onRemoveTag?.(value);
      }
    }
  };

  return (
    <div className={clsx(styles.tagInputGroup, className)}>
      <div ref={tagsRef}>
        <input
          autoComplete="off"
          onChange={(ev) => {
            setTagInput(ev.currentTarget.value);
          }}
          onClick={() => setShowTagsMenu(true)}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
              handleNewTag();
            }
          }}
          placeholder={placeholder}
          ref={inputRef}
          type="text"
          value={tagInput}
        />
      </div>
      {hasSuggestions && (
        <TagSuggestions
          addNewTag={handleNewTag}
          setTagInput={setTagInput}
          menuMaxHeight={menuMaxHeight}
          handleTagClick={handleSuggestionClick}
          ref={ref}
          results={results}
          setShowTagsMenu={setShowTagsMenu}
          showSelectedTags={showSelectedTags}
          showTagsMenu={showTagsMenu}
          tagInput={tagInput}
          tags={tags}
        />
      )}
      <Button type="button" onClick={handleNewTag} variant="icon">
        <New size={20} />
      </Button>
    </div>
  );
}
