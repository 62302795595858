import clsx from 'clsx';

import styles from './PositionContainer.module.scss';

type PositionContainerProps = {
  className?: string;
  children: React.ReactNode;
};

export default function PositionContainer({
  children,
  className,
}: PositionContainerProps) {
  return <div className={clsx(styles.positionContainer, className)}>{children}</div>;
}
