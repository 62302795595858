import { Icon16 } from '@spaceduck/icons';

import { TopNav as BaseTopNav } from '@pages/common';
import type { TopNavProps } from '@pages/common/TopNav';

const { ProjectCategory } = Icon16;

type NavProps = Omit<TopNavProps, 'breadcrumb'> & {
  currentBreadcrumb: string;
};

const root = {
  icon: <ProjectCategory />,
  text: 'Categories',
};

const createCategoriesBreadcrumb = (currentBreadcrumb: string | null) =>
  currentBreadcrumb ? [root, { text: currentBreadcrumb }] : [root];

export default function TopNav(topNavProps: NavProps) {
  const { currentBreadcrumb, ...baseProps } = topNavProps;
  const breadcrumb = createCategoriesBreadcrumb(currentBreadcrumb);

  return <BaseTopNav {...baseProps} breadcrumb={breadcrumb} />;
}
