import { Icon24 } from '@spaceduck/icons';
import debounce from 'lodash/debounce';
import { type ChangeEvent, useMemo, useState } from 'react';

import { css } from '@/lib/css';
import type { AllOrNone } from '@spaceduck/utils';
import DropdownMenu, { type DropdownMenuProps } from './DropdownMenu';
import styles from './InfiniteDropdown.module.scss';
import { InfiniteListing, type InfiniteListingProps } from './InfiniteListing';
import ScrollArea from './ScrollArea';

const { Search } = Icon24;

const DEFAULT_FILTER_TEXT_DEBOUNCE = 250;

export type InfiniteDropDownProps = DropdownMenuProps &
  InfiniteListingProps &
  AllOrNone<{
    placeholderFilterText?: string;
    initialFilterText: string;
    onFilterTextChange: (value: string) => void;
    filterTextDebounce: number;
  }>;

export const InfiniteDropdown = ({
  placeholderFilterText,
  initialFilterText,
  onFilterTextChange,
  filterTextDebounce,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
  children,
  ...dropdownMenuProps
}: InfiniteDropDownProps) => {
  const [filterText, setFilterText] = useState(initialFilterText ?? '');

  const debouncedFilterTextChange = useMemo(
    () =>
      onFilterTextChange
        ? debounce(
            onFilterTextChange,
            filterTextDebounce ?? DEFAULT_FILTER_TEXT_DEBOUNCE
          )
        : null,
    [onFilterTextChange, filterTextDebounce]
  );

  const handleFilterTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.currentTarget.value);
    debouncedFilterTextChange?.(event.currentTarget.value);
  };

  const handleInputFocus = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key !== 'Escape') {
      event.stopPropagation();
    }
  };

  const handleKeyDown = (ev: React.KeyboardEvent) => {
    if (ev.key !== 'Escape') {
      ev.stopPropagation();
    }
  };

  return (
    <DropdownMenu {...dropdownMenuProps}>
      {onFilterTextChange && (
        <label className={styles.searchBox} onKeyDown={handleKeyDown}>
          <Search size={20} />
          <div onKeyDown={handleInputFocus}>
            <input
              onChange={handleFilterTextChange}
              placeholder={placeholderFilterText ?? 'Search'}
              type="search"
              value={filterText}
            />
          </div>
        </label>
      )}
      <ScrollArea
        style={css({
          '--width': '100%',
          '--maxHeight': onFilterTextChange
            ? 'calc(var(--radix-popper-available-height) - var(--size-20))'
            : 'var(--radix-popper-available-height)',
        })}
        type="auto"
      >
        <InfiniteListing
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
        >
          {children}
        </InfiniteListing>
      </ScrollArea>
    </DropdownMenu>
  );
};
