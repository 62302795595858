import { Slot } from '@radix-ui/react-slot';
import { clsx } from 'clsx';
import camelCase from 'lodash/camelCase';
import { type ComponentProps, forwardRef } from 'react';
import { Link } from 'react-router-dom';

import { css } from '@/lib/css';
import styles from './Button.module.scss';

export type ButtonVariant =
  | 'danger'
  | 'emoji'
  | 'flat'
  | 'ghost'
  | 'icon'
  | 'menu'
  | 'link'
  | 'light'
  | 'outlined'
  | 'primary'
  | 'secondary'
  | 'tertiary';

type BaseButtonProps = {
  asChild?: boolean;
  children?: React.ReactNode;
  className?: string;
  iconBefore?: React.ReactNode;
  iconAfter?: React.ReactNode;
  iconAbove?: React.ReactNode;
  isMuted?: boolean;
  isSquare?: boolean;
  padX?: string;
  size?: 'lg' | 'md' | 'sm' | 'xs';
  shortkeys?: React.ReactNode;
  variant?: ButtonVariant;
  disabled?: boolean;
};

export type ButtonProps = BaseButtonProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

const getClassNames = ({
  iconBefore,
  iconAfter,
  size,
  variant,
  className,
  isMuted,
  isSquare,
}: BaseButtonProps) => {
  let iconClassNames = '';
  if (iconBefore && iconAfter) {
    iconClassNames = 'btnIcons';
  } else if (iconBefore) {
    iconClassNames = 'btnIconLeft';
  } else if (iconAfter) {
    iconClassNames = 'btnIconRight';
  }

  return clsx(
    'btn',
    iconClassNames,
    camelCase(`btn-${size ?? 'md'}${isSquare ?? false ? 'Square' : ''}`),
    camelCase(`btn-${variant ?? 'primary'}`),
    isMuted && 'btnIsMuted',
    className
  );
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      asChild = false,
      children,
      className,
      iconBefore,
      iconAfter,
      iconAbove,
      isMuted,
      isSquare,
      padX,
      shortkeys,
      size,
      variant,
      ...rest
    }: ButtonProps,
    ref
  ) => {
    const Component = asChild ? Slot : 'button';

    return (
      <Component
        ref={ref}
        className={getClassNames({
          iconBefore,
          iconAfter,
          iconAbove,
          size,
          variant,
          className,
          isMuted,
          isSquare,
        })}
        style={padX ? css({ '--padding-x': `var(--size-${padX})` }) : undefined}
        {...rest}
      >
        <>
          {iconBefore && <span className="icon iconBefore">{iconBefore}</span>}
          {iconAbove ? (
            <div className={styles.iconAboveContainer}>
              <span className="icon iconAbove">{iconAbove}</span>
              {children}
            </div>
          ) : (
            children
          )}
          {iconAfter && <span className="icon iconAfter">{iconAfter}</span>}
          {shortkeys && <span className="shortkeys">{shortkeys}</span>}
        </>
      </Component>
    );
  }
);

type ButtonLinkProps = Omit<BaseButtonProps, 'asChild'> & ComponentProps<typeof Link>;

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (
    {
      children,
      className,
      iconBefore,
      iconAfter,
      isMuted,
      isSquare,
      shortkeys,
      size,
      variant,
      ...rest
    },
    ref
  ) => {
    return (
      <Link
        ref={ref}
        className={getClassNames({
          iconBefore,
          iconAfter,
          size,
          variant,
          className,
          isMuted,
          isSquare,
        })}
        {...rest}
      >
        <>
          {iconBefore && <span className="icon">{iconBefore}</span>}
          {children}
          {iconAfter && <span className="icon">{iconAfter}</span>}
          {shortkeys && <span className="shortkeys">{shortkeys}</span>}
        </>
      </Link>
    );
  }
);

export default Button;
