import { debounce } from 'lodash';
import { useCallback } from 'react';

export const useDebouncedSearch = (
  setter: React.Dispatch<React.SetStateAction<string>>,
  timeout = 400
) => {
  const debouncedSetSearchQuery = useCallback(debounce(setter, timeout), []);

  return {
    debouncedSetSearchQuery,
  };
};
