import type { MediaGroupDTO } from '@spaceduck/api';
import { Icon24 } from '@spaceduck/icons';
import { type KeyboardEvent, useState } from 'react';

import { useMediaGroupDetail } from '@api/mediaGroup';
import TagInput from '@components/TagInput';
import type { useCheckMediaGroupUpdated } from '@hooks/useCheckMediaGroupUpdated';
import { useCreateTags } from '@hooks/useCreateTags';

import Button from '@ui/Button';
import Card from '@ui/Card';
import ContextMenu from '@ui/ContextMenu';
import { useMediaGroupContextMenu } from './MediaGroupActionMenu';
import styles from './MediaGroupCard.module.scss';

const { Close } = Icon24;

type MediaGroupCardProps = {
  selected?: boolean;
  onSelected?: (selected: boolean) => void;
  mediaGroup: MediaGroupDTO;
  handleSearchClick: (id: string) => void;
  updatedMediaGroupMap: ReturnType<typeof useCheckMediaGroupUpdated>;
  inBulkSelectMode: boolean;
};

export default function MediaGroupCard({
  selected,
  onSelected,
  mediaGroup,
  handleSearchClick,
  updatedMediaGroupMap,
  inBulkSelectMode,
}: MediaGroupCardProps) {
  const [showTagCreation, setShowTagCreation] = useState(false);

  const contextMenu = useMediaGroupContextMenu({
    mediaGroup,
    onTagCreateClick: () => {
      setShowTagCreation(true);
    },
  });

  const mg = updatedMediaGroupMap[mediaGroup.id] || mediaGroup;

  return (
    <ContextMenu
      items={contextMenu}
      disabled={inBulkSelectMode}
      className={styles.contextMenu}
    >
      <Card
        key={mg.id}
        mediaGroup={mg}
        onClick={() => handleSearchClick(mg.id)}
        selected={selected}
        onSelected={onSelected}
        contextMenu={contextMenu}
        className={styles.card}
      />
      {showTagCreation && (
        <CreateTagInput
          mediaGroupId={mediaGroup.id}
          close={() => setShowTagCreation(false)}
        />
      )}
    </ContextMenu>
  );
}

const CreateTagInput = ({
  close,
  mediaGroupId,
}: {
  close: () => void;
  mediaGroupId: string;
}) => {
  const { data: mediaGroupData } = useMediaGroupDetail(mediaGroupId);
  const { addTag } = useCreateTags(mediaGroupData?.mediaGroup ?? null, true);
  const onKeyDown = (ev: KeyboardEvent) => {
    if (ev.key === 'Escape') {
      ev.preventDefault();
      ev.stopPropagation();
      close();
    }
  };

  return (
    <div className={styles.createTagInput} onKeyDown={onKeyDown}>
      <Button
        isSquare
        size="xs"
        type="button"
        variant="outlined"
        className={styles.closeButton}
        onClick={close}
      >
        <Close size={12} />
      </Button>
      <TagInput className={styles.tagInput} onAddTag={addTag} autoFocus />
    </div>
  );
};
