import type { IconProps } from '../types/IconProps';

export default function Icon({ className, size = 64 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 64 64"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33333 59.9987C7.91885 59.9987 6.56229 59.4368 5.5621 58.4366C4.5619 57.4364 4 56.0799 4 54.6654V33.332H8"
        stroke="#4B4E68"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6654 4V54.6667C14.6654 56.0812 14.1035 57.4377 13.1033 58.4379C12.1031 59.4381 10.7465 60 9.33203 60H54.6654C56.0799 60 57.4364 59.4381 58.4366 58.4379C59.4368 57.4377 59.9987 56.0812 59.9987 54.6667V4H14.6654Z"
        stroke="#4B4E68"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 36H50.6667"
        stroke="#7A7E9F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 46.668H50.6667"
        stroke="#B5B7CA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.6667 14.668H24V25.3346H50.6667V14.668Z"
        stroke="#4B4E68"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
