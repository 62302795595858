import { ApiClient } from '../client';
import { successfulResponseSchema } from '../schema';
import { apiUrl } from '../util';

export const reportSearchEvent = async (
  attributionToken: string,
  mediaGroupId: string
) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/search/report/`,
    method: 'POST',
    body: { attributionToken, userClickedOnMediaGroupId: mediaGroupId },
    responseSchema: successfulResponseSchema,
  });
