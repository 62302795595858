import { deleteWorkspaceInvite, resendWorkspaceInvite } from '@spaceduck/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { workspaceKeys } from './workspace';

export const workspaceInviteKeys = {
  all: ['invite'] as const,
  one: (inviteId: string) => [...workspaceInviteKeys.all, inviteId] as const,
};

export const useDeleteWorkspaceInvite = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: deleteWorkspaceInvite,
    onSuccess: ({ workspaceId }, { inviteId }) => {
      queryClient.invalidateQueries({
        queryKey: workspaceInviteKeys.one(inviteId),
      });
      // TODO: Can we have this only invalidate the inviteList (for all search
      //  terms) instead of everything about the workspace?
      queryClient.invalidateQueries({
        queryKey: workspaceKeys.one(workspaceId),
      });
    },
  });
  return mutation;
};

export const useResendWorkspaceInvite = () => {
  const mutation = useMutation({
    mutationFn: resendWorkspaceInvite,
  });
  return mutation;
};
