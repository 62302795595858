import type { MediaGroupCategoryDetail } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';
import { useEffect, useRef, useState } from 'react';

import type { TableColumnMeta } from '@/types/Category';
import { CategoryKind } from '@icons/CategoryKind';
import Button from '@ui/Button';
import { useDeleteConfirmModal } from '@ui/ConfirmModal';
import DropdownMenu, { MenuItem, Separator } from '@ui/DropdownMenu';
import {
  type OnDeleteSubmit,
  type OnEditAliasSubmit,
  type OnEditSubmit,
  useEditAliasModal,
  useEditPropertyModal,
} from '../Forms';
import styles from './TableColumnHeader.module.scss';

type TableColumnHeaderProps = {
  canEdit: boolean;
  categoryProperties: TableColumnMeta[];
  clearSelectedCell: () => void;
  handleDelete?: OnDeleteSubmit;
  handleEdit: OnEditSubmit;
  id: string;
  name: string;
  swapLeft?: (name: string) => void;
  swapRight?: (name: string) => void;
};

type TableColumnHeaderFirstProps = {
  category: MediaGroupCategoryDetail;
  categoryProperties: TableColumnMeta[];
  handleEdit: OnEditAliasSubmit;
  id: string;
};

const { MenuMore } = Icon16;

const { Left, Pencil, Right, TrashCan } = Icon24;

const FIRST_EDITABLE_COLUMN_INDEX = 1;

export default function TableColumnHeader({
  canEdit,
  categoryProperties,
  handleDelete,
  handleEdit,
  name,
  id,
  swapLeft,
  swapRight,
}: TableColumnHeaderProps) {
  const { open } = useEditPropertyModal();
  const { open: openDeleteModal } = useDeleteConfirmModal({
    title: 'Delete item',
    subtitle: 'This action cannot be undone. Your item will be permanently deleted.',
    confirmText: 'Yes, delete item',
    onConfirm: async () => handleDelete?.(name),
  });
  const index = categoryProperties.findIndex((column) => column.id === id);
  if (index < 0) return name;

  const column = categoryProperties[index];
  if (!column) return name;

  const isFirst = index === FIRST_EDITABLE_COLUMN_INDEX;
  const isLast = index === categoryProperties.length - 1;

  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      setRef(containerRef.current);
    }
  }, [containerRef]);

  return (
    <div className={styles.container} ref={containerRef} title={name}>
      <div className={styles.icon}>
        <CategoryKind kind={column.kind} />
      </div>
      <div className={styles.propertyName}>
        <span>{name}</span>
      </div>
      <div className={styles.menu}>
        {canEdit && !!ref && (
          <DropdownMenu
            align="start"
            className={styles.dropdown}
            container={containerRef.current}
            isPadded
            width={210}
            triggerContent={
              <Button variant="icon" size="xs" className={styles.trigger}>
                <MenuMore />
              </Button>
            }
          >
            <MenuItem
              disabled={isFirst}
              iconBefore={<Left size={20} />}
              onClick={() => {
                swapLeft?.(name);
              }}
            >
              Move left
            </MenuItem>
            <MenuItem
              disabled={isLast}
              iconBefore={<Right size={20} />}
              onClick={() => swapRight?.(name)}
            >
              Move right
            </MenuItem>
            <Separator className={styles.separator} color={2} />
            <MenuItem
              iconBefore={<Pencil size={20} />}
              onSelect={() =>
                open({
                  categoryProperties,
                  onSubmit: handleEdit,
                  property: column,
                })
              }
            >
              Edit property name
            </MenuItem>
            <MenuItem
              onSelect={() => openDeleteModal()}
              iconBefore={<TrashCan size={20} />}
            >
              Delete property
            </MenuItem>
          </DropdownMenu>
        )}
      </div>
    </div>
  );
}

export const TableColumnHeaderFirst = ({
  category,
  categoryProperties,
  handleEdit,
  id,
}: TableColumnHeaderFirstProps) => {
  const { open } = useEditAliasModal();

  const name = category.labelAlias || category.label;
  const index = categoryProperties.findIndex((column) => column.id === id);
  if (index < 0) return name;
  const column = categoryProperties[index];
  if (!column) return name;

  return (
    <div className={styles.container}>
      <div>{name}</div>
      <div className={styles.menu}>
        <DropdownMenu
          align="start"
          className={styles.dropdown}
          isPadded
          width={210}
          triggerContent={
            <Button variant="icon" size="xs" className={styles.trigger}>
              <MenuMore />
            </Button>
          }
        >
          <MenuItem disabled iconBefore={<Left size={20} />}>
            Move left
          </MenuItem>
          <MenuItem disabled iconBefore={<Right size={20} />}>
            Move right
          </MenuItem>
          <Separator className={styles.separator} color={2} />
          <MenuItem
            iconBefore={<Pencil size={20} />}
            onSelect={() => {
              open({
                category,
                categoryProperties,
                onSubmit: handleEdit,
              });
            }}
          >
            Edit property name
          </MenuItem>
          <MenuItem disabled iconBefore={<TrashCan size={20} />}>
            Delete property
          </MenuItem>
        </DropdownMenu>
      </div>
    </div>
  );
};
