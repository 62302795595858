import { Icon24 } from '@spaceduck/icons';
import { exists } from '@spaceduck/utils';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';

import { ROLE_NAMES } from '@/components/admin/const';
import { urlFor } from '@/urls';
import { useDeleteWorkspaceMember, usePatchWorkspace } from '@api/workspace';
import { useUserInfo } from '@hooks/useAuth';
import useWorkspaces from '@hooks/useWorkspaces';
import Button from '@ui/Button';
import { useConfirmModal } from '@ui/ConfirmModal';
import { RecursiveDropdownMenu } from '@ui/DropdownMenu';
import { WorkspaceAvatar } from '@ui/UserAvatar';
import styles from './WorkspacesManager.module.scss';
import { Container, Header, TopNav } from './common';

const { Menu } = Icon24;

export default function WorkspacesManagerPage() {
  const user = useUserInfo();
  const { workspaces } = useWorkspaces();
  const { mutateAsync: patchWorkspace } = usePatchWorkspace();
  const { mutateAsync: deleteWorkspaceMember } = useDeleteWorkspaceMember();
  const leaveWorkspaceConfirmModal = useConfirmModal<{
    workspaceId: string;
  }>({
    title: 'Are you sure?',
    subtitle:
      'Upon your departure from this workspace, all permissions and access to spaces you are involved in will be permanently revoked. Please be aware that this action is irreversible.',
    confirmText: 'Yes, leave workspace',
    onConfirm(vars) {
      if (!vars?.workspaceId || !user) return;

      return deleteWorkspaceMember({
        workspaceId: vars.workspaceId,
        userId: user.id,
      });
    },
  });
  const navigate = useNavigate();

  return (
    <>
      <TopNav title="Manage Workspaces" owner="account" currentBreadcrumb="Workspaces">
        <Link to={urlFor('userSettingsCreateWorkspace')}>
          <Button variant="secondary" size="sm">
            New Workspace
          </Button>
        </Link>
      </TopNav>
      <Container>
        <Header>
          <h1>Workspaces</h1>
          <p>Select your current workspace, or go to settings to manage it.</p>
        </Header>
        <div className={styles.workspaceList}>
          {workspaces.map((workspace) => {
            const menuItems = [
              workspace.role !== 'O'
                ? {
                    content: <span className={styles.dangerText}>Leave workspace</span>,
                    onClick: () => {
                      leaveWorkspaceConfirmModal.open({
                        workspaceId: workspace.id,
                      });
                    },
                    disabled: workspace.isFavorite,
                  }
                : null,
            ].filter(exists);

            return (
              <div
                key={workspace.id}
                className={clsx(
                  styles.workspaceRow,
                  menuItems.length > 0 && styles.hasMenu
                )}
              >
                <div className={styles.details}>
                  <WorkspaceAvatar
                    workspaceName={workspace.label}
                    workspaceAvatar={workspace.avatarUrl}
                  />
                  <div>
                    <h3 className="nav4">{workspace.label}</h3>
                    <p>{ROLE_NAMES[workspace.role]}</p>
                  </div>
                </div>
                <div className={styles.select}>
                  <Button
                    disabled={workspace.isFavorite}
                    onClick={() => {
                      patchWorkspace({
                        workspaceId: workspace.id,
                        patch: {
                          isFavorite: true,
                        },
                      });
                    }}
                    size="sm"
                    variant="outlined"
                  >
                    {workspace.isFavorite ? 'Default' : 'Make Default'}
                  </Button>
                </div>
                <div className={styles.settings}>
                  <Button
                    onClick={() => {
                      navigate(
                        urlFor('workspaceSettings', {
                          workspaceId: workspace.id,
                        })
                      );
                    }}
                    size="sm"
                    variant="outlined"
                  >
                    Settings
                  </Button>
                </div>
                <div className={styles.leave}>
                  {menuItems.length > 0 && (
                    <RecursiveDropdownMenu
                      dropdownMenuProps={{
                        align: 'end',
                        isPadded: true,
                        width: 200,
                      }}
                      items={menuItems}
                    >
                      <Button variant="icon" className={styles.dropdownMenuTrigger}>
                        <Menu />
                      </Button>
                    </RecursiveDropdownMenu>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </>
  );
}
