import { useCallback, useState } from 'react';
import styles from './EnvironmentIndicator.module.scss';

const ALIAS = import.meta.env.VITE_ENVIRONMENT_ALIAS || null;
const WHITELIST = new Set(['local', 'development', 'staging']);
const ENABLED = WHITELIST.has(ALIAS);

export const EnvironmentIndicator = () => {
  const [dismissed, setDismissed] = useState(false);
  const handleDismiss = useCallback(() => setDismissed(true), []);

  if (!ENABLED || dismissed) {
    return null;
  }

  return (
    <div className={styles.indicator} data-alias={ALIAS} onClick={handleDismiss}>
      {ALIAS}
    </div>
  );
};
