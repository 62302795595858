import Image from '@tiptap/extension-image';
import {
  type NodeViewRendererProps,
  NodeViewWrapper,
  ReactNodeViewRenderer,
  mergeAttributes,
} from '@tiptap/react';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

import Spinner from '@ui/Spinner';
import { padWithParagraph } from '../utils';
import styles from './Image.module.scss';

export default Image.extend({
  addAttributes() {
    return {
      asset: {
        default: null,
      },
      'data-ref': {
        default: null,
      },
      'data-src': {
        default: null,
      },
      'data-width': {
        default: null,
      },
      'data-height': {
        default: null,
      },
      'data-caption': {
        default: null,
      },
      id: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'image-node',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['image-node', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },
});

const Component = (props: NodeViewRendererProps) => {
  const { attrs } = props.node;
  const src = attrs['data-src'];
  const width = attrs['data-width'];
  const height = attrs['data-height'];
  const caption = attrs['data-caption'] ?? '';

  const [captionText, setCaptionText] = useState(caption);
  const [captionTextIsEditable, setCaptionTextIsEditable] = useState(false);

  useEffect(() => {
    if (typeof props.getPos !== 'boolean') {
      queueMicrotask(() => {
        props.editor
          .chain()
          .focus()
          .updateAttributes('image', { 'data-caption': captionText })
          .run();
      });
    }
  }, [captionText]);

  return (
    <NodeViewWrapper>
      <div className={styles.wrapper}>
        {src ? (
          <figure>
            <img
              className={styles.img}
              src={src}
              alt=""
              width={width}
              height={height}
            />
            {props.editor.isEditable ? (
              <figcaption
                contentEditable={captionTextIsEditable}
                suppressContentEditableWarning={true}
                className={clsx(
                  caption && styles.hasCaption,
                  styles.caption,
                  captionText.trim().length === 0 && styles.emptyCaption
                )}
                onClick={() => setCaptionTextIsEditable(true)}
                onBlur={(ev) => {
                  setCaptionText(ev.currentTarget.innerText);
                  setCaptionTextIsEditable(false);
                  padWithParagraph(props.editor);
                }}
                onFocus={() => {
                  queueMicrotask(() => {
                    if (typeof props.getPos !== 'boolean') {
                      props.editor.commands.setNodeSelection(props.getPos());
                    }
                  });
                }}
                onKeyDown={(ev) => {
                  if (ev.key === 'Escape') {
                    ev.stopPropagation();
                    ev.preventDefault();
                    setCaptionText(ev.currentTarget.innerText);
                    setCaptionTextIsEditable(false);
                  }
                }}
                // Prevents creation of <div> and <br> on enter
                style={{ display: 'inline-block' }}
              >
                {caption ?? ''}
              </figcaption>
            ) : (
              <figcaption
                className={clsx(caption && styles.hasCaption)}
                style={{ display: 'inline-block' }}
              >
                {caption ?? ''}
              </figcaption>
            )}
          </figure>
        ) : (
          <div
            className={styles.placeholder}
            style={{
              width: `${width}px`,
            }}
          >
            <div
              style={{
                paddingTop: `${(height / width) * 100}%`,
              }}
            >
              <Spinner className={styles.spinner} backgroundColor="#1b1d31" />
            </div>
          </div>
        )}
      </div>
    </NodeViewWrapper>
  );
};
