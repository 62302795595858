const Placeholder = ({
  className,
  height = 0,
  width = 0,
}: {
  className?: string;
  height: number;
  width: number;
}) => (
  <svg
    className={className}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    width={width}
  />
);

export default Placeholder;
