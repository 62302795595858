import type { Role } from '@spaceduck/api';
import { clsx } from 'clsx';
import capitalize from 'lodash/capitalize';
import { type ChangeEvent, type ReactNode, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { Icon24 } from '@spaceduck/icons';
import Button from '@ui/Button';
import Checkbox from '@ui/Checkbox';
import DropdownMenu from '@ui/DropdownMenu';
import styles from './DropdownCheckboxList.module.scss';
const { Search } = Icon24;

export type Option = {
  icon?: ReactNode;
  label?: string;
  value: string;
  role?: Role;
};

type ControllerValue = string[];

export type DropdownCheckboxListOnChange = (
  selectedValues: ControllerValue,
  meta: { add: string } | { remove: string }
) => void;

export type DropdownCheckboxListParams = {
  className?: string;
  controllerValue: ControllerValue;
  icon?: ReactNode;
  isSingle?: boolean;
  label?: string;
  name: string;
  onChange?: DropdownCheckboxListOnChange;
  options: Option[];
  pluralLabel?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  setWantMore?: (value: boolean) => void;
  search?: string;
  setSearch?: (value: string) => void;
  align?: 'start' | 'end' | 'center';
  showRoles?: boolean;
  disabled?: boolean;
  side?: 'left' | 'right' | 'top' | 'bottom';
  searchPlaceholder?: string;
  triggerContent?: ReactNode;
};

const displaySingleLabel = (options: Option[], value: string | null, label: string) => {
  if (!value) return label;
  const selectedValue = options.find((option) => option.value === value);

  if (!selectedValue) return label;
  return selectedValue.label ?? selectedValue.value;
};

const displayMultiLabel = (count: number, label: string, pluralLabel?: string) => {
  if (!pluralLabel) return label;
  if (count === 1) return label;
  return pluralLabel;
};

const displayCount = (i: number) => (i !== 0 ? `${i} ` : null);
const roles: Record<Role, string> = {
  A: 'Admin',
  E: 'Editor',
  O: 'Owner',
  V: 'Viewer',
};

export default function DropdownCheckboxList({
  className,
  controllerValue,
  icon,
  isSingle = false,
  label = '',
  name,
  onChange,
  options,
  pluralLabel,
  size,
  setWantMore,
  search,
  setSearch,
  align,
  showRoles = false,
  disabled = false,
  side,
  searchPlaceholder,
  triggerContent,
}: DropdownCheckboxListParams) {
  const { ref, inView } = useInView();

  useEffect(() => {
    setWantMore?.(inView);
  }, [inView]);

  const getNewValue = ({
    checked,
    value,
  }: {
    checked: boolean;
    value: string;
  }) => {
    if (!checked) {
      return controllerValue.filter((v) => v !== value);
    }
    if (isSingle) {
      return [value];
    }
    return [...controllerValue, value];
  };

  const handleChange = ({
    currentTarget: { checked, value },
  }: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      let meta: Parameters<typeof onChange>[1];
      if (checked) {
        meta = { add: value };
      } else {
        meta = { remove: value };
      }
      onChange(getNewValue({ checked, value }), meta);
    }
  };

  const handleSearchOnChange = (ev: ChangeEvent<HTMLInputElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    setSearch?.(ev.currentTarget.value);
  };

  const results = options.map(({ value, icon, label, role }, idx) => (
    <Checkbox
      checked={controllerValue?.includes(value)}
      className={clsx(styles.option)}
      icon={icon}
      key={idx}
      name={name}
      onChange={handleChange}
      singleSelection={isSingle}
      value={value}
      disabled={disabled}
    >
      <div className={styles.text}>
        <div className={styles.label}>{label ?? value}</div>
        {showRoles && role && <div className={styles.role}>{roles[role]}</div>}
      </div>
    </Checkbox>
  ));

  return (
    <div className={className}>
      <DropdownMenu
        align={align}
        side={side}
        triggerContent={
          <div>
            {triggerContent ? (
              triggerContent
            ) : (
              <Button
                className={clsx(`btn${capitalize(size)}`, styles.trigger)}
                size="sm"
                variant="outlined"
              >
                {icon && <span className="icon">{icon}</span>}
                {!isSingle && displayCount(controllerValue.length)}
                {isSingle
                  ? displaySingleLabel(options, controllerValue[0] ?? null, label)
                  : displayMultiLabel(controllerValue.length, label, pluralLabel)}
              </Button>
            )}
          </div>
        }
      >
        {search !== undefined && (
          <label className={styles.searchBox}>
            <Search />
            <input
              className={styles.input}
              onChange={handleSearchOnChange}
              placeholder={searchPlaceholder ?? 'Search people...'}
              type="search"
              value={search}
            />
          </label>
        )}
        <div className={styles.options}>
          {results || 'No results found.'}
          {setWantMore && <div ref={ref} style={{ width: '100%', height: '10px' }} />}
        </div>
      </DropdownMenu>
    </div>
  );
}
