import {
  type CommentDTO,
  type ListCommentsResponse,
  type MediaGroupDetailResponse,
  comment,
  deleteComment,
  editComment,
  listComments,
  react,
  resolve,
} from '@spaceduck/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { JSONContent } from '@tiptap/react';
import { NIL as NIL_UUID } from 'uuid';

import { useUserInfo } from '@hooks/useAuth';
import { mediaGroupKeys } from './mediaGroup';

type CommentPointer = {
  x: number;
  y: number;
  mediaId: string;
};

export const commentKeys = {
  list: (mediaGroupId: string) => ['comment', mediaGroupId],
};

export const useListComments = (mediaGroupId: string) => {
  return useQuery({
    queryKey: commentKeys.list(mediaGroupId),
    queryFn: () => listComments(mediaGroupId),
    refetchInterval: 5 * 1000,
  });
};

export const useMakeComment = (mediaGroupId: string) => {
  const queryClient = useQueryClient();
  const user = useUserInfo();
  const mutation = useMutation({
    mutationFn: ({
      content,
      parentId,
      pointAt,
      document,
    }: {
      content: string;
      parentId?: string;
      pointAt?: CommentPointer;
      document?: JSONContent;
    }) => comment({ mediaGroupId, content, parentId, pointAt, document }),
    onMutate: async (newComment) => {
      // Optimistically update the comment
      await queryClient.cancelQueries({
        queryKey: commentKeys.list(mediaGroupId),
      });

      const previousComments = queryClient.getQueryData<ListCommentsResponse>(
        commentKeys.list(mediaGroupId)
      );

      queryClient.setQueryData<ListCommentsResponse>(
        commentKeys.list(mediaGroupId),
        (old) => {
          if (!old) {
            return old;
          }

          const response: ListCommentsResponse = {
            kind: 'success',
            comments: [],
          };

          const tempComment: CommentDTO = {
            kind: 'alive' as const,
            id: 'temp-comment',
            content: newComment.content,
            createdAt: new Date().toISOString(),
            createdBy: {
              id: user?.id ?? NIL_UUID,
              name: user?.preferredName ?? 'You',
              avatarUrl: user?.avatarUrl ?? null,
            },
            reactions: [],
            resolved: null,
            replies: null,
            pointAt: newComment.pointAt ?? null,
          };

          if (newComment.parentId) {
            // insert into parent comment if its a reply
            response.comments = old.comments;
            const parentComment = old.comments.find(
              (comment) => comment.id === newComment.parentId
            );
            if (!parentComment) {
              return old;
            }
            (parentComment.replies ?? []).push(tempComment);
          } else {
            // append to end of list if its a new comment
            response.comments = [...old.comments, tempComment];
          }
          return response;
        }
      );

      const previousMediaGroupDetail =
        queryClient.getQueryData<MediaGroupDetailResponse>(
          mediaGroupKeys.detail(mediaGroupId)
        );

      // We also update the comment count on the media group
      queryClient.setQueryData<MediaGroupDetailResponse>(
        mediaGroupKeys.detail(mediaGroupId),
        (old) => {
          if (!old) {
            return old;
          }
          return {
            ...old,
            mediaGroup: {
              ...old.mediaGroup,
              commentCount: old.mediaGroup.commentCount + 1,
            },
          };
        }
      );

      return { previousComments, previousMediaGroupDetail };
    },
    onError: (_err, _newComment, context) => {
      // Roll back optimistic update
      queryClient.setQueryData<ListCommentsResponse>(
        commentKeys.list(mediaGroupId),
        context?.previousComments
      );

      queryClient.setQueryData<MediaGroupDetailResponse>(
        mediaGroupKeys.detail(mediaGroupId),
        context?.previousMediaGroupDetail
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: commentKeys.list(mediaGroupId),
      });
    },
  });
  return mutation;
};

export const useEditComment = ({
  mediaGroupId,
  commentId,
}: {
  mediaGroupId: string;
  commentId: string;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      content,
      document,
    }: {
      content: string;
      document?: JSONContent;
    }) => editComment(commentId, content, document),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: commentKeys.list(mediaGroupId),
      });
    },
  });
};

export const useDeleteComment = ({
  mediaGroupId,
  commentId,
}: {
  mediaGroupId: string;
  commentId: string;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => deleteComment(commentId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: commentKeys.list(mediaGroupId),
      });
    },
  });
};

export const useToggleReaction = ({
  mediaGroupId,
}: {
  mediaGroupId: string;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      commentId,
      emoji,
      setOn,
    }: {
      commentId: string;
      emoji: string;
      setOn: boolean;
    }) => react(commentId, emoji, setOn),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: commentKeys.list(mediaGroupId),
      });
    },
  });
};

export const useToggleResolve = ({
  mediaGroupId,
}: {
  mediaGroupId: string;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      commentId,
      resolved,
    }: {
      commentId: string;
      resolved: boolean;
    }) => resolve(commentId, resolved),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: commentKeys.list(mediaGroupId),
      });
    },
  });
};
