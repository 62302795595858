import { z } from 'zod';
import { ApiClient } from '../client';
import { successfulResponseSchema } from '../schema';
import { apiUrl } from '../util';
import type { CreateMediaGroupStatus, MediaGroupStatus } from './schemas';

export const deleteMediaGroupStatus = async (
  mediaGroupStatusId: string,
  toMediaGroupStatusId: string | null = null
) =>
  ApiClient.call({
    method: 'POST',
    endpoint: apiUrl`/w/explore/media-group-status/${mediaGroupStatusId}/delete-and-move/`,
    body: {
      toMediaGroupStatusId,
    },
    responseSchema: successfulResponseSchema.extend({
      movedMediaGroups: z.number(),
      projectId: z.string(),
    }),
  });

export const bulkPatchMediaGroupStatus = async (
  mediaGroupStatuses: Array<MediaGroupStatus | CreateMediaGroupStatus>
) =>
  ApiClient.call({
    method: 'PATCH',
    endpoint: apiUrl`/w/explore/media-group-status/`,
    body: {
      mediaGroupStatuses,
    },
    responseSchema: successfulResponseSchema.extend({
      created: z.number(),
      updated: z.number(),
      projectId: z.string(),
    }),
  });
