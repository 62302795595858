import { listWorkspaceMembers } from '@spaceduck/api';
import type { SuggestionOptions, SuggestionProps } from '@tiptap/suggestion';
import clsx from 'clsx';
import { type ReactNode, type RefObject, useState } from 'react';

import useWorkspaceId from '@hooks/useWorkspaceId';
import InputDropDownPopover, { InputPopoverMenuItem } from '@ui/InputDropdownMenu';
import UserAvatar from '@ui/UserAvatar';
import styles from './MentionPopover.module.scss';

export type Mention = {
  id: string;
  label: string;
  avatarUrl: string | null;
};

export type SuggestionConfigurationProps = {
  open: () => void;
  close: () => void;
  updateSuggestionHandler: (props?: SuggestionProps) => void;
  setFilteredSuggestions: (suggestions: Mention[]) => void;
  handleKeyDown: (event: KeyboardEvent) => boolean;
};

export const useSuggestionConfiguration = ({
  open,
  close,
  updateSuggestionHandler,
  setFilteredSuggestions,
  handleKeyDown,
}: SuggestionConfigurationProps): Omit<SuggestionOptions<Mention>, 'editor'> => {
  const workspaceId = useWorkspaceId();
  return {
    items: async ({ query }: { query: string }) => {
      if (!workspaceId) {
        return [];
      }
      const items = await listWorkspaceMembers(workspaceId, {
        query,
      });
      return items.members.map((m) => {
        return {
          id: m.id,
          label: m.name,
          avatarUrl: m.avatarUrl,
        };
      });
    },
    render: () => {
      return {
        onStart: (props: SuggestionProps<Mention>) => {
          updateSuggestionHandler(props);
          setFilteredSuggestions(props.items);
          open();
        },
        onUpdate: (props: SuggestionProps<Mention>) => {
          updateSuggestionHandler(props);
          setFilteredSuggestions(props.items);
        },
        onExit: () => {
          updateSuggestionHandler(undefined);
          close();
        },
        onKeyDown: (props) => {
          if (props.event.key === 'Escape') {
            close();
            return true;
          }
          return handleKeyDown(props.event);
        },
      };
    },
  };
};

type MentionPopoverProps = {
  suggestions: Mention[];
  selectSuggestion: (suggestion: Mention) => void;
  textAreaRef: RefObject<HTMLElement>;
  isOpen: boolean;
  anchor: ReactNode;
  containerRef?: React.MutableRefObject<HTMLDivElement | null>;
};

export const MentionPopover = ({
  suggestions,
  isOpen,
  selectSuggestion,
  textAreaRef,
  anchor,
  containerRef,
}: MentionPopoverProps) => {
  const [mentionIndex, setMentionIndex] = useState<number>();

  return (
    <InputDropDownPopover
      open={!!isOpen}
      isLoading={false}
      onSelectIndex={(index) => {
        const suggestion = suggestions[index];
        if (suggestion !== undefined) {
          selectSuggestion(suggestion);
        }
      }}
      onIndexPreview={() => {}}
      inputRef={textAreaRef}
      numberOfItems={suggestions.length}
      onHighlightedIndexChange={(index) => {
        setMentionIndex(index);
      }}
      anchor={anchor}
      className={styles.mentionSuggestions}
      containerRef={containerRef}
    >
      {suggestions.map((mentionSuggestion, index) => {
        return (
          <InputPopoverMenuItem
            suggestion={mentionSuggestion}
            key={index}
            onMouseOver={() => {
              setMentionIndex(index);
            }}
            onSelect={(mention) => {
              selectSuggestion(mention);
            }}
          >
            <span
              className={clsx(
                styles.mentionSuggestion,
                index === mentionIndex ? styles.highlighted : ''
              )}
            >
              <UserAvatar
                name={mentionSuggestion.label}
                imageUrl={mentionSuggestion.avatarUrl}
                size={'xs'}
              />
              {mentionSuggestion.label}
            </span>
          </InputPopoverMenuItem>
        );
      })}
    </InputDropDownPopover>
  );
};
