import type { MediaSource } from '@spaceduck/api';
import clsx from 'clsx';
import { useMemo } from 'react';

import { css } from '@/lib/css';
import Spinner from '@ui/Spinner';
import styles from './BunnyStreamEmbed.module.scss';

type BunnyVideoProps = {
  autoplay?: boolean;
  allowFullScreen?: boolean;
  centered?: boolean;
  containerClassName?: string;
  height?: number;
  isFullScreen?: boolean;
  knownSource?: string | null;
  muted?: boolean;
  lazy?: boolean;
  loading?: 'lazy' | 'eager';
  loop?: boolean;
  onClick?: () => void;
  preload?: boolean | null;
  preview?: string | null;
  responsive?: boolean;
  source: MediaSource | null;
  width?: number;
} & Omit<
  React.IframeHTMLAttributes<HTMLIFrameElement>,
  'autoplay' | 'loading' | 'referrerPolicy' | 'src'
>;

const encodeBoolean = (value: boolean) => (value ? 'true' : 'false');

export default function BunnyVideoEmbed({
  autoplay = false,
  allowFullScreen,
  containerClassName,
  centered = true,
  height,
  isFullScreen = false,
  knownSource,
  preload = false,
  lazy,
  loop,
  muted,
  onClick,
  preview,
  responsive = true,
  source,
  width,
  ...htmlIFrameElementProps
}: BunnyVideoProps) {
  const {
    allow = 'accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;',
    className,
    ...iframeProps
  } = htmlIFrameElementProps;
  const url = (knownSource || source?.embedUrl) ?? null;

  const videoSrc = useMemo(() => {
    if (!url) return;
    const src = new URL(url);
    src.searchParams.set('autoplay', encodeBoolean(autoplay ?? false));
    src.searchParams.set('loop', encodeBoolean(loop ?? false));
    src.searchParams.set('muted', encodeBoolean(muted ?? false));
    src.searchParams.set('preload', encodeBoolean(preload ?? false));
    src.searchParams.set('responsive', encodeBoolean(responsive ?? false));
    return src.toString();
  }, [url, autoplay, loop, muted, preload, responsive]);

  return (
    <div
      className={clsx(
        styles.maxWidthContainer,
        centered && styles.centered,
        isFullScreen && styles.isFullScreen,
        containerClassName
      )}
      style={css({
        '--height': height,
        '--width': width,
      })}
    >
      <div className={styles.responsiveContainer}>
        {onClick && (
          <button className={styles.overlayButton} onClick={onClick} type="button" />
        )}
        {videoSrc ? (
          <iframe
            allow={allow}
            allowFullScreen={allowFullScreen ?? true}
            className={clsx(styles.iframe, className)}
            loading={lazy ? 'lazy' : 'eager'}
            referrerPolicy="origin-when-cross-origin"
            src={videoSrc}
            {...iframeProps}
            width={width}
            height={height}
          />
        ) : preview ? (
          <div className={styles.preview}>
            <img alt="Video loading..." height={height} src={preview} width={width} />
            <div className={styles.generating}>
              <Spinner size={18} />
              <span>Generating video...</span>
            </div>
          </div>
        ) : (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
}
