import type { MediaGroupWithCategoryFields } from '@spaceduck/api';
import clsx from 'clsx';

import { urlFor } from '@/urls';
import { ContentType } from '@components/icons';
import Button from '@ui/Button';
import styles from './MediaGroup.module.scss';

type MediaGroupProps = {
  focused: boolean;
  mediaGroup?: MediaGroupWithCategoryFields | null;
};

export default function MediaGroup({ focused, mediaGroup }: MediaGroupProps) {
  if (!mediaGroup) return null;
  const mediaGroupURL = urlFor('mediaGroup', { mediaGroupId: mediaGroup.id });

  return (
    <div className={clsx(styles.container, focused && styles.focused)}>
      <div>
        <a href={mediaGroupURL} target="_blank" rel="noreferrer">
          <ContentType contentType={mediaGroup.contentType} />
          <span>{mediaGroup.label}</span>
        </a>
      </div>
      <Button
        className={styles.button}
        onClick={() => window.open(mediaGroupURL)}
        size="xs"
        variant="outlined"
      >
        Open
      </Button>
    </div>
  );
}
