import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import { clsx } from 'clsx';

import styles from './RadioGroup.module.scss';

type RadioGroupOption = {
  label: string;
  description?: string;
  id: string;
  value: string;
};

type RadioGroupProps = {
  children?: React.ReactNode;
  className?: string;
  defaultValue?: string;
  label?: string;
  options?: RadioGroupOption[];
} & RadixRadioGroup.RadioGroupProps;

export default function RadioGroup({
  children,
  className,
  defaultValue,
  options,
  ...rootProps
}: RadioGroupProps) {
  if (!(options?.length || children)) return null;

  return (
    <RadixRadioGroup.Root
      {...rootProps}
      className={clsx(styles.radioGroup, className)}
      defaultValue={defaultValue}
    >
      {options?.map((option) => (
        <RadioGroupItem {...option} key={option.id} />
      ))}
      {children}
    </RadixRadioGroup.Root>
  );
}

type RadioGroupItem = {
  className?: string;
  description?: string;
  id: string;
  label: string;
  value: string;
} & RadixRadioGroup.RadioGroupItemProps;

export const RadioGroupItem = ({
  className,
  description,
  id,
  label,
  value,
  ...itemProps
}: RadioGroupItem) => {
  return (
    <div className={clsx(styles.radioGroupItem, className)}>
      <label className={styles.radioGroupItemLabel} htmlFor={id}>
        {label}
      </label>
      <RadixRadioGroup.Item
        {...itemProps}
        className={styles.radioGroupItemControl}
        id={id}
        value={value}
      >
        <RadixRadioGroup.Indicator className={styles.radioGroupItemIndicator} />
      </RadixRadioGroup.Item>
      {description && (
        <div className={styles.radioGroupItemDescription}>{description}</div>
      )}
    </div>
  );
};
