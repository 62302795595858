import { type ReactNode, createContext, useContext, useState } from 'react';

const AuthContext = createContext<{
  token: string;
  setToken: (token: string) => void;
}>({
  token: '',
  setToken: () => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [token, setToken] = useState('');

  const value = {
    token,
    setToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
