import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  bulkPatchMediaGroupStatus,
  deleteMediaGroupStatus,
} from '@spaceduck/api/src/resources/mediaGroupStatus';
import { projectKeys } from './project';

export const useDeleteMediaGroupStatus = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({
      mediaGroupStatusId,
      toMediaGroupStatusId = null,
    }: {
      mediaGroupStatusId: string;
      toMediaGroupStatusId: string | null;
    }) => {
      return deleteMediaGroupStatus(mediaGroupStatusId, toMediaGroupStatusId);
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: projectKeys.one(data.projectId),
      });
    },
  });
  return mutation;
};

export const useBulkPatchMediaGroupStatus = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: bulkPatchMediaGroupStatus,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: projectKeys.one(data.projectId),
      });
    },
  });
  return mutation;
};
