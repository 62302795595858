import {
  Content as CollapseContent,
  Root as CollapsibleRoot,
} from '@radix-ui/react-collapsible';
import { Icon16 } from '@spaceduck/icons';
import { clsx } from 'clsx';

import { css } from '@/lib/css';
import { useUserInfo } from '@hooks/useAuth';
import UserAvatar from '@ui/UserAvatar';
import styles from '../DetailsModalSidebar.module.scss';
import infoStyles from '../sidebar/Info.module.scss';
import placeholderStyles from './Placeholder.module.scss';

const { ColorPalette } = Icon16;

const colors: string[] = [
  '#D7DDDE',
  '#D7DDDE',
  '#8F96A1',
  '#949BAA',
  '#B2B9C7',
  '#7A818C',
  '#757C8B',
  '#565C68',
];

export default function DetailsModalSidebarPlaceholder() {
  const user = useUserInfo();

  return (
    <CollapsibleRoot open={true} className={styles.sidebar}>
      <CollapseContent>
        <div className={infoStyles.sidebarContent}>
          <div className={infoStyles.meta}>
            <div className={placeholderStyles.details}>
              <div className={placeholderStyles.logo}>
                <UserAvatar name={user?.preferredName ?? 'J'} size="xxs" />
              </div>
              <div className={placeholderStyles.text}>
                <div className={placeholderStyles.label} />
                <div className={placeholderStyles.company} />
              </div>
            </div>
          </div>
          <div className={infoStyles.tags}>
            <ul className={placeholderStyles.tags}>
              {Array(10)
                .fill(null)
                .map((_, idx) => (
                  <li key={idx} />
                ))}
            </ul>
          </div>
          <div className={infoStyles.colors}>
            <h3 className={clsx('body4', infoStyles.heading)}>
              <ColorPalette />
              Colors
            </h3>
            <div
              className={infoStyles.colorBar}
              style={css({
                '--slices': colors.length,
              })}
            >
              {colors.map((value, idx) => (
                <span key={idx} style={{ backgroundColor: value }} />
              ))}
            </div>
          </div>
        </div>
      </CollapseContent>
    </CollapsibleRoot>
  );
}
