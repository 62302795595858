import type { ReactNode } from 'react';

import { Icon16 } from '@spaceduck/icons';
import Collapsible from '@ui/Collapsible';
import styles from './ProjectMenuAccordion.module.scss';
const { DownArrow, RightArrow } = Icon16;

type ProjectMenuAccordionProps = {
  alternateAction?: ReactNode;
  children?: ReactNode;
  label: string;
};

export default function ProjectMenuAccordion({
  alternateAction,
  children,
  label,
}: ProjectMenuAccordionProps) {
  return (
    <Collapsible
      className={styles.accordion}
      contentClassName={styles.content}
      hasUnstyledTrigger
      initiallyExpanded
      triggerContent={
        <div className={styles.header}>
          <span className={styles.tabTrigger}>
            {label}
            <DownArrow className={styles.expanded} />
            <RightArrow className={styles.collapsed} />
          </span>
          {alternateAction}
        </div>
      }
    >
      {children}
    </Collapsible>
  );
}
