import clsx from 'clsx';
import useEmblaCarousel from 'embla-carousel-react';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import Logo from '@assets/img/Logo';
import Head from '@components/Head';
import formStyles from '@components/login/Form.module.scss';
import Button from '@ui/Button';
import { openMailTo } from '@utils/openMailTo';
import styles from './Welcome.module.scss';

export default function WelcomePage() {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [emblaMainRef, emblaMainApi] = useEmblaCarousel({
    slidesToScroll: 1,
    skipSnaps: false,
    active: true,
  });

  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    dragFree: true,
  });

  const onSelect = useCallback(() => {
    if (!emblaMainApi || !emblaThumbsApi) return;
    setSelectedIndex(emblaMainApi.selectedScrollSnap());
    emblaThumbsApi.scrollTo(emblaMainApi.selectedScrollSnap());
  }, [emblaMainApi, emblaThumbsApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaMainApi) return;
    onSelect();
    emblaMainApi.on('select', onSelect);
    emblaMainApi.on('reInit', onSelect);
  }, [emblaMainApi, onSelect]);

  const goToSlide = useCallback(
    (index: number) => {
      if (!emblaMainApi || !emblaThumbsApi) return;
      emblaMainApi.scrollTo(index);
    },
    [emblaMainApi, emblaThumbsApi]
  );

  const slides: React.ReactElement[] = [
    <Welcome key="welcome" onClick={() => goToSlide(1)} />,
    <CreateYourWorkspace key="createWorkspace" />,
  ];

  return (
    <>
      <Head title="Welcome to Spaceduck!" />
      <div className={styles.carousel}>
        <div className={styles.carouselViewport} ref={emblaMainRef}>
          <div className={styles.carouselContainer}>
            {slides.map((slide, idx) => (
              <div className={styles.slide} key={idx}>
                {slide}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.dots}>
        <div className={styles.dotsViewport} ref={emblaThumbsRef}>
          <div className={styles.dotsContainer}>
            {slides.map((_, idx) => (
              <button
                className={clsx(selectedIndex === idx && styles.active)}
                key={idx}
                onClick={() => goToSlide(idx)}
                type="button"
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

const Welcome = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className={styles.container}>
      <div className={clsx('body4', styles.container)}>
        <div className={formStyles.logo}>
          <Logo />
        </div>
        <h1 className="title1">Welcome to Spaceduck!</h1>
        <p>
          Spaceduck is a product design platform that helps you manage your design
          projects, house research, receive feedback, and collaborate with your team.
        </p>
        <Button
          className={styles.btnContinue}
          onClick={onClick}
          size="lg"
          type="button"
        >
          Continue
        </Button>
        <p className={clsx(formStyles.getHelp, 'body5')}>
          Need help?{' '}
          <Button
            variant="link"
            className={styles.button}
            onClick={() => openMailTo('[SUPPORT]', '')}
          >
            Get in touch
          </Button>
        </p>
      </div>
    </div>
  );
};

const baseUrl = `${window.location.origin}/`;
type WorkspaceCreationForm = {
  workspaceName: string;
};

const CreateYourWorkspace = () => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
  } = useForm<WorkspaceCreationForm>();

  const submitHandler = (data: WorkspaceCreationForm) => {
    // TODO: Post to Workspace creation API and redirect
    console.log(data);
    setError('workspaceName', {
      type: 'custom',
      message: 'Workspace name is unavailable',
    });
  };

  return (
    <div className={styles.container}>
      <div className={clsx('body4', styles.container)}>
        <div className={formStyles.logo}>
          <Logo />
        </div>
        <h1 className="title1">Create your Workspace</h1>
        <p>Your workspace is the home for all your teams content on Spaceduck.</p>
        <form className={styles.form} onSubmit={handleSubmit(submitHandler)}>
          <label
            className={clsx(styles.inputWrapper, errors.workspaceName && styles.error)}
          >
            <span>{baseUrl}</span>
            <input
              {...register('workspaceName', {
                required: 'A name for your workspace is required.',
              })}
              type="text"
            />
          </label>
          {errors.workspaceName?.message && (
            <div className="errorMessage">{errors.workspaceName?.message}</div>
          )}
          <Button size="lg" type="submit">
            Create workspace
          </Button>
        </form>
        <p className={clsx(formStyles.getHelp, 'body5')}>
          Need help?{' '}
          <Button
            variant="link"
            className={styles.button}
            onClick={() => openMailTo('[SUPPORT]', '')}
          >
            Get in touch
          </Button>
        </p>
      </div>
    </div>
  );
};
