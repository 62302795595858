import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type SidebarStateStore = {
  // Using null as placeholder prior to determining if menu should be initially opened or closed
  sidebarIsOpen: boolean | null;
  closeSidebar: () => void;
  openSidebar: () => void;
  toggleSidebar: () => void;
};

export const useSidebarStateStore = create<SidebarStateStore>()(
  persist(
    (set) => ({
      sidebarIsOpen: null,
      closeSidebar: () => set(() => ({ sidebarIsOpen: false })),
      openSidebar: () => set(() => ({ sidebarIsOpen: true })),
      toggleSidebar: () => set((state) => ({ sidebarIsOpen: !state.sidebarIsOpen })),
    }),
    {
      name: 'sidebarIsOpen',
    }
  )
);
