import { MediaGroupStatus as MediaGroupStatusIcon } from '@components/icons';
import clsx from 'clsx';

import type { MediaGroupStatus } from '@spaceduck/api';
import styles from './MediaGroupStatusBadge.module.scss';

type MediaGroupStatusBadgeProps = {
  background?: 1 | 2 | 3 | 4;
  className?: string;
  outline?: 1 | 2 | 3;
  iconSize?: number;
  size?: 'sm' | 'md';
  status: MediaGroupStatus;
};

export default function MediaGroupStatusBadge({
  background = 4,
  className,
  outline,
  iconSize = 20,
  size = 'md',
  status,
}: MediaGroupStatusBadgeProps) {
  const icon = <MediaGroupStatusIcon size={iconSize} status={status} />;

  return (
    <div
      className={clsx(
        styles.badge,
        styles[size],
        styles[`background${background}`],
        styles[`outline${outline}`],
        className
      )}
    >
      {icon}
      {status.label}
    </div>
  );
}
