import { DropdownMenuCheckboxItem } from '@radix-ui/react-dropdown-menu';
import { Icon24 } from '@spaceduck/icons';
import clsx from 'clsx';
import { useState } from 'react';

import { useListMediaGroupCategories } from '@api/workspace';
import { MediaGroupCategory } from '@components/icons';
import Button from '@ui/Button';
import Checkbox from '@ui/Checkbox';
import { InfiniteDropdown } from '@ui/InfiniteDropdown';
import styles from './CategoryMenu.module.scss';

type CategoryMenuProps = {
  handleCategoryChange: (id: string | null) => void;
  selectedCategoryId?: string | null;
  triggerContent?: React.ReactNode;
  workspaceId: string | null;
};

const { CategoryFilter } = Icon24;

export default function CategoryMenu({
  handleCategoryChange,
  selectedCategoryId = null,
  triggerContent,
  workspaceId,
}: CategoryMenuProps) {
  const [categoryFilterText, setCategoryFilterText] = useState('');
  const {
    data: categoryListing,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useListMediaGroupCategories(workspaceId ?? null, categoryFilterText);

  return (
    <InfiniteDropdown
      className={styles.categoryMenu}
      onFilterTextChange={setCategoryFilterText}
      filterTextDebounce={250}
      initialFilterText=""
      triggerContent={
        triggerContent ? (
          triggerContent
        ) : (
          <Button
            size="sm"
            variant="outlined"
            iconBefore={<CategoryFilter size={16} />}
          >
            Category
          </Button>
        )
      }
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      width={260}
    >
      <DropdownMenuCheckboxItem
        onSelect={() => handleCategoryChange(null)}
        className={styles.menuItem}
      >
        <Checkbox
          className={styles.checkboxItem}
          defaultChecked={selectedCategoryId === null}
          icon={<MediaGroupCategory size={20} />}
          singleSelection={true}
        >
          Uncategorized
        </Checkbox>
      </DropdownMenuCheckboxItem>
      {categoryListing?.pages
        .flatMap((page) => page.categories)
        .map(({ id, label }) => (
          <CategoryMenuItem
            handleCategoryChange={handleCategoryChange}
            label={label}
            key={id}
            selectedCategoryId={selectedCategoryId}
            value={id}
          />
        ))}
    </InfiniteDropdown>
  );
}

type CategoryMenuItemProps = {
  asLabel?: boolean;
  className?: string;
  handleCategoryChange: (id: string) => void;
  label: string;
  selectedCategoryId?: string | null;
  value: string;
};

export const CategoryMenuItem = ({
  asLabel = false,
  className,
  handleCategoryChange,
  label,
  selectedCategoryId = null,
  value,
}: CategoryMenuItemProps) => {
  const checkbox = (
    <Checkbox
      className={clsx(styles.checkboxItem, className)}
      defaultChecked={selectedCategoryId === value}
      onClick={asLabel ? () => handleCategoryChange?.(value) : undefined}
      icon={<MediaGroupCategory size={20} />}
      singleSelection={true}
      value={value}
    >
      {label}
    </Checkbox>
  );

  if (asLabel) {
    return checkbox;
  }

  return (
    <DropdownMenuCheckboxItem
      className={styles.menuItem}
      onSelect={() => handleCategoryChange?.(value)}
    >
      {checkbox}
    </DropdownMenuCheckboxItem>
  );
};
