import { z } from 'zod';

export const errorSchema = z.object({
  kind: z.literal('error'),
  errorKind: z.string(),
  title: z.string(),
  body: z.string().default(''),
  info: z.unknown(),
});

export type MinimalApiErrorPayload = {
  errorKind: string;
  title: string;
  body: string;
};

export class ApiError<
  T extends MinimalApiErrorPayload = MinimalApiErrorPayload,
> extends Error {
  public readonly payload: T;

  public constructor(payload: T) {
    super(payload.title);
    this.name = 'ApiError';
    this.payload = payload;
  }
}
