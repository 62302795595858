import { type ReactNode, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

export type InfiniteListingProps = {
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  children?: ReactNode;
};

export const InfiniteListing = ({
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
  children,
}: InfiniteListingProps) => {
  const { inView, ref: loadMoreRef } = useInView();

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage, inView]);

  return (
    <>
      {children}
      {hasNextPage && <div ref={loadMoreRef} style={{ width: '1px', height: '1px' }} />}
    </>
  );
};
