import type { BillingPlan, WorkspaceUsage } from '@spaceduck/api';
import { Icon16 } from '@spaceduck/icons';
import clsx from 'clsx';
import upperFirst from 'lodash/upperFirst';

import { useGetSubscriptionInfo } from '@api/billing';
import Spinner from '@components/Spinner';
import { useUsageModal } from '@components/UsageModal';
import useWorkspaceId from '@hooks/useWorkspaceId';
import Button from '@ui/Button';
import styles from './PlanButton.module.scss';

const { Upgrade } = Icon16;
const NEARING_LIMIT = 0.8;
type Limit = 'normal' | 'nearingLimit' | 'limitReached';

const calcLimit = (usageList: WorkspaceUsage[]): Limit => {
  const used = usageList
    .filter(
      (
        usage
      ): usage is Pick<typeof usage, 'currentUsage' | 'limitName'> & {
        planLimit: number;
      } => !!usage.planLimit
    )
    .map((usage) => usage.currentUsage / usage.planLimit);

  const maxUsage = Math.max(...used);
  if (maxUsage === 1) {
    return 'limitReached';
  }
  if (maxUsage >= NEARING_LIMIT) {
    return 'nearingLimit';
  }

  return 'normal';
};

const getPlanDisplayName = (plan: BillingPlan) => {
  switch (plan) {
    case 'starter':
      return 'free';
    default:
      return plan;
  }
};

const getButtonText = (limit: Limit, plan?: BillingPlan) => {
  if (!plan) return '';

  switch (limit) {
    case 'limitReached':
      return 'Limit reached';
    case 'nearingLimit':
      return `Nearing ${getPlanDisplayName(plan)} limit`;
    default:
      return `${upperFirst(getPlanDisplayName(plan))} plan`;
  }
};

export default function PlanButton(
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
) {
  const { className, onClick, ...buttonProps } = props;
  const workspaceId = useWorkspaceId();
  const { data, error } = useGetSubscriptionInfo(workspaceId);
  const { open } = useUsageModal();

  if (error) {
    return null;
  }

  if (!data) {
    return <Spinner align="center" />;
  }

  const limit = calcLimit(data.usage);
  const text = getButtonText(limit, data.plan);

  return (
    <Button
      className={clsx(
        styles.button,
        styles[limit],
        styles[`plan${upperFirst(data.plan)}`],
        className
      )}
      onClick={(ev) => {
        onClick ? onClick(ev) : open();
      }}
      variant="ghost"
      {...buttonProps}
    >
      <Upgrade />
      {text}
    </Button>
  );
}
