import { choice } from '@/utils/random';
import { useEffect, useState } from 'react';

export const useRotatingChoice = <T,>(pool: T[], interval: number) => {
  const [current, setCurrent] = useState(() => choice(pool));

  useEffect(() => {
    const pickNext = () => {
      setCurrent(choice(pool));
    };

    const intervalId = setInterval(pickNext, interval);
    return () => clearInterval(intervalId);
  }, [pool, interval]);

  return current;
};
