import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { urlFor } from '@/urls';
import { useGetSubscriptionInfo } from '@api/billing';
import Badge from '@components/PlanBadge';
import useWorkspaceId from '@hooks/useWorkspaceId';
import useWorkspaces from '@hooks/useWorkspaces';
import { Icon24 } from '@spaceduck/icons';
import Button from '@ui/Button';
import { WorkspaceAvatar } from '@ui/UserAvatar';
import styles from './BackButton.module.scss';
const { LeftArrow } = Icon24;

export default function BackButton() {
  const workspaceId = useWorkspaceId();
  const { workspace } = useWorkspaces();
  const { data: subscriptionInfo } = useGetSubscriptionInfo(workspaceId);
  const url = workspaceId
    ? urlFor('workspaceRepository', { workspaceId })
    : urlFor('home');

  return (
    <Link className={styles.link} to={url}>
      <Button
        className={clsx(styles.button, workspace?.label && styles.hasLabel)}
        iconAfter={<Badge plan={subscriptionInfo?.plan} />}
        iconBefore={<LeftArrow size={20} />}
        type="button"
        variant="ghost"
      >
        {workspace?.label && (
          <WorkspaceAvatar
            className={styles.textLogo}
            size="xxs"
            workspaceName={workspace.label}
            workspaceAvatar={workspace.avatarUrl}
          />
        )}
        <span className={styles.text}>
          <span className={styles.label}>Workspace settings</span>
          <span className={styles.workspace}>{workspace?.label ?? 'Workspace'}</span>
        </span>
      </Button>
    </Link>
  );
}
