import { ApiClient } from '../client';
import { successfulResponseSchema } from '../schema';
import { apiUrl } from '../util';

export const claimChallenge = async (codeChallenge: string) =>
  ApiClient.call({
    endpoint: apiUrl`/auth/challenge/claim/`,
    method: 'POST',
    body: { codeChallenge },
    responseSchema: successfulResponseSchema,
  });
