import * as RadixSwitch from '@radix-ui/react-switch';
import { clsx } from 'clsx';

import styles from './Switch.module.scss';

type SwitchProps = {
  size?: 'xs' | 'sm' | 'md';
} & RadixSwitch.SwitchProps;

export default function Switch({ className, size = 'xs', ...props }: SwitchProps) {
  return (
    <RadixSwitch.Root
      className={clsx(styles.switchRoot, styles[size], className)}
      {...props}
    >
      <RadixSwitch.Thumb className={styles.switchThumb} />
    </RadixSwitch.Root>
  );
}
