import { createRef } from 'react';
import type { RefObject } from 'react';
import { create } from 'zustand';

type Comments = {
  commentsContainerRef: RefObject<HTMLDivElement> | undefined;
  scrollToBottomOfComments: () => void;
  scrollToRef: (ref: RefObject<HTMLElement> | undefined) => void;
};

export const useCommentsStore = create<Comments>()((_, get) => ({
  commentsContainerRef: createRef<
    HTMLDivElement | undefined
  >() as React.MutableRefObject<HTMLDivElement | null>,
  scrollToBottomOfComments: () => {
    const containerRef = get().commentsContainerRef;
    if (containerRef?.current) {
      const { scrollHeight } = containerRef.current;
      containerRef.current.scrollTo(0, scrollHeight);
    }
  },
  scrollToRef: (ref) => {
    const containerRef = get().commentsContainerRef;
    if (ref?.current && containerRef?.current) {
      const { bottom: elBottom, height: elHeight } =
        ref.current.getBoundingClientRect();
      const { bottom: containerBottom } = containerRef.current.getBoundingClientRect();

      if (elBottom + elHeight > containerBottom) {
        const { scrollHeight } = containerRef.current;
        containerRef.current.scrollTo(0, scrollHeight);
      }
    }
  },
}));
