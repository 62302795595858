import clsx from 'clsx';

import { css } from '@/lib/css';
import styles from './ScalableSVG.module.scss';

type ScalableSVGProps = {
  children?: React.ReactNode;
  className?: string;
  height: number;
  isSvg?: boolean;
  src: string;
  width: number;
};

export default function ScalableSVG({
  children,
  className,
  height,
  isSvg,
  src,
  width,
}: ScalableSVGProps) {
  const imgIsSvg = isSvg === undefined ? src.toLowerCase().endsWith('.svg') : isSvg;
  const content = children ?? <img src={src} width={width} height={height} />;
  if (!imgIsSvg) return content;

  return (
    <div
      className={clsx(styles.svgWrapper, className)}
      style={css({
        '--svg-width': `${width}`,
        '--svg-height': `${height}`,
      })}
    >
      {content}
    </div>
  );
}
