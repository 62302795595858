import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { ReactNode } from 'react';

import { AuthProvider } from '@hooks/useAuth';
import { AnalyticsProvider } from '@lib/analytics/AnalyticsProvider';
import { captureException } from '@sentry/react';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: async (error) => {
      captureException(error);
    },
  }),
  defaultOptions: {
    mutations: { retry: false },
    queries: { retry: false, refetchOnWindowFocus: false },
  },
});

export default function Providers({
  children,
}: {
  readonly children: ReactNode;
}) {
  return (
    <AnalyticsProvider>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          {children}
          <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" />
        </QueryClientProvider>
      </AuthProvider>
    </AnalyticsProvider>
  );
}
