import { useCookies } from '@/hooks/useCookies';
import { exponentialBackoff, sleep } from '@spaceduck/utils';

type ClarityFn = (...args: any) => void;
type ClarityWindow = { clarity?: ClarityFn };

let clarityPromise: Promise<ClarityFn> | null = null;

const clarityDiscardStub = (...args: any) => {
  console.warn('clarity not available, discarding push', args);
};

const fetcher = async () => {
  const w = window as unknown as ClarityWindow;

  for (let attempt = 0; attempt < 20; attempt++) {
    const clarity = w.clarity;

    if (clarity) {
      return clarity;
    }
    await sleep(exponentialBackoff(500, 1.05, attempt));
  }
  return clarityDiscardStub;
};

const waitForClarityFn = () => {
  if (clarityPromise === null) {
    clarityPromise = fetcher();
  }
  return clarityPromise;
};

export const clarity = async (...args: any) => {
  const clarity = await waitForClarityFn();
  try {
    clarity(...args);
  } catch (error) {
    console.error('Error interacting with clarity', args, error);
  }
};

export const useHasClarityCookies = () => {
  const cookies = useCookies();
  // spell-checker: disable-next-line
  return !!cookies.find(([key]) => key === '_clck' || key === '_clsk');
};

export const claritySetConsent = (value: boolean) =>
  value ? clarity('consent') : clarity('consent', false);
