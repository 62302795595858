import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

import Tag from '@ui/Tag';
import styles from './TextToTagsInput.module.scss';

type TextToTagsInputProps = {
  delimiter?: string;
  errorMessage?: string;
  htmlId?: string;
  initialValue?: string;
  inputClassName?: string;
  inputPlaceholder?: string;
  inputType?: 'email' | 'search' | 'text';
  onValueChange?: (tags: string[]) => void;
  pattern?: RegExp;
};

export default function TextToTagsInput({
  delimiter = ',',
  errorMessage,
  htmlId,
  initialValue,
  inputClassName,
  inputPlaceholder,
  inputType = 'text',
  onValueChange,
  pattern,
}: TextToTagsInputProps) {
  const [textInput, setTextInput] = useState(initialValue ?? '');
  const [tags, setTags] = useState<string[]>([]);
  const [hasError, setHasError] = useState(false);
  const [showTextInput, setShowTextInput] = useState(false);
  const textInputRef = useRef<HTMLInputElement>(null);

  const handleRemove = (value: string) =>
    setTags((tags) => tags.filter((tag) => tag !== value));

  const parseInput = () => {
    const values = textInput.split(delimiter).map((value) => value.trim());
    if (pattern) {
      const invalidValues = values.filter((value) => !pattern.test(value)).join(', ');
      setTags((tags) => [
        ...new Set([...tags, ...values.filter((value) => pattern.test(value))]),
      ]);

      if (invalidValues) {
        setTextInput(invalidValues);
        setHasError(true);
      } else {
        setTextInput('');
        setHasError(false);
      }
    } else {
      setTags((tags) => [...tags, ...values]);
      setTextInput('');
    }
  };

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setTextInput(ev.target.value);
  };

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      parseInput();
    }
  };

  const handleBlur = () => parseInput();

  const handleOuterClick = (ev: React.MouseEvent) => {
    if ((ev.target as HTMLElement).tagName !== 'BUTTON') {
      setShowTextInput(true);
    }
  };

  useEffect(() => {
    if (showTextInput) {
      textInputRef.current?.focus();
    }
  }, [showTextInput]);

  useEffect(() => {
    parseInput();
  }, [initialValue]);

  useEffect(() => {
    onValueChange?.(tags);
  }, [tags]);

  useEffect(() => {
    if (!tags.length) {
      setShowTextInput(true);
    } else if (textInput.length) {
      setShowTextInput(true);
    } else {
      setShowTextInput(false);
    }
  }, [tags, textInput]);

  return (
    <div
      className={clsx(styles.textToTagsInput, hasError && styles.error)}
      onClick={handleOuterClick}
    >
      {tags.length > 0 && (
        <ul>
          {tags.map((tag, idx) => {
            return (
              <li key={idx}>
                <Tag
                  onRemoveClick={() => handleRemove(tag)}
                  size="sm"
                  variant="tertiary"
                >
                  {tag}
                </Tag>
              </li>
            );
          })}
        </ul>
      )}
      <input
        type={inputType}
        value={textInput}
        onChange={handleChange}
        className={clsx(
          styles.textInput,
          inputClassName,
          !showTextInput && styles.hidden
        )}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        placeholder={tags.length > 0 ? '' : inputPlaceholder}
        id={htmlId}
        autoComplete="off"
        ref={textInputRef}
      />
      {hasError && (
        <p className={clsx('errorMessage', styles.errorMessage)}>
          {errorMessage ?? 'Could not parse all values.'}
        </p>
      )}
    </div>
  );
}
