import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const PATTERN = /WebView|(iPhone|iPod|iPad)(?!.*Safari)/i;

export const useIsWebView = () => {
  const userAgent = window.navigator?.userAgent ?? '';
  return useMemo(() => PATTERN.test(userAgent), [userAgent]);
};

export const useIsInWebViewRoute = () => {
  const location = useLocation();

  return useMemo(() => location.pathname.startsWith('/mobile/'), [location.pathname]);
};
