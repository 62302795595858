import {
  type UploadRequest,
  createUploadRequestSchema,
  memberDTO,
  processingRequestDetailManySchema,
  projectDTO,
  roleSchema,
  successfulResponseSchema,
  uploadRequestIntoProcessingRequestSchema,
} from '@spaceduck/api';
import { z } from 'zod';

const api = async <S extends z.ZodSchema>({
  endpoint,
  method,
  body,
  responseSchema,
  token,
}: {
  endpoint: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  responseSchema: S;
  body?: any;
  token?: string;
}): Promise<z.infer<S>> => {
  const options = {
    method,
    body: body instanceof FormData ? body : JSON.stringify(body),
    headers: (body instanceof FormData
      ? {}
      : { 'Content-Type': 'application/json' }) as Record<string, string>,
  };

  if (token) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  const response = await fetch(endpoint, options).then((response) => response.json());

  return responseSchema.parse(response) as z.infer<S>;
};

const successResponse = z.object({
  kind: z.literal('success'),
});

export const createChallenge = async () =>
  api({
    endpoint: '/_/auth/challenge/create/',
    method: 'POST',
    responseSchema: successResponse.extend({
      codeChallenge: z.string(),
      codeVerifier: z.string(),
    }),
  });

export const completeChallenge = async ({
  codeVerifier,
  codeChallenge,
}: {
  codeVerifier: string;
  codeChallenge: string;
}) =>
  api({
    endpoint: '/_/auth/challenge/complete/',
    method: 'POST',
    body: { codeVerifier, codeChallenge },
    responseSchema: z
      .object({
        kind: z.enum([
          'challenge_not_claimed',
          'invalid_challenge',
          'challenge_expired',
        ]),
      })
      .or(
        z.object({
          kind: z.literal('success'),
          accessToken: z.string(),
        })
      ),
  });

export const testAuthentication = async (token: string) =>
  api({
    endpoint: '/_/auth/challenge/test/',
    method: 'POST',
    token,
    responseSchema: successResponse,
  });

export const listWorkspaces = async (token: string) =>
  api({
    endpoint: '/_/w/auth/workspace/',
    method: 'GET',
    token,
    responseSchema: successResponse.extend({
      workspaces: z
        .object({
          id: z.string(),
          label: z.string(),
          role: roleSchema,
          createdAt: z.string(),
          membersPreview: memberDTO.array(),
        })
        .array(),
    }),
  });

export const getProjects = async (token: string, workspaceId: string) =>
  api({
    endpoint: `/_/w/auth/workspace/${workspaceId}/project/`,
    method: 'GET',
    token,
    responseSchema: successResponse.extend({
      projects: projectDTO.array(),
    }),
  });

export const createUploadRequest = async (
  token: string,
  body: { sizeBytes: number; mediaType: string }
) =>
  api({
    endpoint: '/_/w/assets/upload-request/',
    method: 'POST',
    responseSchema: createUploadRequestSchema,
    token,
    body,
  });

export const consumeUploadRequest = async (
  file: File | Blob,
  { endpoint, fileKey, fields }: UploadRequest
) => {
  const body = new FormData();
  for (const [key, value] of Object.entries(fields)) {
    body.set(key, value);
  }
  body.set('Content-Type', file.type);
  body.set(fileKey, file);
  return fetch(endpoint, { method: 'POST', body });
};

export const uploadRequestIntoProcessingRequest = (
  token: string,
  uploadRequestId: string
) =>
  api({
    endpoint: `/_/w/assets/upload-request/${uploadRequestId}/into-processing-request/`,
    method: 'POST',
    responseSchema: uploadRequestIntoProcessingRequestSchema,
    token,
  });

export const getProcessingRequestDetails = (
  token: string,
  processingRequestIds: string[]
) =>
  api({
    endpoint: '/_/w/assets/processing-request/fetch-many/',
    method: 'POST',
    responseSchema: processingRequestDetailManySchema,
    token,
    body: { processingRequestIds },
  });

export const createMediaGroup = async (
  token: string,
  data: {
    label: string;
    description: string;
    tags: string[];
    projectId: string;
    assets: string[];
    kind: 'gallery';
  }
) =>
  api({
    endpoint: '/_/w/explore/media-group/',
    method: 'POST',
    token,
    body: data,
    responseSchema: successfulResponseSchema,
  });
