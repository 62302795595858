import { Icon16 } from '@spaceduck/icons';
import clsx from 'clsx';
import { useCallback } from 'react';

import type { DetailsModelTab } from '@/types/MediaGroup';
import { urlFor } from '@/urls';
import { useMediaGroupDetail } from '@api/mediaGroup';
import { isCapable } from '@api/util';
import { useNavigateWithState } from '@hooks/useNavigateWithState';
import { asMilliseconds } from '@spaceduck/utils';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import Tabs, { type Tab } from '@ui/Tabs';
import styles from './DetailsModalSidebar.module.scss';
import BrowseRepository from './sidebar/BrowseRepository';
import Comments from './sidebar/Comments';
import Info from './sidebar/Info';

const {
  Comment: CommentIcon,
  Info: InfoIcon,
  RepositoryBrowse: RepositoryBrowseIcon,
  TableOfContents: TableOfContentsIcon,
} = Icon16;

export default function DetailsModalSidebar({
  mediaGroupId,
  activeTab,
  view,
  enlarged,
}: {
  mediaGroupId: string;
  activeTab: DetailsModelTab;
  view: string | null;
  enlarged: boolean;
}) {
  const { drawerIsOpen, openDrawer, toggleDrawer } = useDetailsModalStore((store) => ({
    drawerIsOpen: store.drawerIsOpen,
    openDrawer: store.openDrawer,
    toggleDrawer: store.toggleDrawer,
  }));
  const { data, isLoading } = useMediaGroupDetail(mediaGroupId, {
    refetchInterval: asMilliseconds({ seconds: 3 }),
  });
  const navigateWithState = useNavigateWithState();
  const setActiveTab = (activeTab: DetailsModelTab) => {
    const url = urlFor('mediaGroupModal', { mediaGroupId, activeTab, view });
    navigateWithState(url);
  };

  if (!data) return null; // TODO: add error state

  const { mediaGroup, userCapabilities } = data;

  const makeTabs = useCallback(() => {
    const tabs: Tab[] = [
      {
        isActive: true,
        icon: <InfoIcon />,
        content: (
          <Info
            mediaGroup={mediaGroup}
            userCanEdit={isCapable('edit', userCapabilities).capable}
          />
        ),
        value: 'info',
      },
    ];

    if (isCapable('comment', userCapabilities).capable) {
      tabs.push({
        icon: <CommentIcon />,
        content: <Comments isLoading={isLoading} mediaGroupId={mediaGroup.id} />,
        isActive: false,
        value: 'comment',
      });
    }

    tabs.push({
      icon: <RepositoryBrowseIcon />,
      content: <BrowseRepository isLoading={isLoading} mediaGroupId={mediaGroup.id} />,
      isActive: false,
      value: 'browseRepository',
    });

    if (mediaGroup.kind === 'document') {
      tabs.push({
        icon: <TableOfContentsIcon />,
        content: <div id="detailsModalSidebarTableOfContents" />,
        isActive: false,
        value: 'tableOfContent',
      });
    }

    return tabs;
  }, [mediaGroup]);

  return (
    <div
      className={clsx(styles.sidebar, enlarged && styles.enlarged)}
      data-state={drawerIsOpen ? 'open' : 'closed'}
    >
      <div className={styles.tabContainer}>
        <Tabs
          activeTab={activeTab}
          asTriggerButtons
          className={styles.tabs}
          contentClassName={styles.tabContent}
          onClick={(value?: string) => {
            if (activeTab === value) {
              toggleDrawer();
            } else {
              if (!drawerIsOpen) {
                openDrawer();
              }
              setActiveTab(value as DetailsModelTab);
            }
          }}
          orientation="vertical"
          tabs={makeTabs()}
          tabButtonProps={{ size: 'xs', variant: 'ghost' }}
          tabWrapperProps={{ className: styles.tabWrapper, variant: 'ghost' }}
        />
      </div>
    </div>
  );
}
