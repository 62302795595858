import type {
  FieldInstance,
  MediaGroupContentType,
  MediaGroupDTO,
  RelationshipFieldInstance,
} from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { css } from '@/lib/css';
import { urlFor } from '@/urls';
import { useListMediaGroups } from '@api/mediaGroup';
import { getInitialValue } from '@components/category/cellContent/Relation';
import relationshipStyles from '@components/category/cellContent/Relation.module.scss';
import { ContentType } from '@components/icons';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import useWorkspaceId from '@hooks/useWorkspaceId';
import Button from '@ui/Button';
import ScrollArea from '@ui/ScrollArea';
import {
  type CommonProps,
  type UpdateFieldFn,
  getCategoryFieldById,
} from '../InfoCategories';
import Empty from './Empty';
import styles from './Relation.module.scss';

const { Add, Delete } = Icon16;
const { Search } = Icon24;

export default function RelationField({
  category,
  fieldId,
  instances,
  updateField,
}: CommonProps) {
  const field = getCategoryFieldById(fieldId, category);
  if (
    !field?.settings ||
    !('mediaGroupCategoryId' in field.settings) ||
    !field.settings.mediaGroupCategoryId
  )
    return <Empty />;

  return (
    <RelationList
      mediaGroupCategoryId={field.settings.mediaGroupCategoryId}
      instances={instances}
      updateField={updateField}
    />
  );
}

const FILTER_TEXT_DEBOUNCE = 250;

const RelationList = ({
  instances,
  mediaGroupCategoryId,
  updateField,
}: {
  instances: FieldInstance[];
  mediaGroupCategoryId: string;
  updateField?: UpdateFieldFn;
}) => {
  const initialValue = getInitialValue(instances);
  const [shouldShowEditView, setShouldShowEditView] = useState(false);
  const relationshipInstances = instances as RelationshipFieldInstance[];
  const [localValue, setLocalValue] = useState(initialValue);

  return (
    <div className={styles.container} onClick={() => setShouldShowEditView(true)}>
      <div className={clsx(styles.wrapper, !shouldShowEditView && styles.hoverBg)}>
        <div className={styles.overflowHidden}>
          {relationshipInstances.length ? (
            relationshipInstances.map((instance) => {
              return (
                <div className={styles.entry} key={instance.instanceId}>
                  <div className={styles.truncated}>
                    <a
                      className={clsx(
                        relationshipStyles.relationEntry,
                        styles.relationEntry
                      )}
                      href={urlFor('mediaGroup', {
                        mediaGroupId: instance.mediaGroupId,
                      })}
                      onClick={(ev) => ev.stopPropagation()}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {instance.contentType && (
                        <ContentType contentType={instance.contentType} />
                      )}
                      {instance.label}
                    </a>
                  </div>
                </div>
              );
            })
          ) : (
            <Empty />
          )}
        </div>
      </div>
      {shouldShowEditView && (
        <CategoryMenu
          initialValue={initialValue}
          localValue={localValue}
          mediaGroupCategoryId={mediaGroupCategoryId}
          relationshipInstances={relationshipInstances}
          setLocalValue={setLocalValue}
          setShouldShowEditView={setShouldShowEditView}
          updateField={updateField}
        />
      )}
    </div>
  );
};

const CategoryMenu = ({
  initialValue,
  localValue,
  mediaGroupCategoryId,
  relationshipInstances,
  setLocalValue,
  setShouldShowEditView,
  updateField,
}: {
  initialValue: RelationshipFieldInstance[];
  localValue: RelationshipFieldInstance[];
  mediaGroupCategoryId: string;
  relationshipInstances: RelationshipFieldInstance[];
  setLocalValue: React.Dispatch<React.SetStateAction<RelationshipFieldInstance[]>>;
  setShouldShowEditView: React.Dispatch<React.SetStateAction<boolean>>;
  updateField?: UpdateFieldFn;
}) => {
  const workspaceId = useWorkspaceId();
  const [categoryFilterText, setCategoryFilterText] = useState('');
  const [tempCategoryFilterText, setTempCategoryFilterText] = useState('');
  const { ref: loadMoreRef, inView } = useInView();
  const _localValue = useRef(relationshipInstances ?? []);

  const debouncedFilterTextChange = useMemo(
    () =>
      setCategoryFilterText
        ? debounce(setCategoryFilterText, FILTER_TEXT_DEBOUNCE)
        : null,
    [setCategoryFilterText]
  );
  const handleCategoryFilterTextChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setTempCategoryFilterText(ev.currentTarget.value);
    debouncedFilterTextChange?.(ev.currentTarget.value);
  };
  const { data, enabled, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useListMediaGroups(
      workspaceId,
      {
        category: [mediaGroupCategoryId],
        query: categoryFilterText,
      },
      true
    );

  const { containerRef } = useOnClickOutside<HTMLDivElement>({
    callback: async () => {
      if (isEqual(initialValue, _localValue.current)) {
        setShouldShowEditView(false);
        return;
      }

      const value = _localValue.current
        ? _localValue.current.filter((entry) => !!entry.mediaGroupId)
        : null;

      await updateField?.(value ?? []);
      setShouldShowEditView(false);
    },
  });

  useEffect(() => {
    if (enabled && inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [enabled, inView, isFetchingNextPage, hasNextPage]);

  const availableMediaGroups = data?.pages.flatMap((page) => page.mediaGroups);

  const [linkedItems, linkableItems] = useMemo(() => {
    let mediaGroups = availableMediaGroups;
    if (tempCategoryFilterText) {
      const pattern = new RegExp(tempCategoryFilterText, 'i');
      mediaGroups = availableMediaGroups?.filter((mediaGroup) =>
        pattern.test(mediaGroup.label)
      );
    }

    const linkedItems: MediaGroupDTO[] = [];
    const linkableItems: MediaGroupDTO[] = [];
    const selectedItems = _localValue.current.map(({ mediaGroupId }) => mediaGroupId);

    mediaGroups?.forEach((mediaGroup) => {
      if (selectedItems.includes(mediaGroup.id)) {
        linkedItems.push(mediaGroup);
      } else {
        linkableItems.push(mediaGroup);
      }
    });

    return [linkedItems, linkableItems];
  }, [availableMediaGroups, tempCategoryFilterText, _localValue.current]);

  const setLocalValueRef = (value: RelationshipFieldInstance[]) => {
    _localValue.current = value;
    setLocalValue(value);
  };

  const handleAddEntry = ({
    id,
    label,
    contentType,
  }: {
    id: string;
    label?: string;
    contentType: MediaGroupContentType;
  }) => {
    setLocalValueRef([
      ..._localValue.current,
      { mediaGroupId: id, label, contentType },
    ]);
  };

  const handleRemoveEntry = (mediaGroupId: string) => {
    setLocalValueRef([
      ..._localValue.current.filter((entry) => entry.mediaGroupId !== mediaGroupId),
    ]);
  };

  return (
    <div ref={containerRef}>
      <div className={clsx(relationshipStyles.relationsEdit, styles.relationsEdit)}>
        <ScrollArea
          className={clsx(
            relationshipStyles.relationsScrollArea,
            _localValue.current.length > 0 && relationshipStyles.hasEntries
          )}
          orientation="vertical"
          style={css({
            '--width': '100%',
            '--maxHeight': '100%',
          })}
        >
          {localValue.map(({ mediaGroupId }, idx) => {
            const mediaGroup = availableMediaGroups?.find(
              ({ id }) => id === mediaGroupId
            );

            if (!mediaGroup) return <div key={idx} />;
            const mediaGroupURL = urlFor('mediaGroup', {
              mediaGroupId: mediaGroup.id,
            });

            return (
              <div className={relationshipStyles.inputWrapper} key={idx}>
                <div
                  className={relationshipStyles.relation}
                  key={idx}
                  onClick={() => window.open(mediaGroupURL)}
                >
                  <ContentType contentType={mediaGroup.contentType} />
                  <div className={relationshipStyles.relationLabel}>
                    {mediaGroup.label}
                  </div>
                </div>
                <Button onClick={() => handleRemoveEntry(mediaGroup.id)} variant="icon">
                  <Delete />
                </Button>
              </div>
            );
          })}
        </ScrollArea>
        <Tippy
          content={
            <div className={relationshipStyles.addRelations}>
              <div className={relationshipStyles.searchWrapper}>
                <Search size={20} />
                <input
                  className={relationshipStyles.searchbar}
                  onChange={(ev) => handleCategoryFilterTextChange?.(ev)}
                  placeholder={`Search "Relation"...`}
                  type="search"
                  value={tempCategoryFilterText}
                />
              </div>
              {!!linkedItems.length && (
                <>
                  <h3>Linked items</h3>
                  <ScrollArea
                    orientation="vertical"
                    style={css({
                      '--width': '100%',
                      '--maxHeight': '180px',
                    })}
                  >
                    <ul>
                      {linkedItems.map((mediaGroup) => (
                        <li key={mediaGroup.id}>
                          <button
                            className={styles.optionButton}
                            onClick={() => handleRemoveEntry(mediaGroup.id)}
                            type="button"
                          >
                            <ContentType contentType={mediaGroup.contentType} />
                            <div>{mediaGroup.label}</div>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </ScrollArea>
                </>
              )}
              {!!linkableItems.length && (
                <>
                  <h3>Link another item</h3>
                  <ScrollArea
                    orientation="vertical"
                    style={css({
                      '--width': '100%',
                      '--maxHeight': '180px',
                    })}
                  >
                    <ul className={styles.linkableItems}>
                      {linkableItems.map((mediaGroup) => (
                        <li key={mediaGroup.id}>
                          <button
                            className={styles.optionButton}
                            onClick={() =>
                              handleAddEntry({
                                id: mediaGroup.id,
                                label: mediaGroup.label,
                                contentType: mediaGroup.contentType,
                              })
                            }
                            type="button"
                          >
                            <ContentType contentType={mediaGroup.contentType} />
                            <div>{mediaGroup.label}</div>
                          </button>
                        </li>
                      ))}
                    </ul>
                    <div ref={loadMoreRef} style={{ width: '100%', height: '10px' }}>
                      {/* Triggers page fetch when in view */}
                    </div>
                  </ScrollArea>
                </>
              )}
            </div>
          }
          hideOnClick={false}
          interactive={true}
          placement="bottom-end"
          trigger="click"
        >
          <div>
            <Button
              className={relationshipStyles.addButton}
              iconBefore={<Add />}
              variant="ghost"
            >
              Add a Relation
            </Button>
          </div>
        </Tippy>
      </div>
    </div>
  );
};
