import { type NumberFieldInstance, isNumberFieldInstance } from '@spaceduck/api';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import type React from 'react';
import { useEffect, useRef, useState } from 'react';

import type {
  AvailableTypes,
  StandardCellValue,
  StandardEditCellValue,
} from '@/types/Category';
import { useCategoryCellSelectionKeyboard } from '@hooks/useCategoryCellSelection';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import styles from './Number.module.scss';

function getInitialValue(value: AvailableTypes) {
  const instance = value[0] ?? null;
  if (instance === null) {
    return null;
  }

  if (isNumberFieldInstance(instance)) {
    return instance;
  }
  return null;
}

export default function NumberValue({
  canEdit,
  clearSelectedCell,
  handleDelete,
  handleUpdate,
  info,
  setSelectedCell,
  showPreview,
  value,
}: StandardCellValue) {
  const initialValue = getInitialValue(value);
  const [shouldShowEditView, setShouldShowEditView] = useState(false);
  const [localValue, setLocalValue] = useState<NumberFieldInstance | null>(
    initialValue
  );

  const { setEnabled } = useCategoryCellSelectionKeyboard({
    setSelectedCell,
    enableOnLoad: false,
    onEnter: () => setShouldShowEditView(true),
    onEscape: () => clearSelectedCell(),
  });

  useEffect(() => {
    setEnabled(showPreview);
  }, [showPreview]);

  return (
    <>
      {canEdit && shouldShowEditView && (
        <EditTextValue
          clearSelectedCell={clearSelectedCell}
          handleDelete={handleDelete}
          handleUpdate={handleUpdate}
          info={info}
          initialValue={initialValue}
          localValue={localValue}
          setLocalValue={setLocalValue}
          setShouldShowEditView={setShouldShowEditView}
        />
      )}
      {showPreview ? (
        <div
          className={clsx(styles.text, styles.preview)}
          onClick={canEdit ? () => setShouldShowEditView(true) : undefined}
        >
          <div className={styles.sizeWrapper}>
            <div className={styles.overflowWrapper}>{localValue?.value}</div>
          </div>
        </div>
      ) : (
        <div className={clsx(styles.text, shouldShowEditView && styles.hidden)}>
          <div className={styles.sizeWrapper}>
            <div className={styles.overflowWrapper}>{localValue?.value}</div>
          </div>
        </div>
      )}
    </>
  );
}

const EditTextValue = ({
  handleDelete,
  handleUpdate,
  info,
  initialValue,
  localValue,
  setLocalValue,
  setShouldShowEditView,
}: StandardEditCellValue<NumberFieldInstance | null>) => {
  const localValueRef = useRef(localValue);
  const setLocalValueRef = (value: NumberFieldInstance | null) => {
    localValueRef.current = value;
    setLocalValue(value);
  };

  const persist = async () => {
    // Stop update if value is same as initial value
    if (isEqual(initialValue, localValueRef.current)) {
      return;
    }

    const value = localValueRef.current ?? null;

    // Optimistic update
    info.table.options.meta?.updateData?.(info.row.index, info.column.id, [
      localValueRef.current,
    ]);

    if (!value) {
      handleDelete?.({
        propertyId: info.column.id,
        rowIndex: info.row.index,
      });
      return;
    }

    await handleUpdate?.({
      propertyId: info.column.id,
      rowIndex: info.row.index,
      value: [value],
    });
  };

  const handleChange = (ev: React.ChangeEvent) => {
    const value = (ev.target as HTMLInputElement).value.trim();
    if (!value) {
      setLocalValueRef(null);
      return;
    }

    // Backend does not process more than 5 decimal places
    const fiveDecimalPattern = /^(\d*(\.\d{0,5})).*?/;
    if (fiveDecimalPattern.test(value)) {
      const res = value.match(fiveDecimalPattern);
      if (res?.length) {
        setLocalValueRef({
          ...localValueRef.current,
          value: res[0] ?? '',
        });
        return;
      }
    }

    setLocalValueRef({
      ...localValueRef.current,
      value,
    });
  };

  const handleKeyDown = (ev: React.KeyboardEvent) => {
    if (['e', 'E', '+'].includes(ev.key)) {
      ev.preventDefault();
      return true;
    }

    if (ev.key === 'Enter') {
      ev.preventDefault();
      persist();
      setShouldShowEditView(false);
      return true;
    }

    if (ev.key === 'ArrowDown') {
      ev.preventDefault();
    }

    if (ev.key === 'ArrowUp') {
      ev.preventDefault();
    }

    if (ev.key === 'Escape') {
      persist();
      setShouldShowEditView(false);
    }
  };

  const { containerRef } = useOnClickOutside<HTMLDivElement>({
    callback: () => {
      persist();
      setShouldShowEditView(false);
    },
  });

  return (
    <div className={styles.textEdit} ref={containerRef}>
      <input
        autoComplete="off"
        // biome-ignore lint/a11y/noAutofocus: non-disruptive and within context
        autoFocus
        className={styles.textContent}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        step={0.00001}
        type="number"
        value={localValueRef.current?.value ?? ''}
      />
    </div>
  );
};
