import type { Editor } from '@tiptap/core';
import type { NodeViewRendererProps } from '@tiptap/react';

export const padWithParagraph = (editor: Editor) => {
  if (!editor) return;
  const content = editor.getHTML();
  const container = document.createElement('div');
  container.innerHTML = content;
  if (container.lastElementChild?.tagName.toUpperCase() === 'IMAGE-NODE') {
    const emptyParagraph = document.createElement('p');
    emptyParagraph.innerHTML = ' ';
    container.append(emptyParagraph);

    queueMicrotask(() => {
      editor.commands.setContent(container.innerHTML);
    });
  }
};

export const applyAttr = (
  element: HTMLElement,
  htmlAttributes: Record<string, any>
) => {
  Object.entries(htmlAttributes).forEach(([key, value]) => {
    element.setAttribute(key, value);
  });

  return element;
};

export function isTopLevel(props: NodeViewRendererProps) {
  const pos = typeof props.getPos === 'function' && props.getPos();
  const domAtPos = pos !== false && props.editor.view.domAtPos(pos);

  if (typeof domAtPos === 'object') {
    if (domAtPos.node.nodeType === 1) {
      const element = domAtPos.node as HTMLElement;
      return element.classList.contains('ProseMirror');
    }
  }

  return false;
}
