import type { CapabilitySchema, MediaGroupCapability } from '@spaceduck/api';
import { type RefObject, createRef } from 'react';
import { create } from 'zustand';

export type ContentView = 'content' | 'transcript' | 'notes';
export type CommentingPositing = {
  x: number;
  y: number;
  mediaId: string;
} | null;

type DetailsModal = {
  drawerIsOpen: boolean;
  closeDrawer: () => void;
  openDrawer: () => void;
  resetDrawer: () => void;
  toggleDrawer: () => void;
  topRef: RefObject<HTMLDivElement> | undefined;
  availableContentViews: ContentView[];
  setAvailableContentViews: (availableContentViews: ContentView[]) => void;
  contentView?: ContentView;
  setContentView: (contentView: ContentView | undefined) => void;
  isCommenting: boolean;
  setIsCommenting: (isCommenting: boolean) => void;
  userCapabilities: CapabilitySchema<MediaGroupCapability>[];
  setUserCapabilities: (
    userCapabilities: CapabilitySchema<MediaGroupCapability>[]
  ) => void;
  newCommentPosition: CommentingPositing;
  setNewCommentPosition: (newCommentPosition: CommentingPositing) => void;
  currentMediaGroupPageCount: number;
  setCurrentMediaGroupPageCount: (currentMediaGroupPage: number) => void;
  isInLightboxView: boolean;
  setIsInLightboxView: (isInLightboxView: boolean) => void;
  isInlineCommenting: boolean;
  setIsInlineCommenting: (isInlineCommenting: boolean) => void;
  quickViewMediaGroupId: string | null;
  setQuickViewMediaGroupId: (quickViewMediaGroupId: string | null) => void;
  swapQuickViewMediaGroupId: string | null;
  setSwapQuickViewMediaGroupId: (swapQuickViewMediaGroupId: string | null) => void;
};

export const useDetailsModalStore = create<DetailsModal>()((set) => ({
  drawerIsOpen: true,
  closeDrawer: () => set(() => ({ drawerIsOpen: false })),
  openDrawer: () => set(() => ({ drawerIsOpen: true })),
  resetDrawer: () => set(() => ({ drawerIsOpen: true })),
  toggleDrawer: () => set(({ drawerIsOpen }) => ({ drawerIsOpen: !drawerIsOpen })),
  topRef: createRef<
    HTMLDivElement | undefined
  >() as React.MutableRefObject<HTMLDivElement | null>,
  availableContentViews: [],
  setAvailableContentViews: (availableContentViews) =>
    set(() => ({ availableContentViews })),
  contentView: undefined,
  setContentView: (contentView) => set(() => ({ contentView })),
  isCommenting: false,
  setIsCommenting: (isCommenting) => set(() => ({ isCommenting })),
  userCapabilities: [],
  setUserCapabilities: (userCapabilities) => set(() => ({ userCapabilities })),
  newCommentPosition: null,
  setNewCommentPosition: (newCommentPosition) => set(() => ({ newCommentPosition })),
  currentMediaGroupPageCount: 0,
  setCurrentMediaGroupPageCount: (currentMediaGroupPageCount) =>
    set(() => ({ currentMediaGroupPageCount })),
  isInLightboxView: false,
  setIsInLightboxView: (isInLightboxView) => set(() => ({ isInLightboxView })),
  isInlineCommenting: false,
  setIsInlineCommenting: (isInlineCommenting) => set(() => ({ isInlineCommenting })),
  quickViewMediaGroupId: null,
  setQuickViewMediaGroupId: (quickViewMediaGroupId) =>
    set(() => ({ quickViewMediaGroupId })),
  swapQuickViewMediaGroupId: null,
  setSwapQuickViewMediaGroupId: (swapQuickViewMediaGroupId) =>
    set(() => ({ swapQuickViewMediaGroupId })),
}));
