import type { ProjectDetailDTO, ProjectRoleDutySummary } from '@spaceduck/api';
import clsx from 'clsx';

import { usePatchProjectMembership, useRemoveUserFromProject } from '@/api/project';
import type { DropdownCheckboxListOnChange } from '@/components/ui/DropdownCheckboxList';
import createToast from '@/utils/createToast';
import Button from '@ui/Button';
import UserAvatar from '@ui/UserAvatar';
import PeopleDropdownList from './PeopleDropdownList';
import ProjectMenuAccordion from './ProjectMenuAccordion';
import styles from './ProjectMenuPeople.module.scss';

type ProjectMenuPeopleProps = {
  project: ProjectDetailDTO;
};

export default function ProjectMenuPeople({ project }: ProjectMenuPeopleProps) {
  const { capabilities } = project;
  const editCapability = capabilities?.find(({ capability }) => capability === 'edit');
  const canEdit = editCapability?.capable ?? false;

  if (!project) return null;
  const { members } = project;

  const getMembersByRoleDutySummary = (roleDutySummary: ProjectRoleDutySummary) => {
    return members.filter((m) => m.roleDutySummary === roleDutySummary);
  };

  const lead = getMembersByRoleDutySummary('L')?.[0] ?? null;
  const contributors = getMembersByRoleDutySummary('C');
  const viewers = getMembersByRoleDutySummary('V');

  const { mutateAsync: patchProjectMembership } = usePatchProjectMembership();
  const { mutateAsync: removeUserFromProject } = useRemoveUserFromProject();

  const handleLeadChange: DropdownCheckboxListOnChange = async (_leads, meta) => {
    if (!canEdit) {
      return;
    }
    if ('add' in meta) {
      const response = await patchProjectMembership({
        projectId: project.id,
        userId: meta.add,
        roleDutySummary: 'L',
      });
      createToast({
        titleText: 'Leader updated',
        bodyText: `${response.user.name} was set as the leader.`,
        iconVariant: 'success',
      });
    }
    if ('remove' in meta) {
      const response = await patchProjectMembership({
        projectId: project.id,
        userId: meta.remove,
        roleDutySummary: 'C',
      });
      createToast({
        titleText: 'Leader updated',
        bodyText: `${response.user.name} removed as the leader.`,
        iconVariant: 'success',
      });
    }
  };

  const handleContributorsChange: DropdownCheckboxListOnChange = async (
    _contributors,
    meta
  ) => {
    if (!canEdit) {
      return;
    }
    if ('add' in meta) {
      const response = await patchProjectMembership({
        projectId: project.id,
        userId: meta.add,
        roleDutySummary: 'C',
      });
      createToast({
        titleText: 'Contributors updated',
        bodyText: `${response.user.name} was added as a contributor.`,
        iconVariant: 'success',
      });
    }
    if ('remove' in meta) {
      const response = await removeUserFromProject({
        projectId: project.id,
        userId: meta.remove,
      });
      if (response.user) {
        createToast({
          titleText: 'Contributors updated',
          bodyText: `${response.user.name} was removed from the space.`,
          iconVariant: 'success',
        });
      } else {
        createToast({
          titleText: 'Not a contributor',
          bodyText: 'User was not a member of the space, no changes made.',
          iconVariant: 'warning',
        });
      }
    }
  };

  const handleViewersChange: DropdownCheckboxListOnChange = async (_viewers, meta) => {
    if (!canEdit) {
      return;
    }
    if ('add' in meta) {
      const response = await patchProjectMembership({
        projectId: project.id,
        userId: meta.add,
        roleDutySummary: 'V',
      });
      createToast({
        titleText: 'Viewers updated',
        bodyText: `${response.user.name} was added as a viewer.`,
        iconVariant: 'success',
      });
    }
    if ('remove' in meta) {
      const response = await removeUserFromProject({
        projectId: project.id,
        userId: meta.remove,
      });
      if (response.user) {
        createToast({
          titleText: 'Viewers updated',
          bodyText: `${response.user.name} was removed from the space.`,
          iconVariant: 'success',
        });
      } else {
        createToast({
          titleText: 'Not a viewer',
          bodyText: 'User was not a member of the space, no changes made.',
          iconVariant: 'warning',
        });
      }
    }
  };

  return (
    <ProjectMenuAccordion label="People">
      <div className={styles.table}>
        <div className={styles.row}>
          <span>Lead</span>
          <span>
            <PeopleDropdownList
              align="start"
              side="left"
              controllerValue={lead ? [lead.id] : []}
              name="lead"
              searchPlaceholder="Search contributors..."
              showRoles
              disabled={!canEdit}
              triggerContent={
                <>
                  {lead ? (
                    <UserAvatar name={lead.name} imageUrl={lead.avatarUrl} size="xxs" />
                  ) : (
                    <AvatarPlaceholder
                      label={canEdit ? 'Add lead' : 'No lead'}
                      name="L"
                      isMuted
                    />
                  )}
                </>
              }
              isSingle={true}
              onChange={(values, meta) => {
                handleLeadChange(values, meta);
              }}
            />
          </span>
        </div>
        <div className={styles.row}>
          <span>Contributors</span>
          <span>
            <PeopleDropdownList
              align="start"
              side="left"
              controllerValue={contributors.map(({ id }) => id)}
              name="contributors"
              searchPlaceholder="Search contributors..."
              showRoles
              disabled={!canEdit}
              triggerContent={
                <>
                  {contributors?.length > 0 ? (
                    <MultipleAvatars users={contributors} label="contributors" />
                  ) : (
                    <AvatarPlaceholder
                      label={canEdit ? 'Add contributors' : 'No contributors'}
                      name="C"
                      isMuted
                    />
                  )}
                </>
              }
              onChange={(values, meta) => {
                handleContributorsChange(values, meta);
              }}
            />
          </span>
        </div>
        <div className={styles.row}>
          <span>Viewers</span>
          <span>
            <PeopleDropdownList
              align="start"
              side="left"
              controllerValue={viewers.map(({ id }) => id)}
              name="viewers"
              searchPlaceholder="Search people..."
              showRoles
              disabled={!canEdit}
              triggerContent={
                <>
                  {viewers?.length > 0 ? (
                    <MultipleAvatars users={viewers} label="viewers" />
                  ) : (
                    <AvatarPlaceholder
                      label={canEdit ? 'Add viewers' : 'No viewers'}
                      name="V"
                      isMuted
                    />
                  )}
                </>
              }
              onChange={(values, meta) => {
                handleViewersChange(values, meta);
              }}
            />
          </span>
        </div>
      </div>
    </ProjectMenuAccordion>
  );
}

const AvatarPlaceholder = ({
  isMuted = false,
  name,
  label,
}: {
  isMuted?: boolean;
  name: string;
  label: string;
}) => {
  return (
    <Button
      className={clsx(styles.placeholderButton, !isMuted && styles.hasValue)}
      iconBefore={
        <UserAvatar
          hasDashedBorder={isMuted}
          isMuted
          name={name}
          size="xxs"
          className={clsx(!isMuted && styles.lighten)}
        />
      }
      size="xs"
      type="button"
      variant="ghost"
    >
      {label}
    </Button>
  );
};

const MultipleAvatars = ({
  users,
  label,
}: {
  users: { name: string; avatarUrl: string | null }[];
  label?: string;
}) => {
  const [user, ...rest] = users;

  if (user === undefined) {
    return null;
  }

  if (rest.length === 0) {
    return <UserAvatar size="xxs" name={user.name} imageUrl={user.avatarUrl} />;
  }

  const sampleUsers = users.slice(0, 5);

  return (
    <div className={styles.multipleAvatars}>
      <div className={styles.avatars}>
        {sampleUsers.map((user, idx) => (
          <UserAvatar
            key={idx}
            size="xxs"
            isMuted
            name={user.name}
            imageUrl={user.avatarUrl}
            className={styles.lighten}
          />
        ))}
      </div>
      <div>{`${users.length} ${label ?? 'people'}`}</div>
    </div>
  );
};
