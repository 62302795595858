import Mention from '@tiptap/extension-mention';
import { NodeViewWrapper, ReactNodeViewRenderer, mergeAttributes } from '@tiptap/react';

import { useWorkspaceMember } from '@api/workspace';
import useWorkspaceId from '@hooks/useWorkspaceId';
import Spinner from '@ui/Spinner';
import UserAvatar from '@ui/UserAvatar';
import styles from './Mention.module.scss';

export default Mention.extend({
  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },

  parseHTML() {
    return [
      {
        tag: 'mention',
      },
    ];
  },

  renderHTML(props) {
    return ['mention', mergeAttributes(props.HTMLAttributes)];
  },
});

const Component = (props: any) => {
  const workspaceId = useWorkspaceId();

  const { id, label } = props.node.attrs;
  const { data, error, isLoading } = useWorkspaceMember(workspaceId, id);

  if (isLoading) {
    return (
      <NodeViewWrapper>
        <div>
          <Spinner />
        </div>
      </NodeViewWrapper>
    );
  }

  if (error || !data) {
    console.error('Could not load user data', error);

    return (
      <NodeViewWrapper>
        <div>@{label}</div>
      </NodeViewWrapper>
    );
  }

  const {
    member: { avatarUrl, name },
  } = data;

  return (
    <NodeViewWrapper as="span">
      <div className={styles.mention}>
        <UserAvatar
          name={name}
          imageUrl={avatarUrl}
          size="xxs"
          className={styles.avatar}
        />
        {name}
      </div>
    </NodeViewWrapper>
  );
};
