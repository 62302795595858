import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { Icon24 } from '@spaceduck/icons';
import clsx from 'clsx';
import { type ReactNode, type Ref, forwardRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { css } from '@/lib/css';
import type { AllOrNone } from '@spaceduck/utils';
import Button from '@ui/Button';
import type { ContextMenuItemProps } from '@ui/ContextMenu';
import styles from './DropdownMenu.module.scss';

const { Right } = Icon24;

export type DropdownMenuProps = {
  align?: 'start' | 'end' | 'center';
  alignOffset?: number;
  children: React.ReactNode;
  className?: string;
  container?: HTMLElement | null;
  isPadded?: boolean;
  loop?: boolean;
  modal?: boolean;
  side?: 'left' | 'right' | 'top' | 'bottom';
  sideOffset?: number;
  triggerContent: React.ReactNode;
  width?: number;
} & AllOrNone<{
  open?: boolean;
  setOpen?: (state: boolean) => void;
}>;

export const ControlledDropdownMenu = forwardRef(
  (
    {
      align = 'start',
      alignOffset,
      defaultOpen = false,
      children,
      className,
      container,
      isPadded = false,
      loop = false,
      modal = true,
      onOpenChange,
      open,
      side = 'bottom',
      sideOffset = 6,
      triggerContent,
      width,
    }: DropdownMenuProps & {
      defaultOpen?: boolean;
      onOpenChange: (open: boolean) => void;
      open: boolean;
    },
    ref: Ref<HTMLDivElement>
  ) => (
    <RadixDropdownMenu.Root
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
      modal={modal}
    >
      <RadixDropdownMenu.Trigger asChild>{triggerContent}</RadixDropdownMenu.Trigger>
      <RadixDropdownMenu.Portal container={container}>
        <RadixDropdownMenu.Content
          align={align}
          alignOffset={alignOffset}
          className={clsx(isPadded && 'menu', styles.dropdownMenuContent, className)}
          loop={loop}
          ref={ref}
          side={side}
          sideOffset={sideOffset}
          style={width ? css({ '--menu-width': `${width}px` }) : undefined}
        >
          {children}
        </RadixDropdownMenu.Content>
      </RadixDropdownMenu.Portal>
    </RadixDropdownMenu.Root>
  )
);

export default function DropdownMenu({
  open: externalOpen,
  setOpen: externalSetOpen,
  ...props
}: DropdownMenuProps) {
  const [internalOpen, setInternalOpen] = useState(false);
  const open = externalOpen ?? internalOpen;
  const setOpen = externalSetOpen ?? setInternalOpen;

  useHotkeys(
    'Escape',
    () => {
      setOpen(false);
    },
    {
      enabled: open,
    },
    [open]
  );

  return <ControlledDropdownMenu {...props} open={open} onOpenChange={setOpen} />;
}

const RecursiveDropdownMenuItem = ({
  className,
  isPadded = false,
  isUnstyled = true,
  item,
  showIndicator = true,
  width,
}: {
  className?: string;
  isPadded?: boolean;
  isUnstyled?: boolean;
  item: ContextMenuItemProps;
  showIndicator?: boolean;
  width?: number;
}) => {
  const isMenuItem = item.isMenuItem ?? true;

  if (item.subMenu) {
    return (
      <RadixDropdownMenu.DropdownMenuSub>
        <RadixDropdownMenu.DropdownMenuSubTrigger
          className={clsx(isUnstyled && styles.dropdownMenuItem)}
        >
          {item.content}
          {showIndicator && <Right />}
        </RadixDropdownMenu.DropdownMenuSubTrigger>
        <RadixDropdownMenu.DropdownMenuPortal>
          <RadixDropdownMenu.DropdownMenuSubContent
            className={clsx(isPadded && 'menu', styles.dropdownMenuContent, className)}
            sideOffset={isPadded ? 14 : 6}
            style={
              item.width || width
                ? css({ '--menu-width': `${item.width || width}px` })
                : undefined
            }
          >
            {item.subMenu.map((subItem, idx) => (
              <RecursiveDropdownMenuItem
                item={subItem}
                key={idx}
                isPadded={isPadded}
                isUnstyled={isUnstyled}
                showIndicator={showIndicator}
                className={className}
              />
            ))}
          </RadixDropdownMenu.DropdownMenuSubContent>
        </RadixDropdownMenu.DropdownMenuPortal>
      </RadixDropdownMenu.DropdownMenuSub>
    );
  }
  if (!isMenuItem) {
    return <div onClick={() => !item.disabled && item.onClick?.()}>{item.content}</div>;
  }

  return (
    <RadixDropdownMenu.DropdownMenuItem
      className={clsx(isUnstyled && styles.dropdownMenuItem)}
      disabled={item.disabled}
      onClick={() => !item.disabled && item.onClick?.()}
    >
      {item.content}
    </RadixDropdownMenu.DropdownMenuItem>
  );
};

export const RecursiveDropdownMenu = ({
  items,
  children,
  container,
  className,
  dropdownMenuItemClassName,
  dropdownMenuProps,
  isUnstyled = true,
  showIndicator = true,
}: {
  items: ContextMenuItemProps[];
  children: ReactNode;
  container?: HTMLElement | null;
  className?: string;
  dropdownMenuItemClassName?: string;
  dropdownMenuProps?: Pick<DropdownMenuProps, 'align' | 'isPadded' | 'width'>;
  isUnstyled?: boolean;
  showIndicator?: boolean;
}) => {
  return (
    <DropdownMenu
      className={className}
      container={container}
      modal={false}
      sideOffset={6}
      triggerContent={children}
      {...dropdownMenuProps}
    >
      {items.map((item, idx) => (
        <RecursiveDropdownMenuItem
          item={item}
          key={idx}
          isUnstyled={isUnstyled}
          showIndicator={showIndicator}
          className={dropdownMenuItemClassName}
          isPadded={dropdownMenuProps?.isPadded}
          width={dropdownMenuProps?.width}
        />
      ))}
    </DropdownMenu>
  );
};

type MenuItemProps = {
  asButton?: boolean;
  children?: ReactNode;
  className?: string;
  iconAfter?: ReactNode;
  iconBefore?: ReactNode;
} & RadixDropdownMenu.DropdownMenuItemProps;

export const MenuItem = (menuItemProps: MenuItemProps) => {
  const {
    asButton = false,
    className,
    children,
    iconAfter,
    iconBefore,
    onSelect,
    ...dropdownMenuItemProps
  } = menuItemProps;

  const button = (
    <TriggerButton
      className={clsx(styles.button, className)}
      disabled={dropdownMenuItemProps?.disabled ?? false}
      iconAfter={iconAfter}
      iconBefore={iconBefore}
    >
      {children}
    </TriggerButton>
  );

  if (asButton) {
    return button;
  }

  return (
    <RadixDropdownMenu.DropdownMenuItem
      {...dropdownMenuItemProps}
      className={styles.menuItem}
      onSelect={onSelect}
    >
      {button}
    </RadixDropdownMenu.DropdownMenuItem>
  );
};

type TriggerButtonProps = {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  iconAfter?: React.ReactNode;
  iconBefore?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export const TriggerButton = forwardRef<HTMLButtonElement, TriggerButtonProps>(
  (
    {
      children,
      className,
      disabled = false,
      iconAfter,
      iconBefore,
      onClick,
    }: TriggerButtonProps,
    ref
  ) => {
    return (
      <Button
        className={clsx(styles.button, className)}
        disabled={disabled}
        iconAfter={iconAfter}
        iconBefore={iconBefore}
        onClick={onClick}
        ref={ref}
        size="md"
        variant="menu"
      >
        {children}
      </Button>
    );
  }
);

export const Separator = ({
  className,
  color,
}: {
  className?: string;
  color?: 1 | 2 | 3 | 4;
}) => <div className={clsx(styles.separator, styles[`outline${color}`], className)} />;

type DropdownMenuItemProps = {
  className?: string;
  children: ReactNode;
} & RadixDropdownMenu.DropdownMenuItemProps;

export const DropdownMenuItem = ({
  className,
  children,
  ...baseProps
}: DropdownMenuItemProps) => {
  return (
    <RadixDropdownMenu.DropdownMenuItem
      className={clsx(styles.dropdownMenuItem, className)}
      {...baseProps}
    >
      {children}
    </RadixDropdownMenu.DropdownMenuItem>
  );
};
