import type { MediaGroupDetailDTO } from '@spaceduck/api';
import { useEffect, useState } from 'react';

import { usePatchMediaGroup } from '@api/mediaGroup';
import createToast from '@utils/createToast';

export const useCreateTags = (
  mediaGroup: MediaGroupDetailDTO | null,
  isFromCardView?: boolean
) => {
  const [highlightedTag, setHighlightedTag] = useState('');
  const [showTagCreationInput, setShowTagCreationInput] = useState(false);

  const { mutateAsync: patchMediaGroup } = usePatchMediaGroup();

  useEffect(() => {
    let highlightTimer: ReturnType<typeof setTimeout> | undefined = undefined;

    if (highlightedTag) {
      if (highlightTimer) clearTimeout(highlightTimer);
      highlightTimer = setTimeout(() => {
        setHighlightedTag('');
      }, 3000);
    }

    return () => clearTimeout(highlightTimer);
  }, [highlightedTag]);

  if (!mediaGroup) {
    return {
      addTag: () => {
        console.error('Cannot add tag without media group');
      },
      removeTag: () => {
        console.error('Cannot remove tag without media group');
      },
      highlightedTag: '',
      setShowTagCreationInput: () => {},
      showTagCreationInput: false,
    };
  }

  const addTag = async (tag: string) => {
    const trimmedTag = tag.trim();
    if (!trimmedTag) return;

    if (
      !mediaGroup.tags
        .map((tag) => tag.label.toLowerCase())
        .includes(trimmedTag.toLowerCase())
    ) {
      const tags = [...mediaGroup.tags.map((t) => t.label), tag];
      const result = await patchMediaGroup({
        mediaGroupId: mediaGroup.id,
        patch: {
          tags,
        },
      });

      if (result.kind === 'success') {
        createToast({
          titleText: 'Tag added',
          bodyText: isFromCardView
            ? `"${tag}" was added to "${mediaGroup.label}" tags.`
            : `"${tag}" was added to tags.`,
          iconVariant: 'success',
        });
      } else {
        createToast({
          titleText: 'Failed to add tag',
          bodyText: `"${tag}" was not added to tags. Please try again later.`,
          iconVariant: 'warning',
        });
      }
    } else {
      if (isFromCardView) {
        createToast({
          bodyText: 'Tag already exists',
          iconVariant: 'warning',
        });
      } else {
        setHighlightedTag(tag);
      }
    }
  };

  const removeTag = async (tag: string) => {
    if (!tag.trim()) return;

    const mediaGroupTagLabels = mediaGroup.tags.map((t) => t.label);

    if (mediaGroupTagLabels.includes(tag)) {
      const tags = mediaGroupTagLabels.filter((t) => t !== tag);

      const result = await patchMediaGroup({
        mediaGroupId: mediaGroup.id,
        patch: {
          tags,
        },
      });

      if (result.kind === 'success') {
        createToast({
          titleText: 'Tag removed',
          bodyText: `"${tag}" was removed from tags.`,
          iconVariant: 'success',
        });
      } else {
        createToast({
          titleText: 'Failed to remove tag',
          bodyText: `"${tag}" was not removed from tags. Please try again later.`,
          iconVariant: 'warning',
        });
      }
    }
  };

  return {
    addTag,
    removeTag,
    highlightedTag,
    setShowTagCreationInput,
    showTagCreationInput,
  };
};
