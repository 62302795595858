import { type MediaGroupWithCategoryFields, exists } from '@spaceduck/api';
import { useMemo, useReducer, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import type { TableColumnMeta } from '@/types/Category';

export type Direction = 'down' | 'left' | 'right' | 'up';
export type CellSelection = { mediaGroupId: string; propertyId: string } | null;
export type CellSelectionAction =
  | { type: 'CLEAR' }
  | { type: 'MOVE'; payload: Direction }
  | { type: 'SET'; payload: CellSelection };

export const useCategoryCellSelection = ({
  mediaGroups,
  sortedColumns,
}: {
  mediaGroups?: MediaGroupWithCategoryFields[];
  sortedColumns: TableColumnMeta[];
}) => {
  const propertyIds = useMemo(() => {
    return ['_mediaGroup', ...sortedColumns.filter(exists).map((field) => field.id)];
  }, [sortedColumns]);

  const mediaGroupIds = useMemo(() => {
    return mediaGroups?.filter(exists).map((mediaGroup) => mediaGroup.id) ?? [];
  }, [mediaGroups]);

  const cellSelectionReducer = (
    state: CellSelection,
    action: CellSelectionAction
  ): CellSelection => {
    switch (action.type) {
      case 'CLEAR':
        return null;
      case 'SET': {
        const { payload } = action;

        if (payload === null) {
          return null;
        }

        const { mediaGroupId, propertyId } = payload;
        if (propertyIds.includes(propertyId) && mediaGroupIds.includes(mediaGroupId)) {
          return { ...payload };
        }

        return state;
      }
      case 'MOVE': {
        if (state === null) {
          if (!!mediaGroupIds.length && !!propertyIds.length) {
            return {
              mediaGroupId: mediaGroupIds[0]!,
              propertyId: propertyIds[0]!,
            };
          }

          return null;
        }

        const currentMediaGroupIndex = mediaGroupIds.findIndex(
          (mediaGroupId) => mediaGroupId === state.mediaGroupId
        );

        const currentPropertyIndex = propertyIds.findIndex(
          (propertyId) => propertyId === state.propertyId
        );

        switch (action.payload) {
          case 'down': {
            if (currentMediaGroupIndex + 1 <= mediaGroupIds.length - 1) {
              return {
                ...state,
                mediaGroupId: mediaGroupIds[currentMediaGroupIndex + 1]!,
              };
            }

            return state;
          }
          case 'left': {
            if (currentPropertyIndex - 1 >= 0) {
              return {
                ...state,
                propertyId: propertyIds[currentPropertyIndex - 1]!,
              };
            }

            return state;
          }
          case 'right': {
            if (currentPropertyIndex + 1 <= propertyIds.length - 1) {
              return {
                ...state,
                propertyId: propertyIds[currentPropertyIndex + 1]!,
              };
            }

            return state;
          }
          case 'up': {
            if (currentMediaGroupIndex - 1 >= 0) {
              return {
                ...state,
                mediaGroupId: mediaGroupIds[currentMediaGroupIndex - 1]!,
              };
            }

            return state;
          }
          default:
            return state;
        }
      }
      default:
        return state;
    }
  };

  const [selectedCell, setSelectedCell] = useReducer(cellSelectionReducer, null);

  return { selectedCell, setSelectedCell };
};

export const useCategoryCellSelectionKeyboard = ({
  enableOnLoad = true,
  onEnter,
  onEscape,
  setSelectedCell,
}: {
  enableOnLoad?: boolean;
  onEnter?: () => void;
  onEscape?: () => void;
  setSelectedCell: React.Dispatch<CellSelectionAction>;
}) => {
  const [enabled, setEnabled] = useState(enableOnLoad);

  const goDown = () => setSelectedCell({ type: 'MOVE', payload: 'down' });
  const goLeft = () => setSelectedCell({ type: 'MOVE', payload: 'left' });
  const goRight = () => setSelectedCell({ type: 'MOVE', payload: 'right' });
  const goUp = () => setSelectedCell({ type: 'MOVE', payload: 'up' });

  const onEnterFn = () => {
    onEnter?.();
  };
  const onEscapeFn = () => {
    onEscape?.();
  };

  useHotkeys('Enter', onEnterFn, {
    enabled,
    enableOnFormTags: false,
    preventDefault: true,
  });

  useHotkeys('Escape', onEscapeFn, {
    enabled,
    enableOnFormTags: false,
    preventDefault: true,
  });

  useHotkeys('ArrowDown', goDown, {
    enabled,
    enableOnFormTags: false,
    preventDefault: true,
  });

  useHotkeys('ArrowLeft', goLeft, {
    enabled,
    enableOnFormTags: false,
    preventDefault: true,
  });

  useHotkeys('ArrowRight', goRight, {
    enabled,
    enableOnFormTags: false,
    preventDefault: true,
  });

  useHotkeys('ArrowUp', goUp, {
    enabled,
    enableOnFormTags: false,
    preventDefault: true,
  });

  return {
    setEnabled,
  };
};
