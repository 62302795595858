import type { MutableRefObject } from 'react';
import { useLightboxState } from 'yet-another-react-lightbox';
import type { ZoomRef } from 'yet-another-react-lightbox';

import LightboxMenuButton from './LightboxMenuButton';
import styles from './LightboxModalHeader.module.scss';

export default function LighboxModalHeader({
  canZoomIn,
  canZoomOut,
  zoomRef,
}: {
  canZoomIn: boolean;
  canZoomOut: boolean;
  zoomRef?: MutableRefObject<ZoomRef | null>;
}) {
  const { currentSlide } = useLightboxState();

  const handleZoomIn = () => zoomRef?.current?.zoomIn();
  const handleZoomOut = () => zoomRef?.current?.zoomOut();

  return (
    <div className={styles.header}>
      <nav className={styles.navigation}>
        <LightboxMenuButton type="close" />
        <span className={styles.divider} />
        <LightboxMenuButton type="prev" />
        <LightboxMenuButton type="next" />
        {zoomRef && currentSlide?.type === 'image' && (
          <>
            <span className={styles.divider} />
            <LightboxMenuButton
              disabled={!canZoomIn}
              onClick={handleZoomIn}
              type="zoomIn"
            />
            <LightboxMenuButton
              disabled={!canZoomOut}
              onClick={handleZoomOut}
              type="zoomOut"
            />
          </>
        )}
      </nav>
    </div>
  );
}
