import { useListMediaGroups } from '@/api/mediaGroup';
import MediaGroupGrid from '@/components/MediaGroupGrid';
import NoEntries from '@/components/NoEntries';
import useWorkspaceId from '@/hooks/useWorkspaceId';
import breadcrumbStyles from '@ui/Breadcrumb.module.scss';
import styles from './Trash.module.scss';

import { useRestoreProject } from '@/api/project';
import { catchApiErrorIntoToast } from '@/api/util';
import { useWorkspaceProjects } from '@/api/workspace';
import Head from '@/components/Head';
import ProjectListItem from '@/components/ProjectListItem';
import Breadcrumb from '@/components/ui/Breadcrumb';
import Toast from '@/components/ui/Toast';
import createToast from '@/utils/createToast';
import type { ProjectDTO } from '@spaceduck/api';
import { Icon16, Icon64 } from '@spaceduck/icons';
import { useEffect } from 'react';
import { toast } from 'sonner';
import { TrashTabs } from './common/TrashTabs';
const { Trash } = Icon64;
const { Delete } = Icon16;

const TRASH_TOAST_ID = 'trashToast';

function DeletedProjectItem({ project }: { project: ProjectDTO }) {
  const { mutateAsync: restoreProject } = useRestoreProject();
  const restore = catchApiErrorIntoToast(async () => {
    await restoreProject(project.id);
    createToast({
      bodyText: 'Project restored',
      iconVariant: 'success',
    });
  });
  return (
    <div className={styles.deletedProject}>
      <div onClick={restore} className={styles.restoreOverlay}>
        <p>Restore</p>
      </div>
      <div className={styles.projectCard}>
        <ProjectListItem key={project.id} project={project} />
      </div>
    </div>
  );
}

function DeletedProjectList({ workspaceId }: { workspaceId: string }) {
  const { data: projects } = useWorkspaceProjects(workspaceId, {
    isDeleted: true,
  });
  if (!projects?.projects.length) {
    return <EmptyTrash />;
  }
  return (
    <div className={styles.projects}>
      {projects.projects.map((project) => (
        <DeletedProjectItem key={project.id} project={project} />
      ))}
    </div>
  );
}

const EmptyTrash = () => {
  return (
    <div className={styles.emptyTrashContainer}>
      <NoEntries icon={<Trash />}>
        <h1>So clean. Much good. Wow.</h1>
        <p>
          One man's trash is another man's job to take out... Yours is so clean, so
          good, so empty—just like my emotions.
        </p>
      </NoEntries>
    </div>
  );
};

export default function TrashPage({ show }: { show: 'items' | 'projects' }) {
  const workspaceId = useWorkspaceId();
  const { data, isError, isFetchingNextPage, isLoading } = useListMediaGroups(
    workspaceId,
    {
      isDeleted: true,
    }
  );
  useEffect(() => {
    toast.custom(
      (t) => (
        <Toast
          title={'Trash'}
          body={
            'Your items will be permanently deleted after 30 days. To restore an item, simply click on it.'
          }
          iconVariant={'info'}
          onClick={() => toast.dismiss(t)}
          buttonText="Got it"
        />
      ),
      {
        id: TRASH_TOAST_ID,
      }
    );
  }, []);
  if (!workspaceId) {
    return null;
  }
  const mediaGroups = data?.pages.flatMap((page) => page.mediaGroups) || [];
  return (
    <>
      <Head title={'Trash'} />
      <header className={breadcrumbStyles.headerBreadcrumbs}>
        <Breadcrumb
          breadcrumb={[
            {
              icon: <Delete />,
              text: 'Trash',
            },
          ]}
        />
      </header>
      <TrashTabs />
      {show === 'items' && (
        <MediaGroupGrid
          handleSearchClick={() => {}}
          isError={isError}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isLoading}
          noResults={<EmptyTrash />}
          mediaGroups={mediaGroups}
          selected={new Set()}
          onSelected={() => {}}
        />
      )}
      {show === 'projects' && <DeletedProjectList workspaceId={workspaceId} />}
    </>
  );
}
