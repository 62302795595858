import { exists } from '@spaceduck/utils';
import { useMemo } from 'react';

export type Cookie = [key: string, value: string];
export type CookieJar = Cookie[];

const parseCookie = (cookie: string): CookieJar => {
  // Extremely naive parsing, but current use cases only care about the key anyway
  return cookie
    .split(';')
    .map((entry): Cookie | null => {
      const result = entry
        .trim()
        .split('=', 2)
        .map((value) => decodeURIComponent(value));
      if (result.length !== 2) {
        console.error('Cookie parse error: expected key and value', { got: entry });
        return null;
      }
      return result as Cookie;
    })
    .filter(exists);
};

export const useCookies = () => {
  const cookie = document.cookie;
  return useMemo(() => {
    return parseCookie(cookie);
  }, [cookie]);
};
