import type { AliveCommentDTO } from '@spaceduck/api';
import { Icon16 } from '@spaceduck/icons';
import { Extension } from '@tiptap/core';
import { type Editor, ReactRenderer } from '@tiptap/react';
import dayjs from 'dayjs';
import { Plugin, PluginKey } from 'prosemirror-state';
import { useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import tippy, { type Instance, type Props as TProps } from 'tippy.js';

import { urlFor } from '@/urls';
import { useListComments } from '@api/comment';
import { useCommentContent } from '@components/detailsModal/comments/Comment';
import { useNavigateWithState } from '@hooks/useNavigateWithState';
import Menu from '@ui/Menu';
import UserAvatar from '@ui/UserAvatar';
import { copyTextToClipboard } from '@utils/copyToClipboard';
import styles from './HoverMenu.module.scss';
import { parseURL } from './MediaCard';
import EditLinkForm from './link/EditLinkForm';

const { Copy, EditPencil, Link, LinkCard, LinkEmbed, Open, Unlink } = Icon16;

type View = 'card' | 'inline' | 'embed';

const defaultTippySettings = (tippyRootId: string) =>
  ({
    allowHTML: true,
    interactive: true,
    // Appending to body causes input focus issues
    appendTo: () => document.getElementById(tippyRootId)!,
    maxWidth: 'none',
    hideOnClick: false,
    arrow: false,
    offset: [0, 0],
    popperOptions: {
      placement: 'top',
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top'],
          },
        },
      ],
    },
  }) as Partial<TProps>;

export default (tippyRootId: string | undefined = 'tippyHoverMenuRoot') =>
  Extension.create({
    name: 'hoverMenu',
    addProseMirrorPlugins() {
      return [
        new Plugin({
          key: new PluginKey('HoverMenu'),
          props: {
            handleDOMEvents: {
              mouseover(view, event) {
                const { to, from } = view.state.selection;

                // Remove duplicate instances of tippy
                if (
                  (event.target as HTMLElement).tagName?.toUpperCase() === 'A' ||
                  to !== from ||
                  ((event.target as HTMLElement).tagName?.toUpperCase() === 'DIV' &&
                    (event.target as HTMLElement).getAttribute('data-media-card') &&
                    !(event.target as HTMLElement).classList.contains(
                      'ProseMirror-selectednode'
                    )) ||
                  (event.target as HTMLElement).getAttribute('data-comment')
                ) {
                  (window as any).tippyLinkQuickMenu?.destroy?.();
                  (window as any).tippyLinkQuickMenu = null;
                }

                if (
                  to === from &&
                  (event.target as HTMLElement).tagName?.toUpperCase() === 'A'
                ) {
                  const targetPos = view.posAtDOM(event.target as HTMLElement, 0);
                  const targetNode = view.nodeDOM(targetPos)?.parentNode as HTMLElement;

                  const component = new ReactRenderer(MediaComponent, {
                    props: {
                      editor: this.spec.editor,
                      pos: targetPos,
                      targetNode,
                      type: 'inline',
                    },
                    editor: this.spec.editor,
                  });

                  if (targetNode && document.getElementById(tippyRootId)) {
                    const instance = tippy(targetNode as HTMLElement, {
                      ...defaultTippySettings(tippyRootId),
                      content: component.element,
                    });
                    (window as any).tippyLinkQuickMenu = instance;
                  }
                } else if (
                  to === from &&
                  (event.target as HTMLElement).getAttribute('data-comment')
                ) {
                  const id = (event.target as HTMLElement).getAttribute(
                    'data-comment-id'
                  );
                  if (!id) return true;

                  const targetPos = view.posAtDOM(event.target as HTMLElement, 0);
                  const targetNode = view.nodeDOM(targetPos) as HTMLElement;
                  const targetNodeAt = view.state.doc.nodeAt(targetPos);

                  if (!targetNodeAt?.marks?.length) return true;
                  const firstComment = targetNodeAt.marks.find(
                    (mark) => mark.type.name === 'comment'
                  );

                  if (!firstComment) return true;
                  const component = new ReactRenderer(CommentComponent, {
                    props: {
                      commentId: id,
                    },
                    editor: this.spec.editor,
                  });

                  if (targetNode && document.getElementById(tippyRootId)) {
                    const instance = tippy(targetNode.parentElement as HTMLElement, {
                      content: component.element,
                      ...defaultTippySettings,
                    });
                    (window as any).tippyLinkQuickMenu = instance;
                  }
                } else if (
                  (event.target as HTMLElement).tagName?.toUpperCase() === 'DIV' &&
                  (event.target as HTMLElement).getAttribute('data-media-card')
                ) {
                  const targetPos = view.posAtDOM(event.target as HTMLElement, 0);
                  const targetNodeDom = view.nodeDOM(targetPos) as HTMLElement;
                  const targetNode = targetNodeDom.querySelector(
                    '[data-media-card="true"]'
                  );

                  if (
                    to === from ||
                    targetNodeDom.classList.contains('ProseMirror-selectednode')
                  ) {
                    const component = new ReactRenderer(MediaComponent, {
                      props: {
                        editor: this.spec.editor,
                        pos: targetPos,
                        targetNode,
                        type:
                          targetNode?.getAttribute('data-show-preview') === 'true'
                            ? 'embed'
                            : 'card',
                      },
                      editor: this.spec.editor,
                    });

                    if (targetNode && document.getElementById(tippyRootId)) {
                      const instance = tippy(targetNode as HTMLElement, {
                        content: component.element,
                        ...defaultTippySettings,
                      });
                      (window as any).tippyLinkQuickMenu = instance;
                    }
                  }
                }

                return false;
              },
            },
          },
          editor: this.editor,
          ...this.options.hoverMenu,
        }),
      ];
    },
  });

const MediaComponent = ({
  editor,
  pos,
  targetNode,
  type,
}: {
  editor: Editor;
  pos: number;
  targetNode?: HTMLElement;
  type: View;
}) => {
  const data = getLinkData(targetNode);
  const embedDetails = parseURL(data?.href);
  const editButtonRef = useRef<HTMLButtonElement | null>(null);
  const tippyRef = useRef<Instance<TProps> | null>(null);
  const formRef = useRef<HTMLDivElement | null>(null);

  const clearInstance = () => {
    (window as any).tippyLinkQuickMenu?.destroy?.();
    (window as any).tippyLinkQuickMenu = null;
  };

  const handleOpen = () => {
    let url = null;
    if (type === 'inline') {
      url = targetNode?.getAttribute('href');
    } else {
      url = targetNode?.getAttribute('data-href');
    }

    if (url) {
      window.open(url);
    }
  };

  const handleCopy = () => {
    let url = null;
    if (type === 'inline') {
      url = targetNode?.getAttribute('href');
    } else {
      url = targetNode?.getAttribute('data-href');
    }

    if (url) {
      copyTextToClipboard(url, {
        bodyText: 'Link copied to clipboard!',
      });
    }
  };

  const handleUnlink = () => {
    if (type === 'inline') {
      editor.chain().setNodeSelection(pos).extendMarkRange('link').unsetLink().run();
    } else {
      editor
        .chain()
        .setNodeSelection(pos)
        .deleteSelection()
        .insertContentAt(pos, `<p>${data.content}</p>`)
        .run();
    }
    clearInstance();
  };

  const handleViewChange = (format: View) => {
    if (format === type) return true;

    switch (format) {
      case 'card':
      case 'embed':
        editor
          .chain()
          .setNodeSelection(pos)
          .extendMarkRange('link')
          .deleteSelection()
          .insertContentAt(pos, [
            {
              type: 'media-card',
              attrs: {
                'data-href': data.href,
                'data-target': data.target,
                'data-content': data.content,
                'data-show-preview': String(
                  embedDetails.isYoutube && format === 'embed'
                ),
              },
            },
            {
              type: 'paragraph',
            },
          ])
          .run();
        clearInstance();
        break;
      case 'inline':
        editor
          .chain()
          .setNodeSelection(pos)
          .deleteSelection()
          .insertContentAt(
            pos,
            `<p><a href="${data.href}" target="${data.target}">${data.content}</a></p>`
          )
          .run();
        clearInstance();
        break;
    }
    return true;
  };

  useEffect(() => {
    if (editButtonRef.current && formRef.current) {
      tippyRef.current = tippy(editButtonRef.current, {
        ...defaultTippySettings,
        content: formRef.current,
        interactive: true,
        trigger: 'click',
        offset: [0, 5],
      });
    }
  }, []);

  const handleFormHide = () => {
    tippyRef.current?.destroy();
  };

  if (!editor.isEditable) return null;

  return (
    <div>
      <Menu
        offset={[0, 0]}
        menuItems={[
          {
            icon: <Open />,
            onClick: handleOpen,
            tooltip: 'Open link',
          },
          {
            isSeparator: true,
          },
          {
            icon: <Copy />,
            onClick: handleCopy,
            tooltip: 'Copy link',
          },
          {
            icon: <EditPencil />,
            ref: editButtonRef,
            tooltip: 'Edit link',
          },
          {
            icon: <Unlink />,
            onClick: handleUnlink,
            tooltip: 'Remove link',
          },
          {
            isSeparator: true,
          },
          {
            icon: <Link />,
            title: 'View',
            menuItems: [
              {
                label: 'Inline view',
                icon: <Link />,
                disabled: type === 'inline',
                showCheck: type === 'inline',
                onClick: () => handleViewChange('inline'),
              },
              {
                label: 'Card view',
                icon: <LinkCard />,
                disabled: type === 'card',
                showCheck: type === 'card',
                onClick: () => handleViewChange('card'),
              },
            ].concat(
              embedDetails.isYoutube
                ? [
                    {
                      label: 'Embed view',
                      icon: <LinkEmbed />,
                      disabled: type === 'embed',
                      showCheck: type === 'embed',
                      onClick: () => handleViewChange('embed'),
                    },
                  ]
                : []
            ),
          },
        ]}
      />
      <div style={{ display: 'none' }}>
        <div ref={formRef}>
          <EditLinkForm
            hideForm={handleFormHide}
            initialTextValue={
              (type === 'inline'
                ? targetNode?.innerText
                : targetNode?.getAttribute('data-content')) ?? undefined
            }
            initialValue={
              (type === 'inline'
                ? targetNode?.getAttribute('href')
                : targetNode?.getAttribute('data-href')) ?? undefined
            }
            initialOpenInNewTab={
              type === 'inline'
                ? targetNode?.getAttribute('target') === '_blank'
                : targetNode?.getAttribute('data-target') === '_blank'
            }
            onCreateLink={(url, openInNewTab, text) => {
              if (type === 'inline') {
                if (targetNode) {
                  targetNode.setAttribute('href', url);
                  targetNode.setAttribute('target', openInNewTab ? '_blank' : 'self');
                  targetNode.innerText = text || url;
                }
              } else {
                editor
                  .chain()
                  .setNodeSelection(pos)
                  .deleteSelection()
                  .insertContentAt(pos, [
                    {
                      type: 'media-card',
                      attrs: {
                        'data-href': url,
                        'data-target': openInNewTab ? '_blank' : 'self',
                        'data-content': text || url,
                        'data-show-preview': 'false',
                      },
                    },
                  ])
                  .run();
              }
              handleFormHide();
            }}
            onDelete={() => {
              if (type === 'inline') {
                editor
                  .chain()
                  .setNodeSelection(pos)
                  .extendMarkRange('link')
                  .unsetLink()
                  .run();
              } else {
                editor
                  .chain()
                  .setNodeSelection(pos)
                  .deleteSelection()
                  .insertContentAt(pos, `<p>${data.content}</p>`)
                  .run();
              }
              handleFormHide();
            }}
            showTextField
          />
        </div>
      </div>
    </div>
  );
};

const CommentComponent = ({ commentId }: { commentId: string }) => {
  const { mediaGroupId } = useParams();

  if (!mediaGroupId) return null;

  return <CommentBox commentId={commentId} mediaGroupId={mediaGroupId} />;
};

const CommentBox = ({
  commentId,
  mediaGroupId,
}: {
  commentId: string;
  mediaGroupId: string;
}) => {
  const { data } = useListComments(mediaGroupId);
  if (!data?.comments) return null;
  const currentComment = data.comments.find((comment) => comment.id === commentId);

  if (currentComment?.kind !== 'alive') return null;

  return <AliveComment comment={currentComment} mediaGroupId={mediaGroupId} />;
};

const AliveComment = ({
  comment,
  mediaGroupId,
}: {
  comment: AliveCommentDTO;
  mediaGroupId: string;
}) => {
  const { component } = useCommentContent(comment, true, false);
  const navigateWithState = useNavigateWithState();
  const url = urlFor('mediaGroupComments', { mediaGroupId });
  const location = useLocation();

  const scrollSidebarToComment = () => {
    const commentInSidebar = document.querySelector(
      `.commentsSidebar [data-comment-id="${comment.id}"]`
    );
    if (commentInSidebar) {
      commentInSidebar.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    }
  };

  const handleClick = () => {
    if (location.pathname !== url) {
      navigateWithState(url, {});
    } else {
      scrollSidebarToComment();
    }
  };

  return (
    <div className={styles.commentTextBox} onClick={handleClick}>
      <UserAvatar
        name={comment.createdBy.name}
        imageUrl={comment.createdBy.avatarUrl}
        size="xxs"
      />
      <div>
        <div className={styles.meta}>
          <span className={styles.createdInfo}>
            <span className={styles.createdByName} title={comment.createdBy.name}>
              {comment.createdBy.name}
            </span>
            <span className={styles.createdAt}>
              {dayjs(comment.createdAt).fromNow()}
            </span>
          </span>
        </div>
        <div className={styles.summary}>{component}</div>
      </div>
    </div>
  );
};

function getLinkData(node?: HTMLElement) {
  if (node instanceof HTMLAnchorElement) {
    return {
      href: node.href,
      target: node.target,
      content: node.innerHTML,
    };
  }
  if (node?.hasAttribute('data-media-card')) {
    return {
      href: node.getAttribute('data-href') ?? undefined,
      target: node.getAttribute('data-target') ?? undefined,
      content: node.getAttribute('data-content') ?? undefined,
    };
  }

  return {};
}
