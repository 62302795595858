import type { MediaGroupDTO, MediaGroupDetailDTO } from '@spaceduck/api';
import upperFirst from 'lodash/upperFirst';
import { useNavigate } from 'react-router-dom';

import {
  type TransferMode,
  useCopyMediaGroup,
  useDeleteMediaGroup,
} from '@api/mediaGroup';
import { useBackgroundLocation } from '@hooks/useBackgroundLocation';
import { useMediaGroupPermissions } from '@hooks/usePermissions';
import createToast from '@utils/createToast';
import { getCloseTo } from '@utils/mediaGroup';
import useDeleteMediaGroupConfirmModal from './useDeleteMediaGroupConformModal';
import useMoveMediaGroupConfirmModal from './useMoveMediaGroupConfirmModal';

export const useMediaGroupTransfer = (
  mediaGroup?: MediaGroupDTO | MediaGroupDetailDTO
) => {
  const { mutateAsync: copyMediaGroup } = useCopyMediaGroup();
  const { canEdit } = useMediaGroupPermissions(mediaGroup);

  const copyOrMove =
    (mode: TransferMode) =>
    async ({
      mediaGroupId,
      projectId,
      onSuccess,
    }: {
      mediaGroupId?: string;
      projectId?: string;
      onSuccess?: () => void;
    }) => {
      if (!mediaGroupId) {
        createToast({
          iconVariant: 'danger',
          titleText: `${upperFirst(mode)} error`,
          bodyText: `Unable to ${mode} media group`,
        });
        return;
      }

      try {
        await copyMediaGroup({
          mediaGroupIds: [mediaGroupId],
          projectId,
          mode,
        });
      } catch (ex) {
        createToast({
          iconVariant: 'danger',
          titleText: `${upperFirst(mode)} error`,
          bodyText: `Something went wrong when trying to ${mode}`,
        });
        console.error('Failed to copy media group', ex);
        return;
      }

      onSuccess?.();
    };

  const { open: openMoveConfirmationModal } = useMoveMediaGroupConfirmModal();

  const navigate = useNavigate();
  const backgroundLocation = useBackgroundLocation();
  const { mutateAsync: deleteMutation } = useDeleteMediaGroup();

  const openDeleteMediaGroupConfirmModal = useDeleteMediaGroupConfirmModal({
    onConfirm: async (id: string) => {
      console.log('id');
      console.log(id);
      await deleteMutation(id);
    },
    onSuccess: () => {
      createToast({
        bodyText: 'Item deleted',
        iconVariant: 'success',
      });
      navigate(backgroundLocation || getCloseTo(mediaGroup));
    },
  });

  const permissionsErrorToast = () =>
    createToast({
      iconVariant: 'danger',
      titleText: 'Unauthorized action',
      bodyText: 'You do not have permission to perform this action',
    });

  if (!canEdit) {
    return {
      copy: () => permissionsErrorToast(),
      delete: () => permissionsErrorToast(),
      move: () => permissionsErrorToast(),
      moveWithoutConfirmation: () => permissionsErrorToast(),
    };
  }

  return {
    copy: copyOrMove('copy'),
    delete: openDeleteMediaGroupConfirmModal,
    move: openMoveConfirmationModal,
    moveWithoutConfirmation: copyOrMove('move'),
  };
};
