import { Icon16 } from '@spaceduck/icons';
import type { Editor, Range } from '@tiptap/react';

export type CommandProps = {
  editor: Editor;
  range: Range;
};

const {
  BulletedList,
  CodeBlock,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  HorizontalRule,
  Image,
  Link,
  NumberedList,
  Project,
  Quote,
  Table,
  TodoList,
  TextT,
} = Icon16;

const slashMenuItems = () => allItems;

function getHeadingIcon(idx: number) {
  switch (idx) {
    case 1:
      return <Heading1 />;
    case 2:
      return <Heading2 />;
    case 3:
      return <Heading3 />;
    case 4:
      return <Heading4 />;
    case 5:
      return <Heading5 />;
    case 6:
      return <Heading6 />;
    default:
      return null;
  }
}

const formatItems = [
  {
    title: 'Text',
    icon: <TextT />,
    command: ({ editor, range }: CommandProps) => {
      editor.chain().focus().deleteRange(range).setNode('paragraph').run();
    },
  },
  ...[1, 2, 3, 4, 5, 6].map((count) => {
    return {
      title: `Heading ${count}`,
      icon: getHeadingIcon(count),
      aliases: [`H${count}`],
      command: ({ editor, range }: CommandProps) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode('heading', { level: count })
          .run();
      },
    };
  }),
  {
    title: 'Code block',
    icon: <CodeBlock />,
    command: ({ editor, range }: CommandProps) => {
      editor.chain().focus().deleteRange(range).toggleCodeBlock().run();
    },
  },
  {
    title: 'Quote',
    icon: <Quote />,
    command: ({ editor, range }: CommandProps) => {
      editor.chain().focus().deleteRange(range).toggleBlockquote().run();
    },
  },
];

const listItems = [
  {
    title: 'Bulleted',
    aliases: ['list', 'ul'],
    icon: <BulletedList />,
    command: ({ editor, range }: CommandProps) => {
      editor.chain().focus().deleteRange(range).toggleBulletList().run();
    },
  },
  {
    title: 'Numbered',
    aliases: ['list', 'ol'],
    icon: <NumberedList />,
    command: ({ editor, range }: CommandProps) => {
      editor.chain().focus().deleteRange(range).toggleOrderedList().run();
    },
  },
  {
    title: 'Todo',
    icon: <TodoList />,
    command: ({ editor, range }: CommandProps) => {
      editor.chain().focus().deleteRange(range).toggleTaskList().run();
    },
  },
];

const insertItems = [
  {
    title: 'Table',
    icon: <Table />,
    command: ({ editor, range }: CommandProps) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
        .run();
    },
  },
  {
    title: 'Horizontal rule',
    aliases: ['hr'],
    icon: <HorizontalRule />,
    command: ({ editor, range }: CommandProps) => {
      editor.chain().focus().deleteRange(range).setHorizontalRule().run();
    },
  },
];

const noop = () => {};

const contentAndMediaItems = [
  {
    title: 'Spaceduck items',
    icon: <Project />,
    command: ({ editor, range }: CommandProps) => {
      editor.chain().deleteRange(range).insertContent('+').run();
    },
  },
  {
    title: 'Image',
    icon: <Image />,
    command: ({ editor, range }: CommandProps) => {
      editor.chain().deleteRange(range).run();
      const isQuickView = editor.view.dom.getAttribute('data-media-group-id');
      const id = isQuickView
        ? 'quickViewNotesImageUploadInput'
        : 'notesImageUploadInput';
      document.getElementById(id)?.click();
    },
  },
  {
    title: 'Link',
    icon: <Link />,
    command: noop,
    view: 'linkView',
  },
];

const allItems = [
  {
    title: 'Format',
    submenu: formatItems,
    command: noop,
  },
  {
    title: 'List',
    submenu: listItems,
    command: noop,
  },
  {
    title: 'Insert',
    submenu: insertItems,
    command: noop,
  },
  {
    title: 'Content and Media',
    submenu: contentAndMediaItems,
    command: noop,
  },
];

export default slashMenuItems;
