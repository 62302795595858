import { type Editor, EditorContent } from '@tiptap/react';
import clsx from 'clsx';
import { useRef } from 'react';
import '@fontsource/jetbrains-mono';

import SelectionMenu from './SelectionMenu';
import './content.scss';
import styles from './Editor.module.scss';

type TipTapEditor = {
  className?: string;
  editor?: Editor | null;
  mediaGroupId: string;
};

export default function TipTapEditor({
  className,
  editor,
  mediaGroupId,
}: TipTapEditor) {
  const editorRef = useRef<HTMLDivElement | null>(null);

  if (!(mediaGroupId && editor)) return null;

  return (
    <>
      <SelectionMenu editor={editor} />
      <EditorContent
        className={clsx(className, styles.editor)}
        editor={editor}
        ref={editorRef}
      />
    </>
  );
}
