import type { MediaGroupDTO } from '@spaceduck/api';
import { type Table, flexRender } from '@tanstack/react-table';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import { memo, useEffect, useRef } from 'react';

import type {
  CellSelection,
  CellSelectionAction,
} from '@/hooks/useCategoryCellSelection';
import type { Row } from '@/types/Category';
import ContextMenu, { type ContextMenuItemProps } from '@ui/ContextMenu';
import styles from './TableBody.module.scss';

type Coordinates = {
  mediaGroupId: string;
  propertyId: string;
};

export default function TableBody({
  multipleSelectionMenuItems,
  selectedCell,
  selectedRows,
  setSelectedCell,
  singleSelectionMenuItems,
  table,
}: {
  multipleSelectionMenuItems: ContextMenuItemProps[];
  selectedCell: CellSelection;
  selectedRows: string[];
  setSelectedCell: React.Dispatch<CellSelectionAction>;
  singleSelectionMenuItems: ContextMenuItemProps[];
  table: Table<Row>;
}) {
  return (
    <tbody className={styles.tbody}>
      {table.getRowModel().rows.map((row) => {
        const firstCell = row.getAllCells()?.[0];
        const highlighted =
          firstCell &&
          selectedRows.includes(
            (firstCell.getValue() as MediaGroupDTO | null)?.id ?? ''
          );

        return (
          <tr className={clsx(highlighted && styles.highlighted)} key={row.id}>
            {row.getVisibleCells().map((cell) => {
              const coordinates: Coordinates = {
                mediaGroupId: cell.row.original._id,
                propertyId: cell.column.id,
              };

              const focused = isEqual(selectedCell, coordinates);

              return (
                <td key={cell.id}>
                  {selectedRows.length && highlighted ? (
                    <>
                      {/* Used to cover entire TD space */}
                      <ContextMenu
                        className={styles.contextMenuArea}
                        items={
                          selectedRows.length === 1
                            ? singleSelectionMenuItems
                            : multipleSelectionMenuItems
                        }
                      >
                        <div />
                      </ContextMenu>
                      <ContextMenu
                        items={
                          selectedRows.length === 1
                            ? singleSelectionMenuItems
                            : multipleSelectionMenuItems
                        }
                      >
                        <FocusOverlay
                          coordinates={coordinates}
                          focused={focused}
                          setSelectedCell={setSelectedCell}
                        />
                        <div>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </div>
                      </ContextMenu>
                    </>
                  ) : (
                    <>
                      <FocusOverlay
                        coordinates={coordinates}
                        focused={focused}
                        setSelectedCell={setSelectedCell}
                      />
                      <div>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </div>
                    </>
                  )}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
}

const FocusOverlay = ({
  coordinates,
  focused,
  setSelectedCell,
}: {
  coordinates: Coordinates;
  focused: boolean;
  setSelectedCell: React.Dispatch<CellSelectionAction>;
}) => {
  const focusedElementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (focused && focusedElementRef.current) {
      focusedElementRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [focused]);

  return (
    <div
      className={clsx(styles.selectHighlightable, focused && styles.selectHighlighted)}
      onClick={(ev) => {
        ev.stopPropagation();

        setSelectedCell({
          type: 'SET',
          payload: coordinates,
        });

        return true;
      }}
      ref={focusedElementRef}
    />
  );
};

export const MemoizedTableBody = memo(
  TableBody,
  (prev, next) => prev.table.options.data === next.table.options.data
) as typeof TableBody;
