import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.57576 4.84766C5.97742 4.84766 6.30303 5.17327 6.30303 5.57493C6.30303 6.66794 6.73723 7.71619 7.51011 8.48907C8.28298 9.26194 9.33123 9.69614 10.4242 9.69614C10.8259 9.69614 11.1515 10.0218 11.1515 10.4234C11.1515 10.8251 10.8259 11.1507 10.4242 11.1507C9.33123 11.1507 8.28298 11.5849 7.51011 12.3578C6.73723 13.1306 6.30303 14.1789 6.30303 15.2719C6.30303 15.6736 5.97742 15.9992 5.57576 15.9992C5.1741 15.9992 4.84848 15.6736 4.84848 15.2719C4.84848 14.7307 4.74189 14.1948 4.53478 13.6948C4.32767 13.1948 4.0241 12.7405 3.64141 12.3578C3.25872 11.9751 2.8044 11.6715 2.30439 11.4644C1.80438 11.2573 1.26848 11.1507 0.727273 11.1507C0.325611 11.1507 0 10.8251 0 10.4234C0 10.0218 0.325611 9.69614 0.727273 9.69614C1.26848 9.69614 1.80438 9.58954 2.30439 9.38243C2.8044 9.17532 3.25872 8.87176 3.64141 8.48907C4.0241 8.10638 4.32767 7.65206 4.53478 7.15205C4.74189 6.65204 4.84848 6.11613 4.84848 5.57493C4.84848 5.17327 5.1741 4.84766 5.57576 4.84766ZM5.57575 8.32835C5.32907 8.76272 5.02462 9.16289 4.66993 9.51758C4.31523 9.87228 3.91506 10.1767 3.48068 10.4234C3.91506 10.6701 4.31523 10.9745 4.66993 11.3292C5.02462 11.6839 5.32907 12.0841 5.57575 12.5185C5.82082 12.0869 6.12448 11.6863 6.48159 11.3292C6.83868 10.9721 7.23923 10.6685 7.67081 10.4234C7.23923 10.1783 6.83868 9.87468 6.48159 9.51758C6.12448 9.16048 5.82082 8.75993 5.57575 8.32835Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3629 0C12.7646 0 13.0902 0.325611 13.0902 0.727273C13.0902 1.30593 13.3201 1.86088 13.7292 2.27005C14.1384 2.67922 14.6934 2.90909 15.272 2.90909C15.6737 2.90909 15.9993 3.2347 15.9993 3.63636C15.9993 4.03803 15.6737 4.36364 15.272 4.36364C14.6934 4.36364 14.1384 4.59351 13.7292 5.00268C13.3201 5.41185 13.0902 5.9668 13.0902 6.54545C13.0902 6.94712 12.7646 7.27273 12.3629 7.27273C11.9613 7.27273 11.6357 6.94712 11.6357 6.54545C11.6357 5.9668 11.4058 5.41185 10.9966 5.00268C10.5874 4.59351 10.0325 4.36364 9.45384 4.36364C9.05217 4.36364 8.72656 4.03803 8.72656 3.63636C8.72656 3.2347 9.05217 2.90909 9.45384 2.90909C10.0325 2.90909 10.5874 2.67922 10.9966 2.27005C11.4058 1.86088 11.6357 1.30593 11.6357 0.727273C11.6357 0.325611 11.9613 0 12.3629 0ZM12.3629 2.90912C12.2603 3.04597 12.1475 3.17619 12.0251 3.29857C11.9027 3.42096 11.7725 3.53372 11.6357 3.63636C11.7725 3.73901 11.9027 3.85177 12.0251 3.97416C12.1475 4.09654 12.2603 4.22675 12.3629 4.36361C12.4656 4.22675 12.5783 4.09654 12.7007 3.97416C12.8231 3.85177 12.9533 3.73901 13.0902 3.63636C12.9533 3.53372 12.8231 3.42096 12.7007 3.29857C12.5783 3.17619 12.4656 3.04597 12.3629 2.90912Z"
        fill={color}
      />
    </svg>
  );
}
