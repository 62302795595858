import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import { useMediaGroupDetail } from '@api/mediaGroup';
import ErrorDuck from '@assets/img/ErrorDuck';
import Spinner from '@components/Spinner';
import DetailsModalNotesContent from '@components/detailsModal/DetailsModalNotesContent';
import { useNotesEditor } from '@hooks/useNotesEditor';
import type { MediaGroupDetailDTO } from '@spaceduck/api';
import styles from './MediaGroup.module.scss';
import BlockMenu from './components/BlockMenu';
import EmbedViewOptions from './components/EmbedViewOptions';
import FormatMenu from './components/FormatMenu';
import LinkForm from './components/LinkForm';
import MediaGroupMenu from './components/MediaGroupMenu';

export type ContentBlockNode = {
  attrs?: { id?: string; 'data-minimal-view'?: boolean };
  type?: { name: string };
};

export default function MediaGroupPage() {
  const { mediaGroupId } = useParams<{ mediaGroupId: string }>();
  if (!mediaGroupId) {
    throw new Error('TODO(@dbowring)');
  }
  return <Wrapper mediaGroupId={mediaGroupId} />;
}

const Wrapper = ({ mediaGroupId }: { mediaGroupId: string }) => {
  const { data, status } = useMediaGroupDetail(mediaGroupId);

  if (status === 'pending') {
    return <Spinner />;
  }

  if (status === 'error') {
    return (
      <div className={styles.errorContainer}>
        <ErrorDuck />
        <h1 className="h6">Content Error</h1>
        <p>Please try again later.</p>
      </div>
    );
  }

  if (data.mediaGroup.kind === 'document') {
    return (
      <>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
        </Helmet>
        <DocumentWrapper mediaGroup={data.mediaGroup} />
      </>
    );
  }

  return (
    <div className={styles.errorContainer}>
      <ErrorDuck />
      <h1 className="h6">Content Error</h1>
      <p>No preview available for this content.</p>
    </div>
  );
};

export type MenuView =
  | 'blockMenu'
  | 'embedViewOptions'
  | 'formatMenu'
  | 'linkForm'
  | 'mediaGroupMenu';

const DocumentWrapper = ({
  mediaGroup,
}: {
  mediaGroup: MediaGroupDetailDTO;
}) => {
  const [currentMenuView, setCurrentMenuView] = useState<MenuView>('blockMenu');
  const [focusedMediaGroupId, setFocusedMediaGroupId] = useState<string | null>(null);

  const { editor, handleImageUploadInputChange, imageUploadInputRef } = useNotesEditor(
    mediaGroup,
    {
      attributes: { 'data-media-group-id': mediaGroup?.id ?? '' },
    },
    undefined
  );

  useEffect(() => {
    const selection = editor?.view.state.selection;
    if (selection) {
      if ('node' in selection) {
        const node = selection.node as ContentBlockNode;
        if (
          node.attrs?.id &&
          node.type?.name &&
          /^(content-block)|(outgoing-link)/.test(node.type.name)
        ) {
          setFocusedMediaGroupId(node.attrs.id);
          return;
        }
      }
    }
    setFocusedMediaGroupId(null);
  }, [editor?.view.state.selection]);

  useEffect(() => {
    if (focusedMediaGroupId) {
      setCurrentMenuView('embedViewOptions');
      return;
    }

    setCurrentMenuView('blockMenu');
  }, [focusedMediaGroupId]);

  if (!mediaGroup?.id) return null;
  if (!editor) return null;

  return (
    <div className={styles.container}>
      <DetailsModalNotesContent editor={editor} mediaGroupId={mediaGroup.id} />
      {!!editor && (
        <div className={styles.menu}>
          {currentMenuView === 'blockMenu' && (
            <BlockMenu editor={editor} setView={setCurrentMenuView} />
          )}
          {currentMenuView === 'formatMenu' && (
            <FormatMenu editor={editor} setView={setCurrentMenuView} />
          )}
          {currentMenuView === 'linkForm' && (
            <LinkForm editor={editor} setView={setCurrentMenuView} />
          )}
          {currentMenuView === 'embedViewOptions' && (
            <EmbedViewOptions
              editor={editor}
              mediaGroupId={focusedMediaGroupId}
              setView={setCurrentMenuView}
            />
          )}
          {currentMenuView === 'mediaGroupMenu' && (
            <MediaGroupMenu editor={editor} setView={setCurrentMenuView} />
          )}
          <input
            accept="image/png, image/gif, image/jpeg"
            className={styles.hiddenInput}
            id="webViewImageUploadInput"
            multiple
            onChange={handleImageUploadInputChange}
            ref={imageUploadInputRef}
            type="file"
          />
        </div>
      )}
    </div>
  );
};
