import type { MediaGroupDetailDTO } from '@spaceduck/api';
import { Icon16, Icon24, Icon32 } from '@spaceduck/icons';
import clsx from 'clsx';
import useEmblaCarousel from 'embla-carousel-react';
import { useCallback } from 'react';

import { css } from '@/lib/css';
import Placeholder from '@assets/img/Placeholder';
import BunnyStreamEmbed from '@ui/BunnyStreamEmbed';
import Button from '@ui/Button';
import ScalableSVG from '@ui/ScalableSVG';
import Spinner from '@ui/Spinner';
import { getOrientation } from '@utils/carouselVideos';
import { FileIcon } from './FileIcon';
import styles from './PreviewCarousel.module.scss';
import { YoutubeEmbed } from './detailsModal/tiptap/nodes/ContentBlock';

const { Play } = Icon16;
const { Open } = Icon24;
const { Bookmark: BookmarkIcon } = Icon32;

const CAROUSEL_MIN_HEIGHT = 42;

export default function PreviewCarousel({
  asQuickView,
  mediaGroup,
}: {
  asQuickView?: boolean;
  mediaGroup: MediaGroupDetailDTO;
}) {
  // TODO(@dbowring): Support user swapping between render styles.
  const asFlow = false;
  const [emblaMainRef, emblaMainApi] = useEmblaCarousel(
    asFlow
      ? {
          slidesToScroll: 1,
          skipSnaps: false,
        }
      : {}
  );
  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    dragFree: false,
  });

  const handleThumbnailClick = useCallback(
    (idx: number) => {
      if (!emblaMainApi || !emblaThumbsApi) return null;
      emblaMainApi.scrollTo(idx);
    },
    [emblaMainApi, emblaThumbsApi]
  );

  if (mediaGroup.media.length === 0) {
    if (mediaGroup.embed?.kind === 'youtube') {
      return <YoutubeEmbed mediaGroup={mediaGroup} />;
    }

    if (
      ['bookmark'].includes(mediaGroup.contentType) ||
      mediaGroup.isGeneratingContent
    ) {
      return <BookmarkPlaceholder mediaGroup={mediaGroup} />;
    }

    return null;
  }

  const greatestHeight = mediaGroup.media.reduce(
    (acc, current) => Math.max(acc, current.height),
    CAROUSEL_MIN_HEIGHT
  );

  return (
    <div className={styles.container}>
      <div
        className={clsx(
          styles.slides,
          asFlow && styles.flow,
          asQuickView && styles.slim
        )}
        ref={mediaGroup.media.length > 1 ? emblaMainRef : undefined}
        style={css({ '--greatestHeight': `${greatestHeight}px` })}
      >
        <div className={styles.slidesContainer}>
          {mediaGroup.media.map((media) => {
            const { assetUrl, mediaType, height, id, posterUrl, source, width } = media;
            const orientation = getOrientation(width, height);
            const isImage = mediaType.startsWith('image/');
            const isVideo = mediaType.startsWith('video/');
            const isSvg = mediaType === 'image/svg+xml';
            const isFile = !isImage && !isVideo;

            return (
              <div
                className={clsx(
                  styles.slide,
                  asFlow ? styles.flow : styles.standard,
                  orientation === 'landscape' ? styles.landscape : styles.portrait
                )}
                key={id}
              >
                {isImage && (
                  <div className={styles.imageWrapper}>
                    <ScalableSVG
                      className={styles.svgImageWrapper}
                      height={height}
                      isSvg={isSvg}
                      src={assetUrl}
                      width={width}
                    />
                  </div>
                )}
                {isVideo && (
                  <div className={styles.videoWrapper}>
                    <BunnyStreamEmbed
                      height={height}
                      preview={posterUrl}
                      responsive={true}
                      source={source}
                      width={width}
                    />
                  </div>
                )}
                {isFile && (
                  <div className={styles.fileWrapper}>
                    <FileIcon mediaType={mediaType} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      {asFlow && mediaGroup.media.length > 1 && (
        <div className={styles.thumbnails} ref={emblaThumbsRef}>
          <div className={styles.slidesContainer}>
            {mediaGroup.media.map((media, idx) => (
              <div className={styles.slide} key={media.id}>
                <button
                  onClick={() => handleThumbnailClick(idx)}
                  className={clsx(styles.button, styles.video)}
                  type="button"
                >
                  {media.mediaType.startsWith('video/') && (
                    <>
                      {media.posterUrl ? (
                        <img src={media.posterUrl} alt="" height={64} />
                      ) : (
                        <Placeholder
                          className={styles.posterPlaceholder}
                          width={media.width}
                          height={media.height}
                        />
                      )}
                      <Play className={styles.videoIcon} />
                    </>
                  )}
                  {media.mediaType.startsWith('image/') && (
                    <img src={media.assetUrl} height={64} alt="" />
                  )}
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

const BookmarkPlaceholder = ({
  mediaGroup,
}: {
  mediaGroup: MediaGroupDetailDTO;
}) => {
  const { isGeneratingContent, linkUrl, linkUrlSource } = mediaGroup;

  return (
    <div className={clsx(styles.container, styles.bookmarkPlaceholder)}>
      {isGeneratingContent ? <Spinner /> : <BookmarkIcon />}
      <h3>{linkUrlSource}</h3>
      {isGeneratingContent && (
        <p>
          Generating website info. This might take a moment, so please check back later.
        </p>
      )}
      {linkUrl && (
        <Button
          iconAfter={<Open />}
          onClick={() => window.open(linkUrl)}
          size="md"
          variant="outlined"
        >
          Open link
        </Button>
      )}
    </div>
  );
};
