import clsx from 'clsx';
import type { EmblaCarouselType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { useCallback, useEffect, useState } from 'react';

import { usePatchUserSelf } from '@api/users';
import glow from '@assets/img/onboarding/glow.png';
import logo from '@assets/img/onboarding/logo.svg';
import slide01 from '@assets/img/onboarding/slide-01.png';
import slide02 from '@assets/img/onboarding/slide-02.png';
import slide03 from '@assets/img/onboarding/slide-03.png';
import slide04 from '@assets/img/onboarding/slide-04.png';
import slide05a from '@assets/img/onboarding/slide-05a.png';
import slide05b from '@assets/img/onboarding/slide-05b.png';
import stars from '@assets/img/onboarding/stars.svg';
import { useRefreshSession } from '@hooks/useAuth';
import Button from '@ui/Button';
import styles from './Onboarding.module.scss';

export default function Onboarding() {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    watchDrag: () => false,
  });

  const { mutateAsync: patchUserSelf } = usePatchUserSelf();
  const refresh = useRefreshSession();

  const next = () => {
    if (emblaApi?.canScrollNext) emblaApi?.scrollNext();
  };
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onSelect = useCallback(() => {
    if (!emblaApi) return null;
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    emblaApi.on('select', onSelect);
    emblaApi.on('reInit', onSelect);
  }, [emblaApi, onSelect]);

  const slides = [
    <Step1 key={'1'} onClick={next} />,
    <Step2 key={'2'} onClick={next} />,
    <Step3 key={'3'} onClick={next} />,
    <Step4 key={'4'} onClick={next} />,
    <Step5
      key={'5'}
      onClick={async () => {
        try {
          await patchUserSelf({ isNew: false });
        } catch (ex) {
          console.log(ex);
        }
        await refresh();
      }}
    />,
  ];

  return (
    <div className={styles.container}>
      <div className={styles.background}>
        <div className={styles.glow}>
          <img alt="" height={520} src={glow} width={1920} />
        </div>
        <div className={styles.stars}>
          <img alt="" height={633} src={stars} width={1064} />
        </div>
      </div>
      <Header
        embla={emblaApi}
        selectedIndex={selectedIndex}
        slidesCount={slides.length}
      />
      <div className={styles.carousel} ref={emblaRef}>
        <div className={styles.carouselContainer}>
          {slides.map((slide, idx) => {
            return <Slide key={idx}>{slide}</Slide>;
          })}
        </div>
      </div>
    </div>
  );
}

type DotsProps = {
  embla?: EmblaCarouselType;
  selectedIndex: number;
  slidesCount: number;
};

const Dots = ({ embla, selectedIndex, slidesCount }: DotsProps) => {
  const onDotButtonClick = useCallback(
    (idx: number) => {
      if (!embla) return;
      embla.scrollTo(idx);
    },
    [embla]
  );

  if (!embla) return null;

  return (
    <div className={styles.dots}>
      {[...Array(slidesCount).keys()].map((idx) => {
        return (
          <button
            className={clsx(selectedIndex === idx && styles.active)}
            key={idx}
            onClick={() => onDotButtonClick(idx)}
          />
        );
      })}
    </div>
  );
};

type HeaderProps = {
  embla?: EmblaCarouselType;
  selectedIndex: number;
  slidesCount: number;
};

const Header = ({ embla, selectedIndex, slidesCount = 0 }: HeaderProps) => {
  return (
    <div className={styles.header}>
      <div>
        <img alt="" height={32} src={logo} width={147} />
      </div>
      <Dots embla={embla} selectedIndex={selectedIndex} slidesCount={slidesCount} />
    </div>
  );
};

const NextButton = ({ onClick }: { onClick?: () => void }) => {
  return (
    <Button className={styles.nextButton} onClick={onClick}>
      Continue
    </Button>
  );
};

type SlideProps = {
  children: React.ReactNode;
  header?: React.ReactNode;
};

const Slide = ({ children, header }: SlideProps) => {
  return (
    <div className={styles.slide}>
      {header}
      {children}
    </div>
  );
};

type StepProps = {
  onClick?: () => void;
};

const Step1 = ({ onClick }: StepProps) => {
  return (
    <div className={styles.sizeContainer}>
      <div className={styles.image}>
        <img alt="" height={500} src={slide01} width={800} />
      </div>
      <div className={styles.text}>
        <h2>Save literally anything that matters</h2>
        <p>
          From notes to images, videos, files, bookmarks, links, and beyond
          <br />— all connected together in one intelligent library.
        </p>
      </div>
      <NextButton onClick={onClick} />
    </div>
  );
};

const Step2 = ({ onClick }: StepProps) => {
  return (
    <div className={styles.sizeContainer}>
      <div className={styles.image}>
        <img alt="" height={500} src={slide02} width={800} />
      </div>
      <div className={styles.text}>
        <h2>Capture it and let AI do the rest.</h2>
        <p>
          AI automatically labels, tags, and links in a flash. Plus it clusters your
          content together
          <br />— no complex folders, no time wasted, no effort needed.
        </p>
      </div>
      <NextButton onClick={onClick} />
    </div>
  );
};

const Step3 = ({ onClick }: StepProps) => {
  return (
    <div className={styles.sizeContainer}>
      <div className={styles.image}>
        <img alt="" height={500} src={slide03} width={800} />
      </div>
      <div className={clsx(styles.text, styles.mid)}>
        <h2>Find what you need, every time</h2>
        <p>
          Find anything using natural language search or powerful tags, including
          labels, colors, categories, source and more, for unparalleled insights and
          inspiration.
        </p>
      </div>
      <NextButton onClick={onClick} />
    </div>
  );
};

const Step4 = ({ onClick }: StepProps) => {
  return (
    <div className={styles.sizeContainer}>
      <div className={styles.image}>
        <img alt="" height={500} src={slide04} width={800} />
      </div>
      <div className={clsx(styles.text, styles.mid)}>
        <h2>Create freely with notes</h2>
        <p>
          Simplify your complex thoughts and research with clear, easy-to-understand
          documents using our notes tool. Enjoy features like bi-directional linking and
          content clustering.
        </p>
      </div>
      <NextButton onClick={onClick} />
    </div>
  );
};

const Step5 = ({ onClick }: StepProps) => {
  return (
    <div className={styles.sizeContainer}>
      <div className={styles.imageRow}>
        <div className={styles.image}>
          <img alt="" height={500} src={slide05a} width={500} />
        </div>
        <div className={styles.image}>
          <img alt="" height={500} src={slide05b} width={500} />
        </div>
      </div>
      <div className={clsx(styles.text, styles.narrow)}>
        <h2>Ready to Work Together?</h2>
        <p>
          Move your private content to spaces whenever you're ready to share with your
          team and start collaborating.
        </p>
      </div>
      <NextButton onClick={onClick} />
    </div>
  );
};
