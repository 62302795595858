import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 8C21.2 8 23 6.2 23 4C23 1.8 21.2 0 19 0C16.8 0 15 1.8 15 4C15 4.4 15.1 4.7 15.2 5L7.9 9.2C7.1 8.5 6.1 8 5 8C2.8 8 1 9.8 1 12C1 14.2 2.8 16 5 16C6.1 16 7.1 15.5 7.9 14.8L15.2 19C15.1 19.3 15 19.7 15 20C15 22.2 16.8 24 19 24C21.2 24 23 22.2 23 20C23 17.8 21.2 16 19 16C17.9 16 16.9 16.5 16.1 17.2L8.8 13C8.9 12.7 9 12.4 9 12C9 11.6 8.9 11.3 8.8 11L16.1 6.8C16.9 7.5 17.9 8 19 8Z"
        fill={color}
      />
    </svg>
  );
}
