import type { MediaGroupDTO } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';
import { exists } from '@spaceduck/utils';
import Tippy from '@tippyjs/react';
import { type Editor, Node } from '@tiptap/core';
import {
  type NodeViewRendererProps,
  NodeViewWrapper,
  ReactNodeViewRenderer,
  mergeAttributes,
} from '@tiptap/react';

import { urlFor } from '@/urls';
import { useMediaGroupSummary } from '@api/mediaGroup';
import { ExtractBlock } from '@components/ExtractBlock';
import FileBlock from '@components/FileBlock';
import NoAccessCard from '@components/NoAccessCard';
import OverviewListCard from '@components/OverviewListCard';
import { useSidebarModal } from '@components/detailsModal/sidebar/SidebarModal';
import { useIsInWebViewRoute } from '@hooks/useIsWebView';
import { css } from '@lib/css';
import BunnyStreamEmbed from '@ui/BunnyStreamEmbed';
import Menu from '@ui/Menu';
import Spinner from '@ui/Spinner';
import Tooltip from '@ui/Tooltip';
import { copyTextToClipboard } from '@utils/copyToClipboard';
import { downloadUrl } from '@utils/download';
import styles from './ContentBlock.module.scss';

const { Copy, Download, Link, LinkCard, LinkEmbed, MultipleImages, Open, TrashDelete } =
  Icon16;
const { AlertInfo } = Icon24;

export default Node.create({
  name: 'content-block',
  priority: 1000,
  group: 'block',
  atom: true,

  addAttributes() {
    return {
      'data-ref': {
        default: null,
      },
      id: {
        default: null,
      },
      'data-minimal-view': {
        default: false,
      },
      loading: {
        default: false,
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },

  parseHTML() {
    return [
      {
        tag: 'content-block',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['content-block', mergeAttributes(HTMLAttributes)];
  },
});

const Component = (props: NodeViewRendererProps & { deleteNode?: () => void }) => {
  const attrsLoading = props.node.attrs.loading || false;
  const { data, error, isLoading } = useMediaGroupSummary(props.node?.attrs.id ?? null);

  const toggleView: ToggleViewHandler = (displayAsMinimalView: boolean) => {
    const editor: Editor = props.editor;
    if (typeof props.getPos === 'function') {
      editor
        .chain()
        .setNodeSelection(props.getPos())
        .updateAttributes('content-block', {
          'data-minimal-view': displayAsMinimalView,
        })
        .run();
    }
  };

  const switchToOutgoingLink = () => {
    const editor: Editor = props.editor;
    if (typeof props.getPos === 'function') {
      editor
        .chain()
        .setNodeSelection(props.getPos())
        .deleteCurrentNode()
        .insertContent(`<outgoing-link id="${props.node.attrs.id}" />`)
        .run();
    }
  };

  if (isLoading || attrsLoading) {
    return (
      <NodeViewWrapper>
        <div>
          <Spinner />
        </div>
      </NodeViewWrapper>
    );
  }

  if (error || !data?.mediaGroup) {
    console.error('Could not load media group', error);

    return (
      <NodeViewWrapper>
        <div className={styles.block}>
          <div className={styles.cardWrapper}>
            <NoAccessCard />
          </div>
        </div>
      </NodeViewWrapper>
    );
  }

  return (
    <NodeViewWrapper>
      <ContentBlock
        editor={props.editor}
        isEditable={props.editor.isEditable}
        mediaGroup={data.mediaGroup}
        onDelete={() => props.deleteNode?.()}
        toggleView={toggleView}
        showEmbeddedView={props.node.attrs['data-minimal-view'] === false}
        switchToOutgoingLink={switchToOutgoingLink}
      />
    </NodeViewWrapper>
  );
};

const ImageMedia = ({ mediaGroup }: { mediaGroup: MediaGroupDTO }) => {
  const firstItem = mediaGroup.media[0];
  const hasMultipleImages = mediaGroup.media.length > 1;
  if (!firstItem?.assetUrl || !firstItem.mediaType.startsWith('image/')) return null;

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.image}>
        {hasMultipleImages && (
          <Tooltip content="Multiple images">
            <span className={styles.imageIcon}>
              <MultipleImages />
            </span>
          </Tooltip>
        )}
        <img src={firstItem.assetUrl} alt="" />
      </div>
    </div>
  );
};

const VideoMedia = ({ mediaGroup }: { mediaGroup: MediaGroupDTO }) => {
  const firstItem = mediaGroup.media[0];
  if (!firstItem?.mediaType.startsWith('video/')) return null;

  const { height, posterUrl, source, width } = firstItem;

  return (
    <div className={styles.cardWrapper}>
      <div
        className={styles.video}
        style={css({ paddingTop: `${(height / width) * 100}%` })}
      >
        <BunnyStreamEmbed
          containerClassName={styles.embed}
          preview={posterUrl}
          responsive={true}
          source={source}
        />
      </div>
    </div>
  );
};

export const YoutubeEmbed = ({ mediaGroup }: { mediaGroup: MediaGroupDTO }) => {
  if (!mediaGroup.embed?.videoId) return null;

  const baseVideoWidth = 560;
  const baseVideoHeight = 340;

  return (
    <div className={styles.cardWrapper}>
      <div
        className={styles.video}
        style={css({
          paddingTop: `${(baseVideoHeight / baseVideoWidth) * 100}%`,
        })}
      >
        <iframe
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className={styles.embed}
          referrerPolicy="strict-origin-when-cross-origin"
          src={`https://www.youtube.com/embed/${encodeURIComponent(mediaGroup.embed.videoId)}`}
          title={`${mediaGroup.label}`}
        />
      </div>
    </div>
  );
};

const GenericMedia = ({
  mediaGroup,
  showMinimalView,
}: {
  mediaGroup: MediaGroupDTO;
  showMinimalView: boolean;
}) => {
  return (
    <div className={styles.cardWrapper}>
      <OverviewListCard
        disableLinks
        projectName={mediaGroup.project?.label ?? 'Repository'}
        mediaGroup={mediaGroup}
        showMinimalView={showMinimalView}
      />
    </div>
  );
};

const Content = ({
  mediaGroup,
  showEmbeddedView = true,
}: {
  mediaGroup: MediaGroupDTO;
  showEmbeddedView?: boolean;
}) => {
  const { kind, contentType, media } = mediaGroup;

  if (kind === 'extract') {
    return <ExtractBlock mediaGroup={mediaGroup} palette="surface3" />;
  }

  const isBookmark =
    contentType === 'bookmark' || (contentType === 'article' && kind === 'bookmark');
  const isImage = contentType === 'image' || (kind === 'bookmark' && media);
  const isVideo = contentType === 'video';
  const isYoutubeVideo =
    (isVideo || isBookmark) &&
    mediaGroup.embed?.kind === 'youtube' &&
    !!mediaGroup.embed?.videoId;
  const isDocument =
    ['file', 'pdf'].includes(contentType ?? '') || (isBookmark && !isYoutubeVideo);

  if (isYoutubeVideo) {
    if (showEmbeddedView) {
      return <YoutubeEmbed mediaGroup={mediaGroup} />;
    }
    if (isBookmark) {
      return <FileBlock mediaGroup={mediaGroup} />;
    }

    return <GenericMedia mediaGroup={mediaGroup} showMinimalView />;
  }

  if (isVideo) {
    return <VideoMedia mediaGroup={mediaGroup} />;
  }

  if (isImage && showEmbeddedView) {
    return <ImageMedia mediaGroup={mediaGroup} />;
  }

  if (!showEmbeddedView || isDocument) {
    return <FileBlock mediaGroup={mediaGroup} />;
  }

  return (
    <GenericMedia
      mediaGroup={mediaGroup}
      showMinimalView={isBookmark || showEmbeddedView}
    />
  );
};

type ToggleViewHandler = (displayAsMinimalView: boolean) => void;

const ContentBlock = ({
  editor,
  isEditable,
  mediaGroup,
  onDelete,
  toggleView,
  showEmbeddedView,
  switchToOutgoingLink,
}: {
  editor: Editor;
  isEditable: boolean;
  mediaGroup: MediaGroupDTO;
  onDelete?: () => void;
  toggleView: ToggleViewHandler;
  showEmbeddedView: boolean;
  switchToOutgoingLink: () => void;
}) => {
  return (
    <ContentBlockCard
      editor={editor}
      isEditable={isEditable}
      mediaGroup={mediaGroup}
      onDelete={onDelete}
      showEmbeddedView={showEmbeddedView}
      switchToOutgoingLink={switchToOutgoingLink}
      toggleView={toggleView}
    >
      <Content mediaGroup={mediaGroup} showEmbeddedView={showEmbeddedView} />
    </ContentBlockCard>
  );
};

const ContentBlockCard = ({
  children,
  editor,
  isEditable,
  mediaGroup,
  onDelete,
  showEmbeddedView = false,
  switchToOutgoingLink,
  toggleView,
}: {
  children: React.ReactNode;
  editor: Editor;
  isEditable: boolean;
  mediaGroup: MediaGroupDTO;
  onDelete?: () => void;
  showEmbeddedView: boolean;
  switchToOutgoingLink: () => void;
  toggleView?: ToggleViewHandler;
}) => {
  const isInWebViewRoute = useIsInWebViewRoute();
  const { open: openSidebarModal } = useSidebarModal();
  const isYoutubeVideo =
    mediaGroup.embed?.kind === 'youtube' && !!mediaGroup.embed?.videoId;
  const showView = !['document'].includes(mediaGroup.contentType ?? '');
  const assetUrl = mediaGroup.media[0]?.assetUrl;

  const hasEmbeddedView =
    ['image', 'video'].includes(mediaGroup.contentType ?? '') ||
    (mediaGroup.contentType === 'bookmark' &&
      (mediaGroup.media.length > 0 || isYoutubeVideo));

  if (isInWebViewRoute) {
    return <div className={styles.block}>{children}</div>;
  }

  return (
    <Tippy
      allowHTML
      duration={[0, 500]}
      interactive={true}
      content={
        <div className={styles.menuBar}>
          <Menu
            menuItems={[
              {
                icon: <Open />,
                onClick: () =>
                  window.open(urlFor('mediaGroup', { mediaGroupId: mediaGroup.id })),
                tooltip: 'View',
              },
              {
                isSeparator: true,
              },
              {
                icon: <AlertInfo size={16} />,
                tooltip: 'Info',
                onClick: () => {
                  editor.chain().blur().run();
                  queueMicrotask(() => {
                    openSidebarModal({ mediaGroupId: mediaGroup.id });
                  });
                },
              },
              assetUrl
                ? {
                    icon: <Download />,
                    onClick: () => downloadUrl(assetUrl),
                    tooltip: 'Download',
                  }
                : null,
              {
                icon: <Copy />,
                tooltip: 'Copy URL',
                onClick: () => {
                  copyTextToClipboard(
                    new URL(
                      urlFor('mediaGroup', { mediaGroupId: mediaGroup.id }),
                      window.location.origin
                    ).toString(),
                    {
                      bodyText: 'Share link copied to clipboard!',
                    }
                  );
                },
              },
              isEditable
                ? {
                    icon: <TrashDelete />,
                    onClick: () => onDelete?.(),
                    tooltip: 'Delete',
                  }
                : null,
              showView
                ? {
                    isSeparator: true,
                  }
                : null,
              showView
                ? {
                    title: 'View',
                    tooltip: 'View',
                    icon: <Link />,
                    menuItems: [
                      {
                        icon: <Link />,
                        label: 'Inline view',
                        onClick: switchToOutgoingLink,
                      },
                      {
                        disabled: !showEmbeddedView,
                        icon: <LinkCard />,
                        label: 'Card view',
                        onClick: () => toggleView?.(true),
                        showCheck: !showEmbeddedView,
                      },
                      hasEmbeddedView
                        ? {
                            disabled: showEmbeddedView,
                            icon: <LinkEmbed />,
                            label: 'Embed view',
                            onClick: () => toggleView?.(false),
                            showCheck: showEmbeddedView,
                          }
                        : null,
                    ].filter(exists),
                  }
                : null,
            ].filter(exists)}
          />
        </div>
      }
    >
      <div className={styles.block}>{children}</div>
    </Tippy>
  );
};
