import { Icon16 } from '@spaceduck/icons';

import { BROWSER_EXT_URL } from '@/const';
import { useFeedbackModal } from '@ui/FeedbackModal';
import MenuLink from './MenuLink';
import styles from './MenuMoreSection.module.scss';

const { BrowserExtension, Changelog, Feedback } = Icon16;

export default function MenuMoreSection() {
  const { open: openFeedbackModal } = useFeedbackModal();
  return (
    <nav className={styles.more}>
      <div className="menuHeading">More</div>
      <MenuLink
        asButton
        icon={<BrowserExtension />}
        onClick={() => window.open(BROWSER_EXT_URL)}
      >
        Browser Extension
      </MenuLink>
      <MenuLink icon={<Feedback />} asButton onClick={openFeedbackModal}>
        Give Feedback
      </MenuLink>
      <MenuLink
        icon={<Changelog />}
        asButton
        onClick={() => window.open('https://www.spaceduck.com/blog')}
      >
        Changelog
      </MenuLink>
    </nav>
  );
}
