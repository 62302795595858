import { z } from 'zod';
import { ApiClient } from '../client';
import { successfulResponseSchema } from '../schema';
import { apiUrl } from '../util';

export const linkResolveMetaResponseSchema = successfulResponseSchema.extend({
  id: z.string(),
});

export const linkResolveMetaSchema = z.object({
  image: z.string().nullable(),
  title: z.string().nullable(),
  description: z.string().nullable(),
  icon: z.string().nullable(),
});
export const linkResolveMetaStatusResponseSchema = successfulResponseSchema.extend({
  data: linkResolveMetaSchema.nullable(),
});
export type LinkResolveMeta = z.infer<typeof linkResolveMetaSchema>;

export const createLinkResolveMetaRequest = (url: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/notes/link-resolve-meta/`,
    method: 'POST',
    responseSchema: linkResolveMetaResponseSchema,
    body: {
      url,
    },
  });

export const linkResolveMetaStatusRequest = (id: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/notes/link-resolve-meta/${id}/status/`,
    method: 'GET',
    responseSchema: linkResolveMetaStatusResponseSchema,
  });
