import { useParams } from 'react-router-dom';

import { knownErrors } from '@/const';
import type { DetailsModelTab } from '@/types/MediaGroup';
import DetailsModal from '@components/detailsModal/DetailsModal';

export default function MediaGroupPage({
  activeTab,
}: {
  activeTab?: DetailsModelTab;
}) {
  const { mediaGroupId, view } = useParams();

  if (!mediaGroupId) {
    throw new Error(knownErrors.mediaGroupError, {
      cause: 'Missing Media Group Id',
    });
  }
  return (
    <MediaGroup id={mediaGroupId} view={view ?? null} activeTab={activeTab ?? 'info'} />
  );
}

const MediaGroup = ({
  id,
  view,
  activeTab,
}: {
  id: string;
  view: string | null;
  activeTab: DetailsModelTab;
}) => {
  return <DetailsModal mediaGroupId={id} view={view} activeTab={activeTab} />;
};
