import type { MediaGroupDetailDTO } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';
import clsx from 'clsx';
import { useHotkeys } from 'react-hotkeys-hook';

import type { DetailsModelTab } from '@/types';
import { urlFor } from '@/urls';
import { isCapable } from '@api/util';
import { useBackgroundLocation } from '@hooks/useBackgroundLocation';
import { useNavigateWithState } from '@hooks/useNavigateWithState';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import { useNotesStore } from '@stores/useNotesStore';
import Button, { ButtonLink } from '@ui/Button';
import Spinner from '@ui/Spinner';
import Tooltip from '@ui/Tooltip';
import { copyUrlToClipboard } from '@utils/copyToClipboard';
import { getCloseTo } from '@utils/mediaGroup';
import { getOS } from '@utils/os';
import TabButton, { TabButtonWrapper } from '../ui/TabButton';
import DetailsModalActionsDropdown from './DetailsModalActionsDropdown';
import styles from './DetailsModalHeader.module.scss';

const { Close, Comment, DrawerRight } = Icon24;
const { Image, IFrame, ReadingMode, Summary, ShowEmbed } = Icon16;

type DetailsModalHeaderProps = {
  mediaGroup: MediaGroupDetailDTO;
  isNote: boolean;
  activeTab: DetailsModelTab;
  view: string | null;
};

export default function DetailsModalHeader({
  mediaGroup,
  isNote,
  activeTab,
  view,
}: DetailsModalHeaderProps) {
  const {
    contentView,
    drawerIsOpen,
    isCommenting,
    openDrawer,
    setIsCommenting,
    setQuickViewMediaGroupId,
    toggleDrawer,
    userCapabilities,
  } = useDetailsModalStore((state) => ({
    contentView: state.contentView,
    drawerIsOpen: state.drawerIsOpen,
    isCommenting: state.isCommenting,
    openDrawer: state.openDrawer,
    setIsCommenting: state.setIsCommenting,
    setQuickViewMediaGroupId: state.setQuickViewMediaGroupId,
    toggleDrawer: state.toggleDrawer,
    userCapabilities: state.userCapabilities,
  }));
  const backgroundLocation = useBackgroundLocation();
  const { isNotesSaving, isQuickViewNotesSaving } = useNotesStore();

  const navigateWithState = useNavigateWithState();

  const setActiveTab = (activeTab: DetailsModelTab) => {
    const url = urlFor(activeTab === 'comment' ? 'mediaGroupComments' : 'mediaGroup', {
      mediaGroupId: mediaGroup.id,
    });
    navigateWithState(url);
  };

  useHotkeys(
    'meta+alt+s',
    () => {
      copyUrlToClipboard();
    },
    {
      enabled: getOS().isMac,
      preventDefault: true,
    }
  );

  useHotkeys(
    'ctrl+alt+s',
    () => {
      copyUrlToClipboard();
    },
    {
      enabled: !getOS().isMac,
      preventDefault: true,
    }
  );

  useHotkeys(
    'meta+.',
    () => {
      toggleDrawer();
    },
    {
      enabled: getOS().isMac,
      preventDefault: true,
    }
  );

  useHotkeys(
    'ctrl+.',
    () => {
      toggleDrawer();
    },
    {
      enabled: !getOS().isMac,
      preventDefault: true,
    }
  );

  const toggleCommenting = () => {
    if (isCommenting) {
      setIsCommenting(false);
    } else {
      setIsCommenting(true);
      setActiveTab('comment');
      openDrawer();
    }
  };

  useHotkeys(
    'c',
    () => {
      toggleCommenting();
      openDrawer();
    },
    {
      enabled: isCapable('comment', userCapabilities).capable,
    },
    [isCommenting, userCapabilities]
  );

  useHotkeys(
    'Escape',
    () => {
      setIsCommenting(false);
    },
    {
      enabled: isCommenting,
    },
    [isCommenting]
  );

  const showCommentButton =
    isCapable('comment', userCapabilities).capable &&
    ['content'].includes(contentView ?? '') &&
    mediaGroup.media.length !== 0 &&
    !mediaGroup.isGeneratingContent;

  return (
    <div className={styles.header}>
      <nav className={styles.navigation}>
        <Tooltip
          align="start"
          content="Close"
          shortkeys={['Esc']}
          size="medium"
          variant="secondary"
        >
          {isNotesSaving || isQuickViewNotesSaving ? (
            <Button disabled className={styles.button} variant="icon">
              <Close size={20} />
            </Button>
          ) : (
            <ButtonLink
              className={styles.button}
              variant="icon"
              onClick={() => setQuickViewMediaGroupId(null)}
              to={backgroundLocation || getCloseTo(mediaGroup)}
            >
              <Close size={20} />
            </ButtonLink>
          )}
        </Tooltip>
      </nav>
      {isNote && (
        <div className={styles.notesUtils}>
          <div className={styles.savingIndicator}>
            {(isNotesSaving || isQuickViewNotesSaving) && (
              <>
                <Spinner size={16} />
                <span className={styles.savingLabel}>Saving...</span>
              </>
            )}
          </div>
        </div>
      )}
      <ViewSwitcher mediaGroup={mediaGroup} view={view} activeTab={activeTab} />
      <div className={styles.utils}>
        <DetailsModalActionsDropdown mediaGroupId={mediaGroup.id} />
        {showCommentButton && (
          <Tooltip content="Comment" icons={['c']} size="medium" variant="secondary">
            <Button
              className={clsx(styles.button, isCommenting && styles.active)}
              onClick={() => toggleCommenting()}
              variant="icon"
            >
              <Comment size={20} />
            </Button>
          </Tooltip>
        )}
        <Tooltip
          align="end"
          content={`${drawerIsOpen ? 'Hide' : 'Show'} Sidebar`}
          shortkeys={['⌘', '.']}
          size="medium"
          variant="secondary"
        >
          <Button
            className={styles.button}
            variant="icon"
            onClick={() => toggleDrawer()}
          >
            <DrawerRight size={20} />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}

type ViewSwitcherProps = {
  mediaGroup: MediaGroupDetailDTO;
  activeTab: DetailsModelTab;
  view: string | null;
};

const ViewSwitcher = (props: ViewSwitcherProps) => {
  const { mediaGroup } = props;
  if (mediaGroup.kind === 'bookmark') {
    return <BookmarkViewSwitcher {...props} />;
  }
  return null;
};

const BookmarkViewSwitcher = ({ mediaGroup, activeTab, view }: ViewSwitcherProps) => {
  const navigateWithState = useNavigateWithState();

  const { kind, contentType } = mediaGroup;

  if (kind !== 'bookmark' || contentType === 'video') {
    return null;
  }

  return (
    <div className={styles.noteToggle}>
      <TabButtonWrapper variant="dark" className={styles.tabButtonWrapper}>
        <TabButton
          isActive={view === 'media' || view === null}
          iconBefore={<Image />}
          onClick={() =>
            navigateWithState(
              urlFor('mediaGroupModal', {
                mediaGroupId: mediaGroup.id,
                // TODO(@dbowring): should this be explicit?
                view: null,
                activeTab,
              })
            )
          }
          size="sm"
          variant="dark"
        >
          Screenshot
        </TabButton>
        {contentType === 'article' && (
          <TabButton
            isActive={view === 'reading'}
            iconBefore={<ReadingMode />}
            onClick={() =>
              navigateWithState(
                urlFor('mediaGroupModal', {
                  mediaGroupId: mediaGroup.id,
                  view: 'reading',
                  activeTab,
                })
              )
            }
            size="sm"
            variant="dark"
          >
            Reading mode
          </TabButton>
        )}
        {contentType === 'wiki' && (
          <TabButton
            isActive={view === 'iframe'}
            iconBefore={<IFrame />}
            onClick={() =>
              navigateWithState(
                urlFor('mediaGroupModal', {
                  mediaGroupId: mediaGroup.id,
                  view: 'iframe',
                  activeTab,
                })
              )
            }
            size="sm"
            variant="dark"
          >
            iFrame
          </TabButton>
        )}
        {contentType === 'social' && (
          <TabButton
            isActive={view === 'embed'}
            iconBefore={<ShowEmbed />}
            onClick={() =>
              navigateWithState(
                urlFor('mediaGroupModal', {
                  mediaGroupId: mediaGroup.id,
                  view: 'embed',
                  activeTab,
                })
              )
            }
            size="sm"
            variant="dark"
          >
            Embed
          </TabButton>
        )}
        <TabButton
          isActive={view === 'summary'}
          iconBefore={<Summary />}
          onClick={() =>
            navigateWithState(
              urlFor('mediaGroupModal', {
                mediaGroupId: mediaGroup.id,
                view: 'summary',
                activeTab,
              })
            )
          }
          size="sm"
          variant="dark"
        >
          Summary
        </TabButton>
      </TabButtonWrapper>
    </div>
  );
};
