import ScalableSVG from '@ui/ScalableSVG';

type ImageProps = {
  alt?: string;
  className?: string;
  height: number;
  isSvg: boolean;
  onClick?: () => void;
  src: string;
  width: number;
};

export default function Image({
  alt = '',
  className,
  height,
  isSvg,
  onClick,
  src,
  width,
}: ImageProps) {
  return (
    <ScalableSVG
      className={className}
      height={height}
      isSvg={isSvg}
      src={src}
      width={width}
    >
      <img alt={alt} height={height} width={width} onClick={onClick} src={src} />
    </ScalableSVG>
  );
}
