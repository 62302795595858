import { urlFor } from '@/urls';
import useWorkspaceId from '@hooks/useWorkspaceId';
import MenuLink from '@layouts/sidebar/MenuLink';

// TODO: Disabled pending feature decision @ari
const ENABLE_NOTIFICATIONS_MENU_LINK = false;

export default function MenuWorkspaceSettingsSection() {
  const workspaceId = useWorkspaceId();

  if (!workspaceId) {
    return null;
  }

  return (
    <>
      <MenuLink url={urlFor('workspaceSettingsGeneral', { workspaceId })}>
        General
      </MenuLink>
      <MenuLink url={urlFor('workspaceSettingsPeople', { workspaceId })}>
        People
      </MenuLink>
      {ENABLE_NOTIFICATIONS_MENU_LINK && (
        <MenuLink url={urlFor('workspaceSettingsNotifications', { workspaceId })}>
          Notifications
        </MenuLink>
      )}
      <MenuLink url={urlFor('workspaceSettingsTags', { workspaceId })}>Tags</MenuLink>
      <MenuLink url={urlFor('workspaceSettingsSpaces', { workspaceId })}>
        Spaces
      </MenuLink>
      <MenuLink url={urlFor('workspaceSettingsMediaGroupCategories', { workspaceId })}>
        Categories
      </MenuLink>
      <MenuLink url={urlFor('workspaceSettingsBillingAndPlan', { workspaceId })}>
        Billing & Plan
      </MenuLink>
      <MenuLink url={urlFor('workspaceSettingsIntegrations', { workspaceId })}>
        Integrations
      </MenuLink>
    </>
  );
}
