import type { Session } from './schema';
import type { AuthenticatedFetch } from './types';
import { fetchWithSession, getSession } from './utils';

export type SessionStoreOptions = {
  origin: string;
};
export type SessionUpdateHandler = (detail: {
  session: Session | null;
  keyHash?: string | null;
}) => unknown;

const safeCall = async (fn: () => PromiseLike<unknown> | unknown) => {
  try {
    await fn();
  } catch (error) {
    console.error('Error in handler', error);
  }
};

export const createSessionStore = ({ origin }: SessionStoreOptions) => {
  let state: {
    session: Session | null;
    request: Promise<Session | null> | null;
  } = {
    session: null,
    request: null,
  };
  const handlers: SessionUpdateHandler[] = [];

  const dispatchUpdate = (
    session: Session | null,
    keyHash: string | null | undefined
  ): void => {
    for (const handler of handlers) {
      safeCall(() => handler({ session, keyHash })).catch((error) => {
        console.error('Async error in handler', error);
      });
    }
  };

  const waitForSessionUpdate = async (): Promise<Session | null> => {
    if (state.request === null) {
      // eslint-disable-next-line promise/prefer-await-to-then
      const request = getSession(origin)
        .catch((error) => {
          console.error('Fatal error fetching session information', error);
          return null;
        })
        .then((session) => {
          state = {
            request: null,
            session,
          };
          // Custom target only gets the key, including the whole session may
          //  allow the token top be leaked to anything that can register a
          //  handler.
          const keyHash =
            session === null
              ? undefined
              : session.kind === 'authenticated'
                ? session.keyHash
                : null;
          dispatchUpdate(session, keyHash);
          return session;
        });
      state = { ...state, request };
      return request;
    }
    return state.request;
  };

  const waitForSession = async (): Promise<Session | null> => {
    if (state.session) {
      return state.session;
    }
    const session = await waitForSessionUpdate();
    return session;
  };

  const authenticatedFetch: AuthenticatedFetch = async (url, config) => {
    if (state.session?.kind === 'authenticated') {
      const response = await fetchWithSession(url, config ?? null, state.session);

      if (response.status === 401) {
        const newSession = await waitForSessionUpdate();

        if (newSession?.kind === 'authenticated') {
          const newResponse = await fetchWithSession(url, config ?? null, newSession);
          return newResponse;
        }

        // For consistency, return null for non-logged in users
        return Promise.resolve(null);
      }
      return response;
    }

    const session = await waitForSession();

    if (session?.kind === 'authenticated') {
      const response = fetchWithSession(url, config ?? null, session);
      return response;
    }

    return Promise.resolve(null);
  };

  const getCurrentSession = (): Session | null => state.session;

  const addEventListener = (
    _eventName: 'sessionUpdate',
    listener: SessionUpdateHandler
  ): void => {
    handlers.push(listener);
  };

  const removeEventListener = (
    _eventName: 'sessionUpdate',
    listener: SessionUpdateHandler
  ): void => {
    const index = handlers.indexOf(listener);
    if (index < 0) {
      return;
    }
    handlers.splice(index, 1);
  };

  const refresh = async (): Promise<void> => {
    await waitForSessionUpdate();
  };

  const ensureSession = async (): Promise<void> => {
    await waitForSession();
  };

  const destroySession = async (): Promise<void> => {
    await authenticatedFetch(new URL('/_/auth/logout/', origin), {
      method: 'POST',
    });
    await refresh();
  };

  /**
   * Potentially update session for newly detected sessionKey. This can be used
   * if you detect a session change from another running instance (E.g.,
   * another tab or from the browser extension) to synchronize the active
   * session.
   * @param keyHash newly detected session keyHash
   */
  const pushSessionKey = (keyHash: string | null) => {
    const session = getCurrentSession();
    const authenticated = session?.kind === 'authenticated';
    const existingKeyHash = authenticated ? session.keyHash : undefined;
    if (authenticated && keyHash === existingKeyHash) {
      // Session already matches
      return Promise.resolve();
    }
    if (!authenticated && keyHash === null) {
      // Already in a non-authenticated session
      return Promise.resolve();
    }
    return refresh();
  };

  return {
    authenticatedFetch,
    addEventListener,
    removeEventListener,
    refresh,
    ensureSession,
    getCurrentSession,
    destroySession,
    pushSessionKey,
  };
};

export type SessionStore = ReturnType<typeof createSessionStore>;
