import kebabCase from 'lodash/kebabCase';
import { z } from 'zod';
import { ApiClient } from '../client';
import { successfulResponseSchema } from '../schema';
import { apiUrl } from '../util';
import {
  type MediaGroupAiSummaryNotPossiblePayload,
  type MediaGroupAiSummarySuccessPayload,
  type MediaGroupContentType,
  type MediaGroupQuerySchema,
  type MediaGroupReadableSummaryNotAvailablePayload,
  type MediaGroupReadableSummaryPayload,
  type MediaGroupSearchFilterProperties,
  type MediaGroupSortOption,
  createCapabilitySchema,
  mediaGroupAiSummaryNotPossiblePayloadSchema,
  mediaGroupAiSummarySuccessPayloadSchema,
  mediaGroupCapability,
  mediaGroupDTO,
  mediaGroupDetailDTO,
  mediaGroupReadableSummaryNotAvailablePayloadSchema,
  mediaGroupReadableSummaryPayloadSchema,
  mediaGroupWithCategoryFields,
  searchDateSuggestionSchema,
  searchSuggestionsSchema,
} from './schemas';

const mediaGroupDetailSchema = successfulResponseSchema.extend({
  mediaGroup: mediaGroupDetailDTO,
  userCapabilities: createCapabilitySchema(mediaGroupCapability).array(),
});

export type MediaGroupDetailResponse = z.infer<typeof mediaGroupDetailSchema>;

export const getMediaGroupDetail = async (id: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/${id}/`,
    method: 'GET',
    responseSchema: mediaGroupDetailSchema,
  });

export const getMediaGroupSumary = async (id: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/${id}/summary/`,
    method: 'GET',
    responseSchema: successfulResponseSchema.extend({
      mediaGroup: mediaGroupDTO,
    }),
  });

export const listMediaGroups = async (page: number, filters: MediaGroupQuerySchema) => {
  const { maxDatetime, minDatetime, status, statusNot, ...params } = filters;
  return ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/`,
    method: 'GET',
    params: {
      page,
      maxDatetime: maxDatetime?.toISOString(),
      minDatetime: minDatetime?.toISOString(),
      status: status?.map((s) => s || ''),
      statusNot: statusNot?.map((s) => s || ''),
      ...params,
    },
    responseSchema: successfulResponseSchema.extend({
      mediaGroups: mediaGroupDTO.array(),
      page: z.number(),
      hasNext: z.boolean(),
      attributionToken: z.string().nullable(),
    }),
  });
};

export const listMediaGroupsByCategoryWithFields = async (
  categoryId: string,
  cursor?: string,
  sort?: MediaGroupSortOption,
  query?: string
) => {
  return ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group-category/${categoryId}/items/`,
    method: 'GET',
    params: {
      cursor,
      sort,
      query,
    },
    responseSchema: successfulResponseSchema.extend({
      mediaGroups: mediaGroupWithCategoryFields.array(),
      count: z.number(),
      total: z.number(),
      nextCursor: z.string().nullable(),
    }),
  });
};

export const getSimilarMediaGroups = async (
  id: string,
  contentTypes: MediaGroupContentType[]
) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/${id}/similar/`,
    method: 'GET',
    params: { contentTypes },
    responseSchema: successfulResponseSchema.extend({
      mediaGroups: mediaGroupDTO.array(),
    }),
  });

export const getSearchSuggestions = async (
  filter: Exclude<MediaGroupSearchFilterProperties, 'text'>,
  filters: MediaGroupQuerySchema
) => {
  const { maxDatetime, minDatetime, ...params } = filters;
  return ApiClient.call({
    endpoint: apiUrl`/w/explore/search/suggestion/${kebabCase(filter)}/`,
    method: 'GET',
    params: {
      maxDatetime: maxDatetime?.toISOString(),
      minDatetime: minDatetime?.toISOString(),
      ...params,
    },
    responseSchema: successfulResponseSchema.and(searchSuggestionsSchema),
  });
};
type CreateComment = {
  content: string;
  pointAt?: {
    x: number;
    y: number;
    mediaIndex: number;
  };
  parentId?: string;
  reactions?: string[];
};

export const getSearchDateSuggestions = async (filters: MediaGroupQuerySchema) => {
  const { maxDatetime, minDatetime, ...params } = filters;
  return ApiClient.call({
    endpoint: apiUrl`/w/explore/search/suggestion/date/`,
    method: 'GET',
    params: {
      maxDatetime: maxDatetime?.toISOString(),
      minDatetime: minDatetime?.toISOString(),
      ...params,
    },
    responseSchema: successfulResponseSchema.and(searchDateSuggestionSchema),
  });
};

export type CreateMediaGroupSchema = {
  label?: string;
  description?: string;
  tags?: string[];
  projectId?: string | null;
  workspaceId?: string | null;
  categoryId?: string;
  assets?: string[];
  statusId?: string | null;
  kind?: 'gallery' | 'document' | 'bookmark' | 'extract';
  url?: string | null;
  enableAutotag?: boolean;
  comments?: CreateComment[];
  text?: string;
};

export const createMediaGroup = async (body: CreateMediaGroupSchema) => {
  return ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/`,
    method: 'POST',
    body,
    responseSchema: successfulResponseSchema.extend({
      mediaGroupId: z.string(),
    }),
  });
};

export type MediaGroupPatch<T extends object = object> = {
  label?: string | null;
  description?: string | null;
  status?: string | null;
  tags?: string[] | null;
  projectId?: string | null;
  kind?: 'gallery' | null;
  linkUrl?: string | null;
  categoryId?: string | null;
  assets?: string[];
  document?: T;
  plainText?: string;
};

export const patchMediaGroup = async ({
  mediaGroupId,
  patch,
}: {
  mediaGroupId: string;
  patch: MediaGroupPatch;
}) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/${mediaGroupId}/`,
    method: 'PATCH',
    body: { patch },
    responseSchema: mediaGroupDetailSchema,
  });

export const deleteMediaGroup = async (id: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/${id}/`,
    method: 'DELETE',
    responseSchema: successfulResponseSchema.extend({
      projectId: z.string().nullable(),
      mediaGroupId: z.string(),
    }),
  });

export const restoreMediaGroup = async (id: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/restore/`,
    method: 'POST',
    body: {
      mediaGroupId: id,
    },
    responseSchema: successfulResponseSchema,
  });

export const bulkDeleteMediaGroups = async (mediaGroupIds: string[]) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/bulk-delete/`,
    method: 'POST',
    body: { mediaGroupIds },
    responseSchema: successfulResponseSchema.extend({
      deletedIds: z.string().array(),
      unableToDeleteIds: z.string().array(),
      unknownIds: z.string().array(),
    }),
  });

export const bulkTagMediaGroups = async (data: {
  mediaGroupIds: string[];
  tags: string[];
}) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/bulk-tag/`,
    method: 'POST',
    body: data,
    responseSchema: successfulResponseSchema.extend({
      taggedIds: z.string().array(),
      unableToTagIds: z.string().array(),
      unknownIds: z.string().array(),
    }),
  });

export const copyMediaGroups = async (body: {
  mediaGroupIds: string[];
  projectId?: string;
  mode: 'copy' | 'move';
}) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/bulk-copy/`,
    method: 'POST',
    body,
    responseSchema: successfulResponseSchema.extend({
      // TODO: Remove optional/default
      moved: mediaGroupDTO.array().optional().default([]),
      copied: z.record(z.string(), mediaGroupDTO).optional().default({}),
    }),
  });

export const mediaGroupPoll = async (mediaGroupIds: string[]) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/fetch-updated/`,
    method: 'POST',
    body: { mediaGroupIds },
    responseSchema: successfulResponseSchema.extend({
      updatedMap: z.record(mediaGroupDTO),
    }),
  });

export const downloadScreen = async (mediaId: string) => {
  const response = await ApiClient.unparsedCall({
    endpoint: apiUrl`/w/explore/media/${mediaId}/download/`,
    method: 'GET',
    accept: 'image/png',
  });
  if (!response.ok) {
    console.error('Error requesting PNG data', response);
    throw new Error('Error requesting PNG data');
  }
  return response.blob();
};

export const regenerateMediaGroupThumbnail = async (mediaGroupId: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/${mediaGroupId}/regenerate-thumb/`,
    method: 'POST',
    responseSchema: successfulResponseSchema,
  });

export const getMediaGroupReadableContent = async (
  mediaGroupId: string
): Promise<
  MediaGroupReadableSummaryNotAvailablePayload | MediaGroupReadableSummaryPayload
> =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/${mediaGroupId}/readable-summary/`,
    method: 'GET',
    responseSchema: z.discriminatedUnion('kind', [
      mediaGroupReadableSummaryNotAvailablePayloadSchema,
      mediaGroupReadableSummaryPayloadSchema,
    ]),
  });

export const getMediaGroupAiSummary = async (
  mediaGroupId: string
): Promise<MediaGroupAiSummarySuccessPayload> =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/${mediaGroupId}/ai-summary/`,
    method: 'GET',
    responseSchema: mediaGroupAiSummarySuccessPayloadSchema,
  });

export const enqueueMediaGroupSummary = async (
  mediaGroupId: string
): Promise<MediaGroupAiSummarySuccessPayload | MediaGroupAiSummaryNotPossiblePayload> =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/${mediaGroupId}/ai-summary/`,
    method: 'POST',
    responseSchema: z.discriminatedUnion('kind', [
      mediaGroupAiSummarySuccessPayloadSchema,
      mediaGroupAiSummaryNotPossiblePayloadSchema,
    ]),
  });
