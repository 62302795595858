import DetailsModalBodyPlaceholder, {
  type PlaceholderContentType,
} from './DetailsModalBodyPlaceholder';
import DetailsModalHeaderPlaceholder from './DetailsModalHeaderPlaceholder';

export default function DetailsModalLoading({
  errorAction,
  errorMessage,
  state,
}: {
  errorAction?: React.ReactNode;
  errorMessage?: string;
  state: PlaceholderContentType;
}) {
  return (
    <>
      <DetailsModalHeaderPlaceholder />
      <DetailsModalBodyPlaceholder
        state={state}
        errorMessage={errorMessage}
        errorAction={errorAction}
      />
    </>
  );
}
