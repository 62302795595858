import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useGooglePageTracking = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    window.gtag?.('event', 'page_view', {
      page_path: `${pathname}${search}`,
    });
  }, [pathname, search]);
};

export default useGooglePageTracking;
