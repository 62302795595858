import type { MediaGroupDetailDTO } from '@spaceduck/api';
import Markdown from 'react-markdown';
import LoadingPlaceholder from '../LoadingPlaceholder';

import { Icon16, Icon64 } from '@spaceduck/icons';

import {
  useEnqueueMediaGroupAiSummary,
  useMediaGroupAiSummary,
} from '@/api/mediaGroup';
import { usePoll } from '@/hooks/usePoll';
import { useRotatingChoice } from '@/hooks/useRotatingChoice';
import clsx from 'clsx';
import { useCallback } from 'react';
import NoEntries from '../NoEntries';
import Button from '../ui/Button';
import styles from './SummaryModeView.module.scss';

const { Summary } = Icon64;
const { Magic } = Icon16;

const LOADING_TEXTS = [
  'Printing out a copy',
  'Looking for a highlighter',
  'Comparing notes with classmates',
  'Choosing a font',
  'Spell checking',
  "Rephrasing to avoid the word 'delve'",
  'Going out to buy more sticky notes',
  'Brewing coffee',
  'Looking up the difficult words',
  'Connecting the dots',
  'Double and triple checking',
  'Trimming the fat',
  'Wishing I had hands instead of wings',
  'Waddling through the words',
  'Paddling through the paragraphs',
  'Counting ducklings',
  'Trying to avoid Ducksplaining',
  'Consulting with a wise old owl',
  'Cracking open a fresh notebook',
  'Taking a quick flight around the block',
  'Preparing rations',
  'Reticulating splines',
  'Recruiting more copywriters',
  'Copying the duck next to me',
  'Asking the professor',
  "Celebrating Steve's birthday",
  'Looking for a red pen',
  'Hiding evidence of LLM usage',
  'Deciphering the ancient scrolls',
  'Counting the commas',
  'Channeling inner Shakespeare',
  'Switching to dark mode',
  'Staring wistfully out the window',
  'Sketching doodles in the margins',
  'Meditating over metaphors',
  'Convincing myself I can write',
  'Breaking down character motivations',
  'Admiring my penmanship',
];

const Loading = () => {
  const message = useRotatingChoice(LOADING_TEXTS, 2000);

  return (
    <div className={styles.pane}>
      <div className={styles.content}>
        <LoadingPlaceholder message={`${message}...`} />
      </div>
    </div>
  );
};

const ErrorPanel = ({ onRetry }: { onRetry: () => Promise<void> }) => (
  <div className={styles.pane}>
    <div className={styles.content}>
      <div>Error fetching summary information. Please try again later.</div>
      <div className={styles.actions}>
        <Button onClick={onRetry}>Retry</Button>
      </div>
    </div>
  </div>
);

const Empty = ({ mediaGroupId }: { mediaGroupId: string }) => {
  const { mutateAsync: enqueueMediaGroupAiSummary, status } =
    useEnqueueMediaGroupAiSummary();

  const handleEnqueue = useCallback(async () => {
    await enqueueMediaGroupAiSummary(mediaGroupId);
  }, []);

  if (status === 'pending') {
    return <Loading />;
  }

  return (
    <div className={clsx(styles.pane, styles.empty)}>
      <NoEntries className={styles.noEntries} icon={<Summary />}>
        <h1>Summarize this...</h1>
        <p>
          Need a quick overview? Click 'Summarize' to get a concise breakdown of the
          content, making it easier to grasp the main points at a glance.
        </p>
        <Button
          iconBefore={<Magic />}
          onClick={handleEnqueue}
          size="sm"
          variant="primary"
        >
          Summarize this text
        </Button>
      </NoEntries>
    </div>
  );
};

const Failed = ({ mediaGroupId }: { mediaGroupId: string }) => {
  const { mutateAsync: enqueueMediaGroupAiSummary, status } =
    useEnqueueMediaGroupAiSummary();

  const handleEnqueue = useCallback(async () => {
    await enqueueMediaGroupAiSummary(mediaGroupId);
  }, []);

  if (status === 'pending') {
    return <Loading />;
  }

  return (
    <div className={styles.pane}>
      <div className={styles.content}>
        <div>Failed to generate summary content. Please try again later.</div>
        <div className={styles.actions}>
          <Button onClick={handleEnqueue}>Retry</Button>
        </div>
      </div>
    </div>
  );
};

export const SummaryModeView = ({
  mediaGroup,
}: { mediaGroup: MediaGroupDetailDTO }) => {
  const { data, status, refetch } = useMediaGroupAiSummary(mediaGroup.id);
  const handleRetry = useCallback(async () => {
    await refetch();
  }, []);

  const shouldPoll =
    status === 'success' && data.kind === 'success' && data.summary.kind === 'pending';
  const pollFn = useCallback(() => refetch(), []);
  usePoll(5000, shouldPoll, pollFn);

  if (status === 'pending') {
    return <Loading />;
  }
  if (status === 'error') {
    return <ErrorPanel onRetry={handleRetry} />;
  }

  if (data.summary.kind === 'none') {
    return <Empty mediaGroupId={mediaGroup.id} />;
  }

  if (data.summary.kind === 'failed') {
    return <Failed mediaGroupId={mediaGroup.id} />;
  }

  if (data.summary.kind === 'pending') {
    return <Loading />;
  }

  const {
    summary: { markdown },
  } = data;

  return (
    <div className={clsx(styles.pane, styles.summary)}>
      <div className={styles.gradientBorder}>
        <div className={styles.content}>
          <Markdown>{markdown}</Markdown>
        </div>
      </div>
    </div>
  );
};
