import { clsx } from 'clsx';
import upperFirst from 'lodash/upperFirst';

import type { PlanLabel } from '@/const';
import styles from './Badge.module.scss';

export type BadgeSizes = 'sm' | 'md' | 'lg';
type Color = 1 | 2 | 3;
type Outline = 0 | 1 | 2 | 3 | 4 | 5;
type Background = 1 | 2 | 3 | 4;
type Preset =
  | 'none'
  | 'trial'
  | 'latest'
  | 'canceled'
  | 'paused'
  | 'inProgress'
  | 'completed'
  | 'noStatus'
  | 'planned'
  | PlanLabel;

export type BadgeProps = {
  background?: Background;
  children?: React.ReactNode;
  className?: string;
  color?: Color;
  outline?: Outline;
  preset?: Preset;
  size?: BadgeSizes;
};

export default function Badge({
  background = 3,
  children,
  className,
  color = 2,
  outline = 0,
  preset = 'none',
  size = 'md',
}: BadgeProps) {
  if (!children) return null;

  return (
    <div
      className={clsx(
        styles.badge,
        styles[`background${background}`],
        styles[`outline${outline}`],
        styles[`color${color}`],
        styles[size],
        styles[`preset${upperFirst(preset)}`],
        className
      )}
    >
      {children}
    </div>
  );
}
