import { useForm } from 'react-hook-form';

import { useModalManager } from '@context/ModalManagerContext';
import Button from '@ui/Button';
import Dialog from '@ui/Dialog';
import { openMailTo } from '@utils/openMailTo';
import styles from './FeedbackModal.module.scss';

type FormType = {
  message: string;
};

function FeedbackModal({
  isOpen = true,
  closeModal,
}: {
  isOpen?: boolean;
  closeModal?: () => void;
}) {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormType>();

  const onSubmit = (data: FormType) => {
    const subject = '[Feedback] Spaceduck Website';
    const body = data.message;

    window.open(
      `mailto:support@spaceduck.com?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`
    );
    closeModal?.();
  };

  return (
    <Dialog
      className={styles.dialog}
      closeModal={closeModal}
      isOpen={isOpen}
      modalHeading="Give feedback"
      padding="lg"
    >
      <div className={styles.modalContent}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="formGroup">
            <textarea
              placeholder="Share feedback, feature requests, or report a bug..."
              {...register('message', { required: 'Feedback is required' })}
            />
            {errors.message?.message && (
              <p className="errorMessage">{errors.message?.message}</p>
            )}
          </div>
          <div className={styles.footer}>
            <p className="body5">
              You can also email us at{' '}
              <Button
                onClick={() => openMailTo('[SUPPORT]', '')}
                variant="link"
                type="button"
              >
                support@spaceduck.com
              </Button>
            </p>
            <Button variant="primary" size="sm" type="submit">
              Send
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
}

export function useFeedbackModal() {
  const { openModal, closeModal } = useModalManager();
  return {
    open: () => {
      openModal({
        component: <FeedbackModal />,
      });
    },
    close: closeModal,
  };
}
