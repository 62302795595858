import { Extension } from '@tiptap/core';
import type { Editor, Range } from '@tiptap/react';
import Suggestion, { type SuggestionProps } from '@tiptap/suggestion';
import { PluginKey } from 'prosemirror-state';

import renderSlashMenu from './slashMenu/renderSlashMenu';
import slashMenuItems from './slashMenu/slashMenuItems';

const SlashMenu = Extension.create({
  name: 'slashMenu',
  addOptions() {
    return {
      suggestion: {
        char: '/',
        startOfLine: false,
        command: ({
          editor,
          range,
          props,
        }: {
          editor: Editor;
          range: Range;
          props: SuggestionProps;
        }) => {
          props.command({ editor, range, props });
        },
      },
    };
  },
  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        pluginKey: new PluginKey('SlashMenu'),
        ...this.options.suggestion,
      }),
    ];
  },
});

export default (tippySlashMenuRoot: string | undefined = 'tippySlashMenuRoot') =>
  SlashMenu.configure({
    suggestion: {
      items: slashMenuItems,
      render: renderSlashMenu(tippySlashMenuRoot),
    },
  });
