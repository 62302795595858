import { useWorkspace } from '@/api/workspace';
import useWorkspaceId from '@/hooks/useWorkspaceId';
import { KnockFeedProvider, KnockProvider } from '@knocklabs/react';
import { type ReactElement, useEffect, useState } from 'react';
const KNOCK_API_PUBLIC_KEY = import.meta.env.VITE_KNOCK_API_PUBLIC_KEY;
const KNOCK_API_FEED_ID = import.meta.env.VITE_KNOCK_API_FEED_ID;

export const NotificationProvider = ({
  children,
}: {
  readonly children: ReactElement;
}) => {
  const workspaceId = useWorkspaceId();
  const { data: workspace } = useWorkspace(workspaceId);
  const currentMembership = workspace?.workspace.membershipId;

  const [publicKey, setPublicKey] = useState<string>();
  const [feedId, setFeedId] = useState<string>();
  const [token, setToken] = useState<string>();
  const [sub, setSub] = useState<string>();

  useEffect(() => {
    // using a state here instead of passing the Vite variable directly
    // to avoid the provider re-rendering causing websocket disconnection
    setPublicKey(KNOCK_API_PUBLIC_KEY);
    setFeedId(KNOCK_API_FEED_ID);
    !token && workspace && setToken(workspace.workspace.knockToken);
    !sub && currentMembership && setSub(currentMembership);
  }, [workspace?.workspace]);

  if (!feedId || !publicKey || !sub || !token) {
    return children;
  }

  return (
    <KnockProvider apiKey={publicKey} userId={sub} userToken={token}>
      <KnockFeedProvider feedId={feedId}>{children}</KnockFeedProvider>
    </KnockProvider>
  );
};
