import {
  createLinkResolveMetaRequest,
  linkResolveMetaStatusRequest,
} from '@spaceduck/api/src/resources/linkResolveMeta';
import { useQuery } from '@tanstack/react-query';
import { usePollTimeout } from './usePollTimeout';

export const useExtractMetaAttributes = (url: string, enabled: boolean) => {
  const pollWithTimeout = usePollTimeout(
    500,
    (res) => res !== null,
    async (id: string) => {
      const { data } = await linkResolveMetaStatusRequest(id);
      return data;
    },
    10000
  );

  const resolveMetaAttrs = async (url: string) => {
    const { id } = await createLinkResolveMetaRequest(url);
    const data = await pollWithTimeout(id);

    return data;
  };

  return useQuery({
    queryKey: ['linkMeta', url],
    queryFn: () => resolveMetaAttrs(url),
    enabled,
  });
};
