import { clsx } from 'clsx';
import debounce from 'lodash/debounce';
import type { ChangeEvent, ClipboardEvent } from 'react';
import { useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';

import Logo from '@/assets/img/Logo';
import Button from '@ui/Button';
import { openMailTo } from '@utils/openMailTo';
import styles from './Form.module.scss';

export type CodeFormData = {
  code: string;
};

type CodeFormProps = {
  error: string | null;
  handleBackClick: () => void;
  isLoadingRequestId: boolean;
  isLoadingVerify: boolean;
  onResendRequest: () => Promise<void>;
  onSubmit: SubmitHandler<CodeFormData>;
  targetEmail: string;
};

const CodeForm = ({
  error,
  handleBackClick,
  isLoadingRequestId,
  isLoadingVerify,
  onResendRequest,
  onSubmit,
  targetEmail,
}: CodeFormProps) => {
  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    formState: { errors: codeErrors },
  } = useForm<CodeFormData>();

  const onPasteSubmit = async (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text');
    setValue('code', pastedData.replaceAll(/[-.\s]/gu, ''));
    const isValid = await trigger('code');

    if (isValid) {
      await handleSubmit(onSubmit)();
    }
  };

  const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 8) {
      setValue('code', event.target.value);
    }
    if (event.target.value.length === 8) {
      const isValid = await trigger('code');
      if (isValid) {
        await handleSubmit(onSubmit)();
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <h2 className="title2">Check your email</h2>
      <div className={clsx(styles.message, 'body4')}>
        <p>We've sent a temporary login link.</p>
        <p>
          Please check your inbox at <span className={styles.email}>{targetEmail}</span>
        </p>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={clsx(styles.form, styles.codeForm)}
      >
        <input
          {...register('code', { required: 'Code is required' })}
          className={clsx(styles.codeField, (error || codeErrors.code) && 'hasError')}
          type="text"
          placeholder="Enter the code"
          onPaste={onPasteSubmit}
          onChange={onChange}
          disabled={isLoadingVerify}
        />
        {codeErrors.code && <p className="errorMessage">{codeErrors.code.message}</p>}
        {error && <p className="errorMessage">{error}</p>}
        <Button type="submit" disabled={isLoadingVerify} variant="secondary" size="lg">
          Continue with login code
        </Button>
      </form>
      <div className={clsx(styles.actions, 'body6')}>
        <Button variant="link" onClick={() => handleBackClick()}>
          Back to login
        </Button>
        <Button
          variant="link"
          disabled={isLoadingRequestId}
          onClick={debounce(onResendRequest, 1000)}
        >
          Resend Code
        </Button>
      </div>
      <p className={clsx(styles.getHelp, 'body5')}>
        Need help?{' '}
        <Button
          variant="link"
          className={styles.button}
          onClick={() => openMailTo('[SUPPORT]', '')}
        >
          Get in touch
        </Button>
      </p>
    </div>
  );
};

export default CodeForm;
