import { clsx } from 'clsx';

import ProductPlaceholder from '@components/ProductPlaceholder';
import styles from './ProductLoading.module.scss';

type ProductLoadingProps = {
  numberOfProducts?: number;
};

export default function ProductLoading({ numberOfProducts = 16 }: ProductLoadingProps) {
  return (
    <div className={clsx('productGrid', styles.grid)}>
      {[...Array(numberOfProducts)].map((_, idx) => (
        <ProductPlaceholder key={idx} />
      ))}
    </div>
  );
}
