import type { MediaGroupDTO } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';
import { useEffect, useState } from 'react';

import { useProject } from '@/api/project';
import { activeProjectKeys } from '@/const';
import { useWorkspaceProjects } from '@api/workspace';
import CategoryMenu from '@components/CategoryMenu';
import { makeAddToProjectMenu } from '@components/MediaGroupGrid';
import TagInput from '@components/TagInput';
import useWorkspaceId from '@hooks/useWorkspaceId';
import Button from '@ui/Button';
import DropdownMenu, { RecursiveDropdownMenu } from '@ui/DropdownMenu';
import clsx from 'clsx';
import styles from './BulkSelectionToolbar.module.scss';
import StatusMenu from './StatusMenu';

const { Move } = Icon16;
const { Copy, Tags, TrashCan } = Icon24;

export function BulkSelectionToolbar({
  count,
  onCopyMediaGroup,
  onMoveMediaGroup,
  onAddTag,
  onDelete,
  onCancel,
  onUpdateCategory,
  onUpdateStatus,
  selectedMediaGroups,
  currentProjectId,
  className,
}: {
  count: number;
  onCopyMediaGroup: (projectId?: string) => void;
  onMoveMediaGroup: (projectId?: string) => void;
  onAddTag: (tag: string) => void;
  onDelete: () => void;
  onCancel: () => void;
  onUpdateCategory: (categoryId: string | null) => void;
  onUpdateStatus: (status: string | null) => void;
  selectedMediaGroups: Set<MediaGroupDTO>;
  currentProjectId?: string;
  className?: string;
}) {
  const { data: project } = useProject(currentProjectId || null);
  const workspaceId = useWorkspaceId();
  const { data } = useWorkspaceProjects(workspaceId, {
    status: activeProjectKeys,
  });
  const [selectedStatusId, setSelectedStatusId] = useState<string | null>(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);

  useEffect(() => {
    const statuses = new Set(
      [...selectedMediaGroups].map(({ status }) => status?.id || null)
    );

    const categoryIds = new Set(
      [...selectedMediaGroups].map(({ category }) => category?.id ?? null)
    );

    if (statuses.size === 1) {
      const [status] = statuses;
      setSelectedStatusId(status ?? null);
    } else {
      setSelectedStatusId(null);
    }

    if (categoryIds.size === 1) {
      const [categoryId] = categoryIds;
      setSelectedCategoryId(categoryId ?? null);
    } else {
      setSelectedCategoryId(null);
    }
  }, [selectedMediaGroups]);
  const handleStatusChange = (value: string | null) => {
    setSelectedStatusId(value);
    onUpdateStatus?.(value);
  };

  const handleCategoryChange = (value: string | null) => {
    setSelectedCategoryId(value);
    onUpdateCategory?.(value);
  };

  return (
    <div className={clsx(styles.selectedOptionsBar, className)}>
      <p className={styles.selectedItemCount}>
        {count} item{count > 1 ? 's' : ''} selected
      </p>
      <div className={styles.buttons}>
        <DropdownMenu
          align="center"
          sideOffset={16}
          triggerContent={
            <Button
              className={styles.trigger}
              iconBefore={<Tags size={16} />}
              size="sm"
              variant="outlined"
            >
              Add a tag
            </Button>
          }
        >
          <TagInput className={styles.tagInput} onAddTag={onAddTag} />
        </DropdownMenu>

        <RecursiveDropdownMenu
          items={makeAddToProjectMenu({
            projects: data?.projects ?? [],
            onAddToProject: onCopyMediaGroup,
            onAddToRepository: () => onCopyMediaGroup(),
          })}
        >
          <Button
            className={styles.trigger}
            size="sm"
            variant="outlined"
            iconBefore={<Copy size={16} />}
          >
            Copy to
          </Button>
        </RecursiveDropdownMenu>
        <RecursiveDropdownMenu
          items={makeAddToProjectMenu({
            projects: data?.projects ?? [],
            onAddToProject: onMoveMediaGroup,
            onAddToRepository: () => onMoveMediaGroup(),
          })}
        >
          <Button
            className={styles.trigger}
            iconBefore={<Move size={16} />}
            size="sm"
            variant="outlined"
          >
            Move to
          </Button>
        </RecursiveDropdownMenu>
        {project && (
          <StatusMenu
            projectId={project.project.id}
            showSelectedAsCheck
            currentStatus={project.project.mediaGroupStatuses.find(
              (status) => status.id === selectedStatusId
            )}
            handleStatusUpdate={(value) => handleStatusChange(value)}
            width={220}
          />
        )}
        <CategoryMenu
          handleCategoryChange={(value) => handleCategoryChange(value)}
          selectedCategoryId={selectedCategoryId}
          workspaceId={workspaceId}
        />
        <Button
          className={styles.trigger}
          iconBefore={<TrashCan size={16} />}
          onClick={onDelete}
          size="sm"
          variant="outlined"
        >
          Delete items
        </Button>
        <Button size="sm" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
}
