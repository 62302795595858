import { Icon24 } from '@spaceduck/icons';

import { BROWSER_EXT_URL } from '@/const';
import Button from '@ui/Button';
import styles from './Integrations.module.scss';
import { Container, Header, TopNav } from './common/';

const { SpaceduckExtension } = Icon24;

export default function Integrations() {
  return (
    <>
      <TopNav title="Integrations" currentBreadcrumb="Integrations" owner="workspace" />
      <Container>
        <Header>
          <h1>Integrations</h1>
          <p>
            Enhance your Spaceduck experience with a variety of add-ons and
            integrations.
          </p>
        </Header>
        <div className={styles.list}>
          <Item
            buttonText="Download"
            icon={<SpaceduckExtension size={20} />}
            onClick={() => window.open(BROWSER_EXT_URL)}
          >
            <h3>Browser extension</h3>
            <p>
              Enhance your product research and team collaboration with our browser
              extension.
            </p>
          </Item>
        </div>
      </Container>
    </>
  );
}

const Item = ({
  buttonText,
  children,
  icon,
  onClick,
}: {
  buttonText?: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: () => void;
}) => {
  return (
    <div className={styles.item}>
      <div className={styles.image}>{icon}</div>
      <div className={styles.description}>{children}</div>
      <div className={styles.action}>
        {buttonText && (
          <Button onClick={onClick} size="sm" variant="outlined">
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};
