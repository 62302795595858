import { z } from 'zod';
import { ApiClient } from '../client';
import { successfulResponseSchema } from '../schema';
import { apiUrl } from '../util';
import {
  type ProjectDuty,
  type ProjectMode,
  type ProjectRoleDutySummary,
  type ProjectStatus,
  type Role,
  projectDetailDTO,
} from './schemas';

export const getProject = async (id?: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/auth/project/${id}/`,
    method: 'GET',
    responseSchema: successfulResponseSchema.extend({
      project: projectDetailDTO,
    }),
  });

type CreateProjectParams = {
  workspaceId: string;
  label: string;
  description: string;
  mode: ProjectMode;
  leadId?: string | null;
  contributorIds?: string[];
  viewerIds?: string[];
  isStarred?: boolean;
};

export const createProject = async ({ workspaceId, ...data }: CreateProjectParams) =>
  ApiClient.call({
    endpoint: apiUrl`/w/auth/workspace/${workspaceId}/project/`,
    method: 'POST',
    body: data,
    responseSchema: successfulResponseSchema.extend({
      id: z.string(),
    }),
  });

export type ProjectPatch = {
  label?: string;
  description?: string;
  mode?: ProjectMode;
  status?: ProjectStatus;
  targetDate?: string | null;
  isStarred?: boolean;
};

export type PatchProjectParams = {
  id: string;
  patch: ProjectPatch;
};

const patchProjectSchema = successfulResponseSchema.extend({
  workspaceId: z.string(),
});

export const patchProject = ({ id, patch }: PatchProjectParams) =>
  ApiClient.call({
    method: 'PATCH',
    endpoint: apiUrl`/w/auth/project/${id}/`,
    body: { patch },
    responseSchema: patchProjectSchema,
  });

type PatchProjectMembershipParams = {
  userId: string;
  projectId: string;
  trigger?: {
    reason: 'comment';
    id: string;
  };
} & (
  | { role?: Role; duty?: ProjectDuty | null }
  | { roleDutySummary?: ProjectRoleDutySummary }
);

export const deleteProject = (id: string) =>
  ApiClient.call({
    method: 'DELETE',
    endpoint: apiUrl`/w/auth/project/${id}/`,
    responseSchema: successfulResponseSchema,
  });

export const restoreProject = (id: string) =>
  ApiClient.call({
    method: 'POST',
    endpoint: apiUrl`/w/auth/project/restore/`,
    body: {
      projectId: id,
    },
    responseSchema: successfulResponseSchema,
  });

type AddWebLinkToProjectParams = {
  projectId: string;
  label: string;
  url: string;
};

const addWebLinkToProjectResponseSchema = successfulResponseSchema.extend({
  webLink: z.object({ id: z.string(), label: z.string(), url: z.string() }),
});

export const addWebLinkToProject = async ({
  projectId,
  ...body
}: AddWebLinkToProjectParams) =>
  ApiClient.call({
    method: 'POST',
    endpoint: apiUrl`/w/auth/project/${projectId}/web-link/`,
    responseSchema: addWebLinkToProjectResponseSchema,
    body,
  });

const patchProjectMembershipResponseSchema = successfulResponseSchema.extend({
  user: z.object({ id: z.string(), name: z.string() }),
});
export const patchProjectMembership = async ({
  projectId,
  userId,
  ...patch
}: PatchProjectMembershipParams) =>
  ApiClient.call({
    method: 'PATCH',
    endpoint: apiUrl`/w/auth/project/${projectId}/member/${userId}/`,
    body: { patch, trigger: patch.trigger },
    responseSchema: patchProjectMembershipResponseSchema,
  });

type RemoveUserFromProjectParams = { userId: string; projectId: string };
const removeUserFromProjectResponseSchema = successfulResponseSchema.extend({
  user: z.object({ id: z.string(), name: z.string() }).nullable(),
});
export const removeUserFromProject = async ({
  userId,
  projectId,
}: RemoveUserFromProjectParams) =>
  ApiClient.call({
    method: 'DELETE',
    endpoint: apiUrl`/w/auth/project/${projectId}/member/${userId}/`,
    responseSchema: removeUserFromProjectResponseSchema,
  });

type JoinProjectParams = { projectId: string };
const joinProjectResponseSchema = successfulResponseSchema.extend({
  joined: z.boolean(),
});
export const joinProject = async ({ projectId }: JoinProjectParams) =>
  ApiClient.call({
    method: 'POST',
    endpoint: apiUrl`/w/auth/project/${projectId}/join/`,
    responseSchema: joinProjectResponseSchema,
  });
