import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10317_46267)">
        <path
          stroke={color}
          d="M12.25 1.5C10.179 1.5 8.5 2.395 8.5 3.5C8.5 2.395 6.821 1.5 4.75 1.5C2.679 1.5 1 2.395 1 3.5V14.5C1 13.395 2.679 12.5 4.75 12.5C6.821 12.5 8.5 13.395 8.5 14.5C8.5 13.395 10.179 12.5 12.25 12.5C14.321 12.5 16 13.395 16 14.5V3.5C16 2.395 14.321 1.5 12.25 1.5Z"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10317_46267">
          <rect width="16" height="16" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
