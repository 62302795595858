import type { ReactNode } from 'react';
import styles from './IFrameCheck.module.scss';

export default function IFrameCheck({
  children,
  message,
}: {
  children: ReactNode;
  message: string;
}) {
  if (window.parent === window) {
    // not in an iframe
    return <p className={styles.message}>{message}</p>;
  }

  return children;
}
