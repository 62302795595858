import { type TextFieldInstance, isTextFieldInstance } from '@spaceduck/api';
import { Icon16 } from '@spaceduck/icons';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import type React from 'react';
import { useEffect, useRef, useState } from 'react';

import type {
  AvailableTypes,
  StandardCellValue,
  StandardEditCellValue,
} from '@/types/Category';
import { useCategoryCellSelectionKeyboard } from '@hooks/useCategoryCellSelection';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import { css } from '@lib/css';
import Button from '@ui/Button';
import ScrollArea from '@ui/ScrollArea';
import styles from './Phone.module.scss';

const { Add, Delete } = Icon16;

function getInitialValue(value: AvailableTypes) {
  return value.filter(isTextFieldInstance) ?? [];
}

export default function PhoneValue({
  canEdit,
  clearSelectedCell,
  handleDelete,
  handleUpdate,
  info,
  setSelectedCell,
  showPreview,
  value,
}: StandardCellValue) {
  const initialValue = getInitialValue(value);
  const [shouldShowEditView, setShouldShowEditView] = useState(false);
  const [localValue, setLocalValue] = useState<TextFieldInstance[]>(initialValue);

  const { setEnabled } = useCategoryCellSelectionKeyboard({
    setSelectedCell,
    enableOnLoad: false,
    onEnter: () => setShouldShowEditView(true),
    onEscape: () => {
      shouldShowEditView ? setShouldShowEditView(false) : clearSelectedCell();
    },
  });

  useEffect(() => {
    setEnabled(showPreview);
  }, [showPreview]);

  return (
    <>
      {canEdit && shouldShowEditView && (
        <EditPhoneValue
          clearSelectedCell={clearSelectedCell}
          handleDelete={handleDelete}
          handleUpdate={handleUpdate}
          info={info}
          initialValue={initialValue}
          localValue={localValue}
          setLocalValue={setLocalValue}
          setShouldShowEditView={setShouldShowEditView}
        />
      )}
      {showPreview ? (
        <div
          className={clsx(styles.phoneNumbers, styles.preview)}
          onClick={canEdit ? () => setShouldShowEditView(true) : undefined}
        >
          {localValue.map(({ value }, idx) => (
            <div className={styles.linkPreviewItem} key={idx}>
              <span className={styles.phoneNumber}>{value}</span>
            </div>
          ))}
        </div>
      ) : (
        <div className={clsx(styles.phoneNumbers, shouldShowEditView && styles.hidden)}>
          <div className={styles.sizeWrapper}>
            <div className={styles.overflowWrapper}>
              {localValue.map(({ value }, idx) => (
                <span className={styles.phoneNumber} key={idx}>
                  {value}
                </span>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const EditPhoneValue = ({
  handleDelete,
  handleUpdate,
  info,
  initialValue,
  localValue,
  setLocalValue,
  setShouldShowEditView,
}: StandardEditCellValue<TextFieldInstance[]>) => {
  const localValueRef = useRef(localValue);
  const setLocalValueRef = (value: TextFieldInstance[]) => {
    localValueRef.current = value;
    setLocalValue(value);
  };

  const persist = async () => {
    const phoneNumbers = localValueRef.current?.filter(
      (entry) => !!entry.value?.trim()
    );
    // Stop update if value is same as initial value
    if (isEqual(initialValue, phoneNumbers)) {
      return;
    }

    const value = phoneNumbers ?? null;

    // Optimistic update
    info.table.options.meta?.updateData?.(info.row.index, info.column.id, value);

    if (!value) {
      handleDelete?.({
        propertyId: info.column.id,
        rowIndex: info.row.index,
      });
      return;
    }

    await handleUpdate?.({
      propertyId: info.column.id,
      rowIndex: info.row.index,
      value,
    });
  };

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    setLocalValueRef(
      [...localValueRef.current].map((entry, index) =>
        idx === index ? { ...entry, value: ev.target.value } : entry
      )
    );
  };

  const handleAddEntry = () => {
    setLocalValueRef([...localValueRef.current, { value: '' }]);
    setTimeout(() => {
      if (containerRef.current) {
        const inputs = Array.from(containerRef.current.querySelectorAll('input'));
        const lastInput = inputs.at(-1);
        if (lastInput) {
          lastInput.focus();
        }
      }
    }, 0);
  };

  const handleAddKeyDown = (ev: React.KeyboardEvent) => {
    if (ev.key === 'Enter' || ev.key === ' ') {
      ev.preventDefault();
      ev.stopPropagation();
      handleAddEntry();
    }
  };

  const handleKeyDown = (ev: React.KeyboardEvent) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      ev.stopPropagation();
      handleAddEntry();
    }

    if (ev.key === 'Escape') {
      ev.preventDefault();
      ev.stopPropagation();
      persist();
      setShouldShowEditView(false);
    }
  };

  const removeEntry = (idx: number) => {
    setLocalValueRef([...localValueRef.current.filter((_, index) => index !== idx)]);
  };

  const { containerRef } = useOnClickOutside<HTMLDivElement>({
    callback: () => {
      persist();
      setShouldShowEditView(false);
    },
  });

  return (
    <div className={styles.phoneNumbersEdit} ref={containerRef}>
      <ScrollArea
        className={clsx(
          styles.phoneNumbersScrollArea,
          localValueRef.current.length > 0 && styles.hasEntries
        )}
        style={css({
          '--width': '100%',
          '--maxHeight': '100%',
        })}
      >
        {localValue.map(({ value }, idx) => (
          <div className={styles.inputWrapper} key={idx}>
            <input
              className={styles.phoneNumber}
              onChange={(ev) => handleChange(ev, idx)}
              onKeyDown={handleKeyDown}
              value={value}
              type="text"
            />
            <Button onClick={() => removeEntry(idx)} variant="icon">
              <Delete />
            </Button>
          </div>
        ))}
      </ScrollArea>
      <Button
        autoFocus
        className={styles.addButton}
        iconBefore={<Add />}
        onClick={handleAddEntry}
        onKeyDown={handleAddKeyDown}
        variant="ghost"
      >
        Add a number
      </Button>
    </div>
  );
};
