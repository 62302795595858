import {
  type ListProjectsParams,
  type Role,
  createWorkspace,
  createWorkspaceInvite,
  deleteWorkspace,
  deleteWorkspaceMember,
  getMediaGroupCategory,
  getWorkspace,
  listMediaGroupCategories,
  listProjects,
  listWorkspaceInvites,
  listWorkspaceMembers,
  listWorkspaces,
  patchWorkspace,
  patchWorkspaceMember,
  workspaceMemberDetails,
} from '@spaceduck/api';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { QueryDisabledError } from './errors';

import { useRefreshSession } from '@hooks/useAuth';

export const workspaceKeys = {
  all: ['workspace'] as const,
  one: (workspaceId: string | null) => [...workspaceKeys.all, workspaceId] as const,
  list: () => [...workspaceKeys.all, 'list'] as const,
  workspaceMember: (workspaceId: string | null, userId: string | null) => [
    ...workspaceKeys.one(workspaceId),
    'workspaceMember',
    userId,
  ],
  listProjects: (
    workspaceId: string | null,
    filters?: Omit<ListProjectsParams, 'workspaceId'>
  ) => [...workspaceKeys.one(workspaceId), 'projects', filters] as const,
  listInvites: (workspaceId: string | null, query: string) =>
    [...workspaceKeys.one(workspaceId), 'invites', query] as const,
  listMembers: (filters: {
    workspaceId: string | null;
    query: string;
    assignedRoles: Role[];
  }) => [...workspaceKeys.all, 'members', filters] as const,
  mediaGroupCategory: (categoryId: string | null) => [
    ['mediaGroupCategory', categoryId] as const,
  ],
  listMediaGroupCategories: (workspaceId: string | null, filters: { query: string }) =>
    [...workspaceKeys.one(workspaceId), 'mediaGroupCategories', filters] as const,
};

export const useWorkspace = (workspaceId: string | null) => {
  const enabled = !!workspaceId;
  const query = useQuery({
    enabled,
    queryKey: workspaceKeys.one(workspaceId),
    queryFn: () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      return getWorkspace(workspaceId);
    },
  });
  return query;
};

export const useListWorkspaces = () => {
  const query = useQuery({
    queryKey: workspaceKeys.all,
    queryFn: listWorkspaces,
  });
  return query;
};

export const usePatchWorkspace = () => {
  const refreshSession = useRefreshSession();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: patchWorkspace,
    onSuccess: async () => {
      await refreshSession();
      await queryClient.invalidateQueries({ queryKey: workspaceKeys.all });
    },
  });
  return mutation;
};

export const useCreateWorkspace = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: createWorkspace,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: workspaceKeys.all });
    },
  });
  return mutation;
};

export const useWorkspaceProjects = (
  workspaceId: string | null,
  params?: Omit<ListProjectsParams, 'workspaceId'>
) => {
  const enabled = !!workspaceId;
  const query = useQuery({
    enabled,
    queryKey: workspaceKeys.listProjects(workspaceId, params),
    queryFn: () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      return listProjects({ workspaceId, ...params });
    },
  });
  return query;
};

export const useListMediaGroupCategories = (
  workspaceId: string | null,
  query?: string
) => {
  const enabled = !!workspaceId;
  const infiniteQuery = useInfiniteQuery({
    enabled,
    queryKey: workspaceKeys.listMediaGroupCategories(workspaceId, {
      query: query ?? '',
    }),
    initialPageParam: undefined as string | undefined,
    queryFn: async ({ pageParam }) => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      const response = await listMediaGroupCategories({
        workspaceId,
        query: query ?? '',
        cursor: pageParam,
      });
      return response;
    },
    getNextPageParam: (lastPage) => lastPage?.nextCursor ?? undefined,
  });
  return infiniteQuery;
};

export const useMediaGroupCategory = (categoryId: string | null) => {
  const enabled = !!categoryId;
  const query = useQuery({
    enabled,
    queryKey: workspaceKeys.mediaGroupCategory(categoryId),
    queryFn: async () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      const response = await getMediaGroupCategory({ categoryId });
      const { kind, ...payload } = response;
      return payload;
    },
  });
  return query;
};

export const useWorkspaceMembers = (
  workspaceId: string | null,
  query?: string,
  assignedRoles?: Role[]
) => {
  const enabled = !!workspaceId;
  const infiniteQuery = useInfiniteQuery({
    enabled,
    queryKey: workspaceKeys.listMembers({
      workspaceId,
      query: query ?? '',
      assignedRoles: assignedRoles ?? [],
    }),
    initialPageParam: undefined as string | undefined,
    queryFn: async ({ pageParam }) => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      const response = await listWorkspaceMembers(workspaceId, {
        query: query ?? '',
        cursor: pageParam,
        assignedRoles: assignedRoles ?? [],
      });
      const { kind, ...payload } = response;
      return payload;
    },
    getNextPageParam: (lastPage) => lastPage?.nextCursor ?? undefined,
    getPreviousPageParam: () => undefined,
  });
  return infiniteQuery;
};

export const useWorkspaceMember = (
  workspaceId: string | null,
  userId: string | null
) => {
  const enabled = !!workspaceId && !!userId;
  const query = useQuery({
    enabled,
    queryKey: workspaceKeys.workspaceMember(workspaceId, userId),
    queryFn: async () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      const response = await workspaceMemberDetails(workspaceId, userId);
      const { kind, ...payload } = response;
      return payload;
    },
  });
  return query;
};

export const usePatchWorkspaceMember = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: patchWorkspaceMember,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: workspaceKeys.all });
    },
  });
  return mutation;
};

export const useDeleteWorkspaceMember = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: deleteWorkspaceMember,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: workspaceKeys.all });
    },
  });
  return mutation;
};

export const useDeleteWorkspace = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: deleteWorkspace,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: workspaceKeys.all });
    },
  });
  return mutation;
};

export const useWorkspaceInvites = (workspaceId: string | null, query?: string) => {
  const enabled = !!workspaceId;
  const infiniteQuery = useInfiniteQuery({
    enabled,
    queryKey: workspaceKeys.listInvites(workspaceId, query ?? ''),
    initialPageParam: undefined as string | undefined,
    queryFn: async ({ pageParam }) => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      const response = await listWorkspaceInvites(workspaceId, {
        query,
        cursor: pageParam,
      });
      const { kind, ...payload } = response;
      return payload;
    },
    getNextPageParam: (lastPage) => lastPage?.nextCursor ?? undefined,
    getPreviousPageParam: () => undefined,
  });
  return infiniteQuery;
};

export const useCreateWorkspaceInvite = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: createWorkspaceInvite,
    onSuccess: (_data, { workspaceId }) => {
      queryClient.invalidateQueries({
        queryKey: workspaceKeys.one(workspaceId),
      });
    },
  });
  return mutation;
};
