import * as RadixSelect from '@radix-ui/react-select';
import type {
  SelectContentProps as RadixSelectContentProps,
  SelectItemProps as RadixSelectItemProps,
  SelectProps as RadixSelectProps,
} from '@radix-ui/react-select';
import { Icon24 } from '@spaceduck/icons';
import { clsx } from 'clsx';
import React, { Fragment, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import Spinner from '@ui/Spinner';
import styles from './Select.module.scss';

const { Check, Down, Up } = Icon24;

export type SelectItem = {
  description?: string;
  icon?: React.ReactNode;
  isDisabled?: boolean;
  label: string;
  value: string;
};

type SelectItemProps = {
  children?: React.ReactNode;
  className?: string;
  showIndicator?: boolean;
} & RadixSelectItemProps;

type SelectGroup = {
  label?: string;
  options: SelectItem[];
};

type SelectProps = {
  className?: string;
  contentClassName?: string;
  data?: {
    isPaginated?: boolean;
    canLoadMore?: boolean;
    loadMore?: () => void;
  };
  loading?: boolean;
  placeholder: React.ReactNode;
  selectContentProps?: RadixSelectContentProps;
  selectGroups: SelectGroup[];
  showGroupLabels?: boolean;
  showIndicator?: boolean;
  style?: React.CSSProperties;
  triggerClassName?: string;
} & RadixSelectProps;

export default function Select({
  className,
  contentClassName,
  data,
  loading = false,
  placeholder,
  selectContentProps,
  selectGroups,
  showGroupLabels,
  showIndicator = false,
  style,
  triggerClassName,
  ...radixProps
}: SelectProps) {
  const { ref: loadMoreRef, inView } = useInView();
  useEffect(() => {
    if (data?.canLoadMore && data.loadMore) {
      data.loadMore();
    }
  }, [data?.canLoadMore, inView]);

  return (
    <div className={className} style={style}>
      <RadixSelect.Root {...radixProps}>
        <RadixSelect.Trigger className={clsx(styles.selectTrigger, triggerClassName)}>
          <RadixSelect.Value
            placeholder={<div className={styles.selectPlaceholder}>{placeholder}</div>}
          />
          <RadixSelect.Icon className={styles.selectIcon}>
            {!loading && <Down size={24} />}
            {loading && <Spinner size={16} />}
          </RadixSelect.Icon>
        </RadixSelect.Trigger>
        <RadixSelect.Portal>
          <RadixSelect.Content
            {...selectContentProps}
            className={clsx(styles.selectContent, contentClassName)}
            position="popper"
            sideOffset={6}
          >
            <RadixSelect.ScrollUpButton className={styles.selectScrollButton}>
              <Up size={20} />
            </RadixSelect.ScrollUpButton>
            <RadixSelect.Viewport className={styles.selectViewport}>
              {selectGroups.map((selectGroup, idx) => (
                <Fragment key={idx}>
                  {idx !== 0 && (
                    <RadixSelect.Separator className={styles.selectSeparator} />
                  )}
                  <SelectGroup
                    selectGroup={selectGroup}
                    showGroupLabel={showGroupLabels ?? selectGroups.length > 1}
                    showIndicator={showIndicator}
                  >
                    {data?.isPaginated && data.loadMore && data.canLoadMore && (
                      <div ref={loadMoreRef} style={{ width: '100%', height: '10px' }}>
                        {/* Triggers page fetch when in view */}
                      </div>
                    )}
                  </SelectGroup>
                </Fragment>
              ))}
            </RadixSelect.Viewport>
            <RadixSelect.ScrollDownButton className={styles.selectScrollButton}>
              <Down size={20} />
            </RadixSelect.ScrollDownButton>
          </RadixSelect.Content>
        </RadixSelect.Portal>
      </RadixSelect.Root>
    </div>
  );
}

const SelectGroup = ({
  children,
  selectGroup,
  showGroupLabel,
  showIndicator,
}: {
  children?: React.ReactNode;
  selectGroup: SelectGroup;
  showGroupLabel?: boolean;
  showIndicator?: boolean;
}) => {
  return (
    <RadixSelect.Group>
      {showGroupLabel && (
        <RadixSelect.Label className={styles.selectLabel}>
          {selectGroup.label}
        </RadixSelect.Label>
      )}
      {selectGroup.options.map(
        ({ description, icon, isDisabled, label, value }, idx) => (
          <SelectItem
            value={value}
            key={idx}
            disabled={isDisabled}
            showIndicator={showIndicator}
          >
            <div className={styles.option}>
              {icon && <div className={styles.optionIcon}>{icon}</div>}
              <div>
                <div className={styles.optionTitle}>{label}</div>
                {description && (
                  <div className={styles.optionDescription}>{description}</div>
                )}
              </div>
            </div>
          </SelectItem>
        )
      )}
      {children}
    </RadixSelect.Group>
  );
};

const SelectItem = React.forwardRef(
  (
    { children, className, showIndicator, ...props }: SelectItemProps,
    forwardedRef: React.Ref<HTMLDivElement>
  ) => {
    return (
      <RadixSelect.Item
        className={clsx(styles.selectItem, className)}
        {...props}
        ref={forwardedRef}
      >
        <RadixSelect.ItemText className={styles.selectItemText}>
          {children}
        </RadixSelect.ItemText>
        {showIndicator && (
          <RadixSelect.ItemIndicator className={styles.selectItemIndicator}>
            <Check />
          </RadixSelect.ItemIndicator>
        )}
      </RadixSelect.Item>
    );
  }
);
