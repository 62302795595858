import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { urlFor } from '@/urls';
import { useCreateWorkspace } from '@api/workspace';
import Icon from '@assets/img/Icon.svg';
import { OuterSpace } from '@layouts/Stars';
import Button from '@ui/Button';
import styles from './CreateWorkspacePage.module.scss';

type FormModel = {
  workspaceName: string;
};

export default function CreateWorkspacePage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormModel>();
  const { mutateAsync: createWorkspace } = useCreateWorkspace();
  const navigate = useNavigate();

  const onSubmit = async (data: FormModel) => {
    const res = await createWorkspace(data.workspaceName);
    if (res.kind === 'success') {
      navigate(urlFor('userSettingsWorkspaces'));
    }
  };

  return (
    <OuterSpace light={true} className={styles.space}>
      <div className={styles.container}>
        <img src={Icon} width={88} height={88} alt="Spaceduck" />
        <h1>Create your workspace</h1>
        <p>
          Workspaces offer a collaborative environment where you can perform your tasks
          and engage with your team and clients.
        </p>
        <form className={styles.createWorkspaceForm} onSubmit={handleSubmit(onSubmit)}>
          <input
            type="text"
            placeholder="Workspace name"
            {...register('workspaceName', { required: true })}
          />
          {errors.workspaceName && (
            <span className="error">Workspace name is required</span>
          )}
          <Button variant="primary" type="submit">
            Create workspace
          </Button>
        </form>
        <p className={styles.help}>
          Need help? <a href="mailto:support@spaceduck.com">Get in touch</a>
        </p>
      </div>
    </OuterSpace>
  );
}
