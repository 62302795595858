import clsx from 'clsx';
import { forwardRef } from 'react';

import styles from './TabButton.module.scss';

export type TabButtonVariants = 'ghost' | 'dark' | 'light';

export type TabButtonProps = {
  extraInfo?: string;
  iconBefore?: React.ReactNode;
  isActive?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  variant?: TabButtonVariants;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const TabButton = forwardRef<HTMLButtonElement, TabButtonProps>(
  (tabButtonProps: TabButtonProps, ref) => {
    const {
      children,
      className,
      disabled = false,
      extraInfo,
      iconBefore,
      isActive = false,
      onClick,
      size = 'sm',
      variant = 'ghost',
      ...buttonProps
    } = tabButtonProps;

    return (
      <button
        {...buttonProps}
        className={clsx(
          styles.tabButton,
          styles[size],
          styles[variant],
          extraInfo && styles.hasExtraInfo,
          isActive && styles.active,
          className
        )}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
        type="button"
      >
        {!!iconBefore && <span className={styles.icon}>{iconBefore}</span>}
        <span className={styles.label}>{children}</span>
        {!!extraInfo && (
          <>
            <span className={styles.separator} />
            <span className={styles.extraInfo}>{extraInfo}</span>
          </>
        )}
      </button>
    );
  }
);

export type TabButtonWrapperProps = {
  children: React.ReactNode;
  className?: string;
  variant: TabButtonVariants;
};

export const TabButtonWrapper = forwardRef<HTMLDivElement, TabButtonWrapperProps>(
  ({ children, className, variant }: TabButtonWrapperProps, ref) => {
    return (
      <div className={clsx(styles[`${variant}Wrapper`], className)} ref={ref}>
        {children}
      </div>
    );
  }
);

type ControlledTabButtonWrapperProps = {
  activeTab: string;
  className?: string;
  options: { label?: string; onClick?: () => void; value: string }[];
  nav?: 'SubNav';
  variant: TabButtonVariants;
};

export const ControlledTabButtonWrapper = forwardRef<
  HTMLDivElement,
  ControlledTabButtonWrapperProps
>(
  (
    { activeTab, className, options, nav, variant }: ControlledTabButtonWrapperProps,
    ref
  ) => {
    return (
      <div
        className={clsx(
          styles[`${variant}Wrapper`],
          nav === 'SubNav' && styles.isSubNav,
          className
        )}
        ref={ref}
      >
        {options.map(({ label, onClick, value }) => (
          <TabButton
            isActive={value === activeTab}
            key={value}
            onClick={() => onClick?.()}
            variant={variant}
          >
            {label ?? value}
          </TabButton>
        ))}
      </div>
    );
  }
);

export default TabButton;
