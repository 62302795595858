import { Icon24 } from '@spaceduck/icons';
import { useSidebarStateStore } from '@stores/useSidebarStateStore';
import Button from '@ui/Button';
import styles from './SidebarMenuToggle.module.scss';
const { Hamburger } = Icon24;

export default function SidebarMenuToggle() {
  const { openSidebar } = useSidebarStateStore(({ openSidebar }) => ({
    openSidebar,
  }));

  return (
    <Button
      onClick={openSidebar}
      type="button"
      variant="ghost"
      className={styles.button}
    >
      <Hamburger size={20} />
    </Button>
  );
}
