import type { MediaGroupDTO } from '@spaceduck/api';
import { useCallback, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

export function useSelectedMediaGroups() {
  const [selectedMediaGroups, setSelectedMediaGroups] = useState(
    () => new Set<MediaGroupDTO>()
  );

  const onSelected = useCallback(
    (selected: MediaGroupDTO) => {
      const hasSelectedItem = !![...selectedMediaGroups].find(
        (existingSetItem) => existingSetItem.id === selected.id
      );
      if (hasSelectedItem) {
        const newSelectedMediaGroups = new Set(
          [...selectedMediaGroups].filter(
            (existingSetItem) => existingSetItem.id !== selected.id
          )
        );
        setSelectedMediaGroups(newSelectedMediaGroups);
      } else {
        const newSelectedMediaGroups = new Set(selectedMediaGroups);
        newSelectedMediaGroups.add(selected);
        setSelectedMediaGroups(newSelectedMediaGroups);
      }
    },
    [selectedMediaGroups]
  );

  const clearSelected = useCallback(() => {
    setSelectedMediaGroups(new Set<MediaGroupDTO>());
  }, []);

  useHotkeys(
    'Escape',
    () => {
      clearSelected();
    },
    {
      enableOnFormTags: ['input'],
    },
    [clearSelected]
  );

  return {
    selectedMediaGroupIds: new Set(
      Array.from(selectedMediaGroups).map((mediaGroup) => mediaGroup.id)
    ),
    selectedMediaGroups,
    onSelected,
    clearSelected,
  };
}
