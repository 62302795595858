import { SessionSchema } from './schema';
import type {
  Access,
  AuthenticatedSession,
  AuthenticationMethod,
  BearerAccess,
  Oauth2AuthenticationMethod,
  Session,
} from './schema';

export const getSession = async (origin: string): Promise<Session> => {
  const url = new URL('/_/auth/session/info/', origin);
  const headers = new Headers({ accept: 'application/json' });
  const response = await fetch(url, { headers });
  const responseData = (await response.json()) as unknown;
  return SessionSchema.parse(responseData);
};

export const getHeaders = (config: RequestInit): Headers => {
  const { headers } = config;

  if (headers === undefined) {
    return new Headers();
  }

  if (headers instanceof Headers) {
    return headers;
  }

  return new Headers(headers);
};

export const findBearerAccess = (access: Access[]): BearerAccess | null =>
  access.find(({ kind }) => kind === 'bearer') ?? null;

export const filterOAuth2Methods = (
  methods: AuthenticationMethod[]
): Oauth2AuthenticationMethod[] =>
  methods.filter(
    (method): method is Oauth2AuthenticationMethod => method.kind === 'oauth2'
  );

export const reconfigureWithAuthorization = (
  config: RequestInit,
  authenticatedSession: AuthenticatedSession
): RequestInit => {
  const headers = getHeaders(config);
  const bearer = findBearerAccess(authenticatedSession.access);

  if (!bearer) {
    throw Error('No supported authentication method present');
  }

  headers.set(bearer.header, `Bearer ${bearer.token}`);

  return {
    ...config,
    headers,
  };
};

export const fetchWithSession = async (
  url: string | URL,
  config: RequestInit | null,
  session: AuthenticatedSession
) => {
  const authenticatedConfig = reconfigureWithAuthorization(config ?? {}, session);
  const response = await fetch(url, authenticatedConfig);
  return response;
};
