import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { urlFor } from '@/urls';
import { useUserInfo } from '@hooks/useAuth';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { Icon24 } from '@spaceduck/icons';
import Button from '@ui/Button';
import UserAvatar from '@ui/UserAvatar';
import styles from './BackButton.module.scss';
const { LeftArrow } = Icon24;

export default function BackButton() {
  const workspaceId = useWorkspaceId();
  const user = useUserInfo();
  const { avatarUrl, preferredName } = user || {};
  const url = workspaceId
    ? urlFor('workspaceRepository', { workspaceId })
    : urlFor('home');

  return (
    <Link className={styles.link} to={url}>
      <Button
        className={clsx(
          styles.button,
          (avatarUrl || preferredName) && styles.hasAvatar
        )}
        iconBefore={<LeftArrow size={20} />}
        type="button"
        variant="ghost"
      >
        {(avatarUrl || preferredName) && (
          <UserAvatar imageUrl={avatarUrl} name={preferredName ?? '?'} size="xxs" />
        )}
        <span className={styles.text}>
          <span className={styles.label}>Account settings</span>
          {preferredName && <span className={styles.user}>{user?.preferredName}</span>}
        </span>
      </Button>
    </Link>
  );
}
