import type { ProjectDTO } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';

import { activeProjectKeys } from '@/const';
import { useMediaGroupDetail } from '@api/mediaGroup';
import { useProject } from '@api/project';
import { useWorkspaceProjects } from '@api/workspace';
import { ProjectMode } from '@components/icons';
import { useMediaGroupTransfer } from '@hooks/useMediaGroupTransfer';
import { useMediaGroupPermissions } from '@hooks/usePermissions';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { exists } from '@spaceduck/utils';
import Button from '@ui/Button';
import type { ContextMenuItemProps } from '@ui/ContextMenu';
import { RecursiveDropdownMenu, Separator, TriggerButton } from '@ui/DropdownMenu';
import dropdownMenuStyles from '@ui/DropdownMenu.module.scss';
import Tooltip from '@ui/Tooltip';
import { copyUrlToClipboard } from '@utils/copyToClipboard';
import { downloadUrl } from '@utils/download';

const { Move } = Icon16;
const { Copy, Download, Link, Menu, RepositoryBrowse, Right, TrashCan } = Icon24;

export default function DetailsModalActionsDropdown({
  mediaGroupId,
}: {
  mediaGroupId: string;
}) {
  const { data: mediaGroupData } = useMediaGroupDetail(mediaGroupId);
  const mediaGroupProject = mediaGroupData?.mediaGroup.project;
  const { data: projectData } = useProject(mediaGroupProject?.id ?? null);
  const { project = null } = projectData || {};
  const { canEdit } = useMediaGroupPermissions(mediaGroupData?.mediaGroup);
  const workspaceId = useWorkspaceId();
  const { data: projectsData } = useWorkspaceProjects(workspaceId, {
    status: activeProjectKeys,
  });
  const { projects = [] } = projectsData || {};
  const {
    copy,
    move,
    delete: deleteMG,
  } = useMediaGroupTransfer(mediaGroupData?.mediaGroup);

  if (!mediaGroupData) return null;
  const otherProjects = project
    ? projects.filter((workspaceProject) => workspaceProject.id !== project.id)
    : projects;

  const menuItemCopyLink: ContextMenuItemProps = {
    content: <TriggerButton iconBefore={<Link size={20} />}>Copy link</TriggerButton>,
    onClick: () => copyUrlToClipboard(),
  };

  const menuItemCopy: ContextMenuItemProps = {
    content: (
      <TriggerButton iconBefore={<Copy size={16} />} iconAfter={<Right />}>
        Copy to...
      </TriggerButton>
    ),
    subMenu: makeAddToProjectMenu({
      projects: otherProjects,
      onAddToProject: (projectId: string) => {
        copy({ mediaGroupId: mediaGroupData.mediaGroup.id, projectId });
      },
      onAddToRepository: () => {
        copy({
          mediaGroupId: mediaGroupData.mediaGroup.id,
        });
      },
    }),
  };

  const menuItemMove: ContextMenuItemProps = {
    content: (
      <TriggerButton iconBefore={<Move size={20} />} iconAfter={<Right />}>
        Move to...
      </TriggerButton>
    ),
    subMenu: makeAddToProjectMenu({
      projects: otherProjects,
      onAddToProject: (projectId: string) => {
        move({ mediaGroupIds: [mediaGroupData.mediaGroup.id], projectId });
      },
      onAddToRepository:
        (mediaGroupData.mediaGroup.project &&
          (() => {
            move({
              mediaGroupIds: [mediaGroupData.mediaGroup.id],
            });
          })) ||
        undefined,
    }),
  };

  const separator = {
    content: <Separator color={1} />,
    isSeparator: true,
  };

  const assetUrl = mediaGroupData.mediaGroup.media[0]?.assetUrl ?? null;

  const menuItemDownload: ContextMenuItemProps | null = assetUrl
    ? {
        content: (
          <TriggerButton iconBefore={<Download size={20} />} iconAfter={<Right />}>
            Download
          </TriggerButton>
        ),
        width: 212,
        subMenu: [
          {
            content: (
              <TriggerButton
                iconBefore={<Download size={20} />}
                onClick={() => downloadUrl(assetUrl)}
              >
                Download this item
              </TriggerButton>
            ),
          },
        ],
      }
    : null;

  const viewersMenuItem: ContextMenuItemProps[] = [
    menuItemCopyLink,
    separator,
    menuItemDownload,
  ].filter(exists);

  const editorsMenuItems: ContextMenuItemProps[] = [
    menuItemCopyLink,
    ...(otherProjects.length ? [separator, menuItemCopy, menuItemMove] : []),
    separator,
    menuItemDownload,
    {
      content: (
        <TriggerButton iconBefore={<TrashCan size={20} />}>Delete</TriggerButton>
      ),
      onClick: () => deleteMG(mediaGroupData.mediaGroup.id),
    },
  ].filter(exists);

  return (
    <RecursiveDropdownMenu
      isUnstyled={false}
      items={canEdit ? editorsMenuItems : viewersMenuItem}
      dropdownMenuProps={{
        width: 240,
        isPadded: true,
        align: 'end',
      }}
      showIndicator={false}
      dropdownMenuItemClassName={dropdownMenuStyles.menuItem}
    >
      <span>
        <Tooltip content="Options" size="medium" variant="secondary">
          <Button variant="icon">
            <Menu size={20} />
          </Button>
        </Tooltip>
      </span>
    </RecursiveDropdownMenu>
  );
}

const makeAddToProjectMenu = ({
  projects,
  onAddToProject,
  onAddToRepository,
}: {
  projects: ProjectDTO[];
  onAddToProject: (projectId: string) => void;
  onAddToRepository?: () => void;
}) => {
  const addToRepositoryOption = [];
  if (onAddToRepository) {
    addToRepositoryOption.push({
      content: (
        <TriggerButton iconBefore={<RepositoryBrowse size={20} />}>
          Repository
        </TriggerButton>
      ),
      onClick: onAddToRepository,
    });
  }
  const projectOptions = projects.map(({ id, label, mode }) => ({
    content: (
      <TriggerButton iconBefore={<ProjectMode mode={mode} size={20} />}>
        {label}
      </TriggerButton>
    ),
    onClick: () => {
      onAddToProject?.(id);
    },
  }));

  return [...addToRepositoryOption, ...projectOptions];
};
