import { Icon16, Icon24 } from '@spaceduck/icons';
import type { Editor } from '@tiptap/react';

import { css } from '@lib/css';
import ScrollArea from '@ui/ScrollArea';
import type { MenuView } from '../MediaGroup';
import styles from './BlockMenu.module.scss';
import Button from './Button';

const { BulletedList, NumberedList } = Icon16;
const {
  CloseKeyboard,
  IndentBackward,
  IndentForward,
  Image,
  Link,
  New,
  TextFormat,
  ToDo,
} = Icon24;

export default function BlockMenu({
  editor,
  setView,
}: {
  editor: Editor;
  setView: React.Dispatch<React.SetStateAction<MenuView>>;
}) {
  const handleUploadBrowse = (_: React.MouseEvent<HTMLButtonElement>) => {
    // TODO: Open file system explore menu
    document.getElementById('webViewImageUploadInput')?.click();
  };

  return (
    <div className={styles.blockMenu}>
      <ScrollArea
        className={styles.scrollArea}
        orientation="horizontal"
        style={css({
          height: '100%',
          maxWidth: 'calc(100% - var(--size-14))',
        })}
      >
        <div className={styles.scrollable}>
          <Button
            onClick={() => {
              editor.chain().focus();
              setView('formatMenu');
            }}
          >
            <TextFormat size={20} />
          </Button>
          <Button
            onClick={() => editor.chain().focus().toggleTaskList().run()}
            isActive={editor.isActive('taskList')}
          >
            <ToDo size={20} />
          </Button>
          <Button
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            isActive={editor.isActive('orderedList')}
          >
            <NumberedList size={20} />
          </Button>
          <Button
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            isActive={editor.isActive('bulletList')}
          >
            <BulletedList size={20} />
          </Button>
          <Button
            onClick={() => editor.chain().focus().sinkListItem('listItem').run()}
            disabled={!editor.can().sinkListItem('listItem')}
          >
            <IndentForward size={20} />
          </Button>
          <Button
            onClick={() => editor.chain().focus().liftListItem('listItem').run()}
            disabled={!editor.can().liftListItem('listItem')}
          >
            <IndentBackward size={20} />
          </Button>
          <Button onClick={() => setView('mediaGroupMenu')}>
            <New size={20} />
          </Button>
          <Button onClick={() => setView('linkForm')}>
            <Link size={20} />
          </Button>
          <Button onClick={handleUploadBrowse}>
            <Image size={20} />
          </Button>
        </div>
      </ScrollArea>
      <div className={styles.hideKeyboard}>
        <Button
          onClick={() => editor.chain().blur().run()}
          disabled={!editor.isFocused}
        >
          <CloseKeyboard size={20} />
        </Button>
      </div>
    </div>
  );
}
