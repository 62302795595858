import type { SearchQuery } from '@/hooks/useSearch';
import type { MediaGroupFiltersSchema } from '@spaceduck/api';
import type { RefObject } from 'react';
import { create } from 'zustand';

type SearchStore = {
  attributionToken: string | null;
  setAttributionToken: (currentAttributionToken: string | null) => void;
  searchElementRef: RefObject<HTMLInputElement>;
  setSearchElementRef: (searchElementRef: RefObject<HTMLInputElement>) => void;
  searchQueries: Array<SearchQuery>;
  setSearchQueries: (searchQueries: Array<SearchQuery>) => void;
  searchInputValue: string;
  setSearchInputValue: (searchInputValue: string) => void;
  showAltPlaceholder: boolean;
  setShowAltPlaceholder: (showAltPlaceholder: boolean) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  debouncedSearchValue: string | undefined;
  setDebouncedSearchValue: (debouncedSearchValue: string | undefined) => void;
  mediaGroupFilters: MediaGroupFiltersSchema;
  setMediaGroupFilters: (mediaGroupFilters: MediaGroupFiltersSchema) => void;
  excludeProjectLibraries: boolean;
  setExcludeProjectLibraries: (excludeProjectLibraries: boolean) => void;
};

export const useSearchStore = create<SearchStore>()((set) => ({
  attributionToken: null,
  setAttributionToken: (attributionToken: string | null) =>
    set(() => ({ attributionToken })),
  searchElementRef: { current: null },
  setSearchElementRef: (searchElementRef) => set(() => ({ searchElementRef })),
  searchQueries: [],
  setSearchQueries: (searchQueries: Array<SearchQuery>) =>
    set(() => ({ searchQueries })),
  searchInputValue: '',
  setSearchInputValue: (searchInputValue: string) => set(() => ({ searchInputValue })),
  showAltPlaceholder: false,
  setShowAltPlaceholder: (showAltPlaceholder: boolean) =>
    set(() => ({ showAltPlaceholder })),
  isLoading: false,
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
  debouncedSearchValue: undefined,
  setDebouncedSearchValue: (debouncedSearchValue: string | undefined) =>
    set(() => ({ debouncedSearchValue })),
  mediaGroupFilters: {},
  setMediaGroupFilters: (mediaGroupFilters: MediaGroupFiltersSchema) =>
    set(() => ({ mediaGroupFilters })),
  excludeProjectLibraries: false,
  setExcludeProjectLibraries: (excludeProjectLibraries: boolean) =>
    set(() => ({ excludeProjectLibraries })),
}));
