import BulletList from '@tiptap/extension-bullet-list';
import { wrappingInputRule } from '@tiptap/react';

export default BulletList.extend({
  addInputRules() {
    return [
      wrappingInputRule({
        // Overriding default regex to stop '+' from adding list item
        find: /^\s*([-*])\s$/,
        type: this.type,
      }),
    ];
  },
});
