import { useMediaGroupReadableSummary } from '@api/mediaGroup';
import type { MediaGroupDetailDTO } from '@spaceduck/api';
import LoadingPlaceholder from '../LoadingPlaceholder';

import { usePoll } from '@/hooks/usePoll';
import { useCallback } from 'react';
import Button from '../ui/Button';
import styles from './ReadingModeView.module.scss';

const LoadingPane = () => (
  <div className={styles.pane}>
    <div className={styles.content}>
      <LoadingPlaceholder message="Loading readable content..." />
    </div>
  </div>
);

const ErrorPane = ({ onRetry }: { onRetry: () => Promise<void> }) => (
  <div className={styles.pane}>
    <div className={styles.content}>
      <div>Error fetching reading mode content. Please try again later.</div>
      <div className={styles.actions}>
        <Button onClick={onRetry}>Retry</Button>
      </div>
    </div>
  </div>
);

const NotAvailablePane = () => (
  <div className={styles.pane}>
    <div className={styles.content}>
      <div>
        Reading mode content not available or not supported for this webpage. Sorry!
      </div>
    </div>
  </div>
);

export const ReadingModeView = ({
  mediaGroup,
}: { mediaGroup: MediaGroupDetailDTO }) => {
  const { data, status, refetch } = useMediaGroupReadableSummary(mediaGroup.id);

  const handleRetry = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const shouldPoll =
    status === 'success' && data.kind === 'readableSummaryNotAvailable' && data.pending;
  const pollFn = useCallback(() => refetch(), []);
  usePoll(5000, shouldPoll, pollFn);

  if (status === 'error') {
    return <ErrorPane onRetry={handleRetry} />;
  }

  if (status === 'pending') {
    return <LoadingPane />;
  }

  if (data.kind === 'readableSummaryNotAvailable') {
    if (data.pending) {
      return <LoadingPane />;
    }
    return <NotAvailablePane />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {/* biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation> */}
        <div dangerouslySetInnerHTML={{ __html: data.html }} />
      </div>
    </div>
  );
};
