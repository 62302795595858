import { Icon16 } from '@spaceduck/icons';
import { useState } from 'react';

import { NewMediaGroupDropdown } from '@components/NewMediaGroupDropdown';
import Button from '@ui/Button';
import styles from './CreateRowButton.module.scss';

const { Add } = Icon16;

export default function CreateRowButton({
  canEdit,
  categoryId,
}: {
  canEdit: boolean;
  categoryId: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  if (!canEdit) return null;

  return (
    <div className={styles.addRow} onClick={() => setIsOpen(true)}>
      <div className={styles.addRowButtonContainer}>
        <NewMediaGroupDropdown
          align="start"
          mediaGroupAttributes={{ categoryId }}
          open={isOpen}
          setOpen={setIsOpen}
        >
          <Button
            className={styles.addRowButton}
            iconBefore={<Add />}
            size="sm"
            variant="ghost"
          >
            Add new content
          </Button>
        </NewMediaGroupDropdown>
      </div>
    </div>
  );
}
