import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu';
import type { CreateMediaGroupSchema } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';
import clsx from 'clsx';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useCreateDocumentMediaGroup } from '@api/mediaGroup';
import { isCapable } from '@api/util';
import { useWorkspace } from '@api/workspace';
import { useFileUploadWrapper } from '@hooks/useFileUploadWrapper';
import useWorkspaceId from '@hooks/useWorkspaceId';
import sharedProjectStyles from '@pages/projects/Shared.module.scss';
import Button, { type ButtonVariant } from '@ui/Button';
import DropdownMenu from '@ui/DropdownMenu';

const { Add, Document } = Icon16;
const { ImageUpload } = Icon24;

export const NewMediaGroupDropdown = ({
  align = 'end',
  children,
  mediaGroupAttributes,
  open,
  setOpen,
  showTriggerIcon = true,
  triggerText,
  variant = 'outlined',
}: {
  align?: 'start' | 'end' | 'center';
  children?: React.ReactNode;
  mediaGroupAttributes?: Omit<CreateMediaGroupSchema, 'kind' | 'workspaceId'>;
  open?: boolean;
  setOpen?: (state: boolean) => void;
  showTriggerIcon?: boolean;
  triggerText?: string;
  variant?: ButtonVariant;
}) => {
  const workspaceId = useWorkspaceId();
  const { data: workspace } = useWorkspace(workspaceId);
  const projectId = useParams<{ projectId?: string }>().projectId;
  const createDocument = useCreateDocumentMediaGroup();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { handleChange } = useFileUploadWrapper({
    ...mediaGroupAttributes,
    workspaceId,
    projectId,
  });

  const canAddDocument = isCapable(
    'createDocument',
    workspace?.workspace.capabilities
  ).capable;
  return (
    <DropdownMenu
      open={open}
      setOpen={setOpen}
      align={align}
      className={clsx('menu', sharedProjectStyles.dropdownMenu)}
      triggerContent={
        <div>
          {children ?? (
            <Button
              className={sharedProjectStyles.createButton}
              iconBefore={showTriggerIcon ? <Add size={16} /> : undefined}
              size="sm"
              type="button"
              variant={variant}
            >
              {triggerText ?? (
                <>
                  <span className={sharedProjectStyles.desktop}>New item</span>
                  <span className={sharedProjectStyles.mobile}>New</span>
                </>
              )}
            </Button>
          )}
          <input
            type="file"
            multiple
            ref={fileInputRef}
            onChange={handleChange}
            style={{ display: 'none' }}
          />
        </div>
      }
    >
      <DropdownMenuItem
        className={sharedProjectStyles.dropdownMenuItem}
        onClick={(ev) => {
          ev.stopPropagation();
          setOpen?.(false);
          fileInputRef?.current?.click();
        }}
      >
        <div className={sharedProjectStyles.icon}>
          <ImageUpload />
        </div>
        <div className={sharedProjectStyles.text}>
          <div className={sharedProjectStyles.title}>Media & Files</div>
          <div className={sharedProjectStyles.description}>
            Images, Videos, PDFs, etc
          </div>
        </div>
      </DropdownMenuItem>
      {canAddDocument && (
        <DropdownMenuItem
          className={sharedProjectStyles.dropdownMenuItem}
          onClick={(ev) => {
            ev.stopPropagation();
            setOpen?.(false);
            createDocument({
              ...mediaGroupAttributes,
              workspaceId: workspaceId ? workspaceId : undefined,
              projectId: projectId,
            });
          }}
        >
          <div className={sharedProjectStyles.icon}>
            <Document />
          </div>
          <div className={sharedProjectStyles.text}>
            <div className={sharedProjectStyles.title}>Document</div>
            <div className={sharedProjectStyles.description}>Notes</div>
          </div>
        </DropdownMenuItem>
      )}
    </DropdownMenu>
  );
};
