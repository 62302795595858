import { deleteProjectWebLink, patchProjectWebLink } from '@spaceduck/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { projectKeys } from './project';

export const usePatchProjectWebLink = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: patchProjectWebLink,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: projectKeys.one(data.webLink.project.id),
      });
    },
  });
  return mutation;
};

export const useDeleteProjectWebLink = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: deleteProjectWebLink,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: projectKeys.one(data.projectId),
      });
    },
  });
  return mutation;
};
