import * as RadixTabs from '@radix-ui/react-tabs';
import { clsx } from 'clsx';
import { forwardRef } from 'react';

import type { DetailsModelTab } from '@/types/MediaGroup';
import TabButton, {
  type TabButtonProps,
  type TabButtonVariants,
  TabButtonWrapper,
  type TabButtonWrapperProps,
} from '@ui/TabButton';
import styles from './Tabs.module.scss';

export type Tab = {
  content: React.ReactNode;
  icon?: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
  ref?: React.RefObject<HTMLButtonElement>;
  title?: React.ReactNode;
  value?: DetailsModelTab;
};

type TabsProps = {
  activeTab?: string;
  asTriggerButtons?: boolean;
  className?: string;
  contentClassName?: string;
  label?: string;
  onClick?: (value?: string) => void;
  orientation?: 'horizontal' | 'vertical';
  setActiveTab?: (value: string) => void;
  tabs: Tab[];
  tabButtonProps?: TabButtonProps;
  tabWrapperProps?: Omit<TabButtonWrapperProps, 'children'>;
};

export default function Tabs({
  activeTab,
  asTriggerButtons,
  className,
  contentClassName,
  label,
  onClick,
  orientation = 'horizontal',
  setActiveTab,
  tabs,
  tabButtonProps,
  tabWrapperProps,
}: TabsProps) {
  if (!tabs.length) return;

  const defaultValue = (tabs.findIndex((tab) => tab.isActive) ?? 0).toString();
  const handleTabChange = (value: string) => {
    setActiveTab?.(value);
  };

  return (
    <RadixTabs.Root
      className={clsx(styles.tabsRoot, styles[orientation], className)}
      defaultValue={defaultValue}
      onValueChange={handleTabChange}
      orientation={orientation}
      value={activeTab}
    >
      <Triggers
        asTriggerButtons={asTriggerButtons}
        label={label}
        onClick={onClick}
        tabButtonProps={tabButtonProps}
        tabs={tabs}
        tabWrapperProps={tabWrapperProps}
      />
      {tabs.map(({ content, value }, idx) => (
        <RadixTabs.Content
          className={clsx(styles.tabsContent, contentClassName)}
          key={idx}
          value={value ?? idx.toString()}
        >
          {content}
        </RadixTabs.Content>
      ))}
    </RadixTabs.Root>
  );
}

type TriggersProps = {
  asTriggerButtons?: boolean;
  onClick?: (value?: string) => void;
  label?: string;
  tabs: Tab[];
  tabButtonProps?: TabButtonProps;
  tabWrapperProps?: Omit<TabButtonWrapperProps, 'children'>;
};

const Triggers = ({
  asTriggerButtons,
  onClick,
  label,
  tabs,
  tabButtonProps,
  tabWrapperProps,
}: TriggersProps) => {
  switch (asTriggerButtons) {
    case true:
      return (
        <RadixTabs.List aria-label={label} asChild>
          <TabButtonWrapper
            {...tabWrapperProps}
            variant={tabWrapperProps?.variant ?? 'dark'}
          >
            {tabs.map((tab, idx) => (
              <Trigger
                asTriggerButtons
                onClick={() => onClick?.(tab.value)}
                idx={idx}
                key={idx}
                ref={tab.ref}
                tab={tab}
                tabButtonProps={tabButtonProps}
                variant={tabWrapperProps?.variant}
              />
            ))}
          </TabButtonWrapper>
        </RadixTabs.List>
      );
    default:
      return (
        <RadixTabs.List className={styles.tabsList} aria-label={label}>
          {tabs.map((tab, idx) => (
            <Trigger idx={idx} key={idx} ref={tab.ref} tab={tab} />
          ))}
        </RadixTabs.List>
      );
  }
};

type TriggerProps = {
  asTriggerButtons?: boolean;
  idx: number;
  onClick?: () => void;
  tab: Tab;
  tabButtonProps?: TabButtonProps;
  variant?: TabButtonVariants;
};

const Trigger = forwardRef<HTMLButtonElement, TriggerProps>(
  (
    { asTriggerButtons, idx, onClick, tab, tabButtonProps, variant }: TriggerProps,
    ref
  ) => {
    const { icon, onClick: tabOnClick, title, value } = tab;

    switch (asTriggerButtons) {
      case true:
        return (
          <RadixTabs.Trigger
            asChild
            onClick={() => onClick?.()}
            ref={ref}
            value={value ?? idx.toString()}
          >
            <TabButton {...tabButtonProps} variant={variant ?? 'dark'}>
              {icon}
              {title}
            </TabButton>
          </RadixTabs.Trigger>
        );
      default:
        return (
          <RadixTabs.Trigger
            className={styles.tabsTrigger}
            onClick={() => (tabOnClick ? tabOnClick() : onClick?.())}
            ref={ref}
            value={value ?? idx.toString()}
          >
            {icon}
            {title}
          </RadixTabs.Trigger>
        );
    }
  }
);
