import { useTags } from '@/api/tags';
import { useDebouncedValue } from '@hooks/useDebouncedValue';
import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import useWorkspaceId from './useWorkspaceId';

export const useTagSuggestions = () => {
  const workspaceId = useWorkspaceId();
  const [tagInput, setTagInput] = useState('');
  const debouncedSearchQuery = useDebouncedValue(tagInput);
  const { ref, inView } = useInView();

  const {
    data: paginator,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useTags(workspaceId, debouncedSearchQuery);

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage, isFetchingNextPage]);

  const workspaceTags = paginator?.pages.flatMap((page) => page.tags);
  const allTags = workspaceTags?.map((tag) => tag.label) ?? [];

  const results = useMemo(() => {
    const pattern = new RegExp(tagInput, 'i');
    return allTags.filter((tag) => pattern.test(tag));
  }, [tagInput, allTags]);

  return {
    inView,
    ref,
    results,
    setTagInput,
    tagInput,
  };
};
