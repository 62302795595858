import { Icon24 } from '@spaceduck/icons';
import type { QueryStatus } from '@tanstack/react-query';
import clsx from 'clsx';
import { useState } from 'react';

import styles from './Search.module.scss';

const { Loading, Search: SearchIcon } = Icon24;

type SearchProps = {
  className?: string;
  collapsible?: boolean;
  defaultExpanded?: boolean;
  defaultValue: string;
  onInput: (ev: React.FormEvent<HTMLInputElement>) => void;
  placeholder?: string;
  size?: 'sm' | 'md';
  status: QueryStatus;
};

export default function Search({
  className,
  collapsible = false,
  defaultExpanded = true,
  defaultValue,
  onInput,
  placeholder = '',
  size = 'md',
  status,
}: SearchProps) {
  const [expanded, setExpanded] = useState(collapsible ? defaultExpanded : true);

  return (
    <div
      className={clsx(
        styles.searchBox,
        styles[size],
        !expanded && styles.collapsed,
        className
      )}
    >
      <div className="formGroup">
        <span
          className={clsx(styles.icon, collapsible && styles.clickable)}
          onClick={(ev) => {
            if (collapsible) {
              ev.stopPropagation();
              setExpanded((expanded) => !expanded);
            }
          }}
        >
          {status === 'pending' ? (
            <div className={styles.spin}>
              <Loading size={20} />
            </div>
          ) : (
            <SearchIcon size={20} />
          )}
        </span>
        <input
          defaultValue={defaultValue}
          onInput={onInput}
          placeholder={placeholder}
          type="search"
        />
      </div>
    </div>
  );
}
