import { Icon24 } from '@spaceduck/icons';
import Button from '@ui/Button';
import DropdownMenu from '@ui/DropdownMenu';
import styles from './EditMenu.module.scss';
const { Menu } = Icon24;

export default function EditMenu({ children }: { children: React.ReactNode }) {
  return (
    <DropdownMenu
      align="end"
      className={styles.dropdownMenu}
      isPadded
      triggerContent={
        <Button variant="icon" className={styles.dropdownMenuTrigger}>
          <Menu />
        </Button>
      }
      width={170}
    >
      <div>{children}</div>
    </DropdownMenu>
  );
}
