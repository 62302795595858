import { clsx } from 'clsx';
import { useNavigate } from 'react-router-dom';

import ErrorDuck from '@assets/img/ErrorDuck';
import Button from '@ui/Button';
import { getDuckFact } from '@utils/getDuckFact';
import { openMailTo } from '@utils/openMailTo';
import styles from './ErrorPage.module.scss';

export default function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <div className={clsx('body5', styles.container)}>
      <ErrorDuck />
      <h1 className="h6">Server Failed Successfully...</h1>
      <p>“{getDuckFact()}.”</p>
      <div className={styles.buttonGroup}>
        <Button onClick={() => navigate('/')}>Go to the Spaceduck Homepage</Button>
        <Button variant="secondary" onClick={() => openMailTo('[SUPPORT]', '')}>
          Contact Support
        </Button>
      </div>
    </div>
  );
}
