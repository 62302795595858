import { useWindowWidth } from '@react-hook/window-size';
import { Fragment, useEffect, useRef, useState } from 'react';

import { css } from '@/lib/css';
import { calcCardHeight } from '@utils/mediaGroup';
import styles from './MasonryGrid.module.scss';

type Item = {
  card: React.ReactNode;
  isNotes?: boolean;
  thumbHeight: number | null;
  thumbWidth: number | null;
};

export type Breakpoints = {
  sm: number;
  md: number;
  lg: number;
  xl: number;
};

type MasonryGridProps = {
  breakpoints?: Breakpoints;
  items: Item[];
};

type CardColumn = {
  cards: React.ReactNode[];
  height: number;
};

const GRID_GAP = 24;
const defaultBreakpoints: Breakpoints = {
  sm: 768,
  md: 1200,
  lg: 1441,
  xl: 1920,
} as const;

function getNumberOfColumns(breakpoints: Breakpoints, windowWidth: number) {
  switch (true) {
    case windowWidth < breakpoints.sm:
      return 1;
    case windowWidth < breakpoints.md:
      return 2;
    case windowWidth < breakpoints.lg:
      return 3;
    case windowWidth < breakpoints.xl:
      return 4;
    default:
      return 5;
  }
}

export default function MasonryGrid({
  breakpoints = defaultBreakpoints,
  items,
}: MasonryGridProps) {
  const width = useWindowWidth();
  const containerRef = useRef<HTMLDivElement>(null);
  const [columnCount, setColumnCount] = useState(
    getNumberOfColumns(breakpoints, width)
  );
  const [cardColumns, setCardColumns] = useState<CardColumn[]>([]);

  useEffect(() => {
    setColumnCount(getNumberOfColumns(breakpoints, width));
  }, [breakpoints, width]);

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth =
        containerRef.current.clientWidth - (columnCount - 1) * GRID_GAP;
      const columnWidth = containerWidth / columnCount;
      const tempColumns: CardColumn[] = Array(columnCount)
        .fill(null)
        .map(() => ({ height: 0, cards: [] }));

      items.forEach((item) => {
        const shortestColumn = tempColumns.reduce((a, b) =>
          a.height <= b.height ? a : b
        );

        const cardHeight = calcCardHeight({
          columnWidth,
          isNotes: item.isNotes,
          thumbNailHeight: item.thumbHeight,
          thumbNailWidth: item.thumbWidth,
        });

        shortestColumn.height += cardHeight + GRID_GAP;
        shortestColumn.cards.push(item.card);
      });

      setCardColumns(tempColumns);
    }
  }, [columnCount, containerRef.current?.clientWidth, items]);

  return (
    <div
      className={styles.grid}
      ref={containerRef}
      style={css({ '--column-count': `${columnCount}` })}
    >
      {cardColumns.map((column, idx) => (
        <div className={styles.column} key={idx}>
          {column.cards.map((card, cardIdx) => (
            <Fragment key={cardIdx}>{card}</Fragment>
          ))}
        </div>
      ))}
    </div>
  );
}
