import Head from '@components/Head';

export default function SupportedFileTypesPage() {
  const supportedImageFileTypes = [
    'jpg',
    'png',
    'svg',
    'gif',
    'webp',
    'avif',
    'heif',
    'heic',
  ];

  const supportedVideoFileTypes = ['mp4', 'mov', 'webm'];

  return (
    <>
      <Head title="Supported file types" />
      <div className="body4">
        <h1 className="h5">Supported links, files and sizes</h1>
        <p>
          For any issues, regarding Spaceduck, please reach out to us on{' '}
          <a href="mailto:support@spaceduck.com" target="_blank" rel="noreferrer">
            support@spaceduck.com
          </a>{' '}
          or join our Slack.
        </p>
        <hr />
        <p className="body2">
          Spaceduck supports a variety of links and files in your posts. Below is a
          detailed overview of each. If there is a link or file type we don't currently
          support, please reach out to us at{' '}
          <a href="mailto:support@spaceduck.com" target="_blank" rel="noreferrer">
            support@spaceduck.com
          </a>
        </p>
        <h2 className="title1">Supported files</h2>
        <h3 className="subtitle2">Supported image files</h3>
        <ul className="ul">
          {supportedImageFileTypes.map((fileType, idx) => (
            <li key={idx}>{fileType}</li>
          ))}
        </ul>
        <h3 className="subtitle2">Supported video files</h3>
        <ul className="ul">
          {supportedVideoFileTypes.map((fileType, idx) => (
            <li key={idx}>{fileType}</li>
          ))}
        </ul>
        <h2 className="title1">File size support</h2>
        <h3 className="subtitle2">Max file size</h3>
        <p>The maximum file size for each file is 250MB</p>
      </div>
    </>
  );
}
