import { create } from 'zustand';

import { sessionStore } from '@/singleton';
import type { Session } from '@spaceduck/api';

type WorkspaceStore = {
  // Server-provided default workspace
  fallbackWorkspaceId: string | null;
  // Sticky workspace reference - when a user interacts with a workspace, it
  //  becomes the "selected" workspace for the session
  sessionWorkspaceId: string | null;
  // Focused. The user interacting with a related entity (like a project) that
  //  may need to load in the workspace. This becomes sticky (by updating the
  // session workspace) when is is set non-null.
  focusedWorkspaceId: string | null | undefined;
  setFallbackWorkspaceId: (workspaceId: string | null) => void;
  setSessionWorkspaceId: (workspaceId: string | null) => void;
  setFocusedWorkspaceId: (workspaceId: string | null | undefined) => void;
  ingestSession: (session: Session | null) => void;
};

const getFavoriteWorkspaceId = (session: Session | null) => {
  const workspaces = session?.kind === 'authenticated' ? session.workspaces : [];
  return workspaces?.[0]?.id ?? null;
};

const useStore = create<WorkspaceStore>((set, get) => ({
  fallbackWorkspaceId: getFavoriteWorkspaceId(sessionStore.getCurrentSession()),
  sessionWorkspaceId: null,
  focusedWorkspaceId: null,

  setFallbackWorkspaceId: (fallbackWorkspaceId: string | null) => {
    const current = get();
    if (fallbackWorkspaceId !== current.fallbackWorkspaceId) {
      set({ fallbackWorkspaceId });
    }
  },
  setSessionWorkspaceId: (sessionWorkspaceId: string | null) => {
    const current = get();
    if (sessionWorkspaceId !== current.sessionWorkspaceId) {
      set({ sessionWorkspaceId, focusedWorkspaceId: null });
    }
  },
  setFocusedWorkspaceId: (focusedWorkspaceId: string | null | undefined) => {
    const current = get();
    const patch: Partial<WorkspaceStore> = {};

    if (focusedWorkspaceId !== current.focusedWorkspaceId) {
      patch.focusedWorkspaceId = focusedWorkspaceId;
    }
    if (focusedWorkspaceId && focusedWorkspaceId !== current.sessionWorkspaceId) {
      // Successfully loaded the workspace, make it sticky!
      patch.sessionWorkspaceId = focusedWorkspaceId;
    }
    set(patch);
  },

  ingestSession: (session: Session | null) => {
    const fallbackWorkspaceId = getFavoriteWorkspaceId(session);

    const current = get();
    if (fallbackWorkspaceId !== current.fallbackWorkspaceId) {
      set({ fallbackWorkspaceId });
    }
  },
}));

sessionStore.addEventListener('sessionUpdate', ({ session }) => {
  useStore.getState().ingestSession(session);
});

export const useWorkspaceStore = () => {
  const { fallbackWorkspaceId, focusedWorkspaceId, sessionWorkspaceId, ...rest } =
    useStore();
  return {
    ...rest,
    workspaceId: focusedWorkspaceId ?? sessionWorkspaceId ?? fallbackWorkspaceId,
  };
};
