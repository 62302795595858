import { Icon16 } from '@spaceduck/icons';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';

import { useWorkspace } from '@/api/workspace';
import { css } from '@/lib/css';
import { useCreateMediaGroup } from '@api/mediaGroup';
import type { CreateMediaGroupSchema } from '@spaceduck/api';
import Toast from '@ui/Toast';
import createToast, { warningToast } from '@utils/createToast';
import { useProcessAssets } from './useProcessAssets';

const { Image, Video, File } = Icon16;

const getIcon = (file: File) => {
  if (file.type.startsWith('video/')) {
    return <Video />;
  }
  if (file.type.startsWith('image/')) {
    return <Image />;
  }
  return <File />;
};

const UPLOAD_PROGRESS_TOAST_ID = 'progressToast';

export const useFileUploadWrapper = (
  mediaGroupAttributes: Omit<CreateMediaGroupSchema, 'kind'>
) => {
  const { workspaceId, projectId, ...attributesRest } = mediaGroupAttributes;
  const workspace = useWorkspace(workspaceId || null);
  const { mutateAsync: createMediaGroup } = useCreateMediaGroup();
  const [dragActive, setDragActive] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const { insert, pending, isLoading } = useProcessAssets({
    pollInterval: 1500,
    onSuccess: async (item) => {
      if (!item.request.result) {
        return;
      }
      if (!workspaceId) {
        console.error('Processed asset abandoned due to missing workspaceId');
        createToast({
          titleText: 'Error adding content',
          bodyText: 'Please try again later',
          iconVariant: 'warning',
        });
        return;
      }
      try {
        await createMediaGroup({
          kind: 'gallery',
          ...attributesRest,
          assets: [item.request.result.id],
          workspaceId: projectId ? undefined : workspaceId,
          projectId,
        });
        createToast({
          bodyText: item.file?.name ? `Uploaded ${item.file.name}` : 'Uploaded!',
          iconVariant: 'success',
        });
      } catch (err) {
        warningToast({
          message: `"${item.file.name}" could not be uploaded.`,
        });
      }
    },
    onFail: (item) => {
      warningToast({
        message: `"${item.file.name}" could not be uploaded.`,
      });
    },
    onReject: (item) => {
      warningToast({
        message: `"${item.file.name}" could not be uploaded.`,
      });
    },
    onError: () => {
      warningToast({
        message: 'Something went wrong. Please try again or contact support.',
      });
    },
  });

  const handleDrag = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (ev.type === 'dragenter' || ev.type === 'dragover') {
      setDragActive(true);
    } else if (ev.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const processIncomingFiles = (incomingFileList: FileList | null) => {
    if (!incomingFileList || !incomingFileList[0]) return;
    if (!workspaceId || !workspace) {
      console.error('Dropped file discarded due to missing workspaceId');
      createToast({
        titleText: 'Error uploading content',
        bodyText: 'Please try again later',
        iconVariant: 'warning',
      });
      return;
    }
    setDragActive(false);
    insert({ workspaceId, files: incomingFileList });
  };

  const handleDrop = (ev: React.DragEvent<HTMLLabelElement>) => {
    const incomingFileList = ev.dataTransfer.files;
    ev.preventDefault();
    ev.stopPropagation();
    processIncomingFiles(incomingFileList);
  };

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const incomingFileList = ev.target.files;
    setDragActive(false);
    processIncomingFiles(incomingFileList);
  };

  const pendingCount = pending.length;
  const collapsibleHeaderExpandedHeight = 49;
  const collapsibleHeaderCollapsedHeight = 50;
  const collapsibleItemHeight = 48;
  const collapsibleContentGap = 4;
  const collapsibleContentBox = 18;
  const maxItemsDisplayed = 5;

  const calcHeight = (numberOfItems: number) =>
    collapsibleHeaderExpandedHeight +
    numberOfItems * collapsibleItemHeight +
    (numberOfItems - 1) * collapsibleContentGap +
    collapsibleContentBox;

  useEffect(() => {
    if (pending.length === 0) {
      toast.dismiss(UPLOAD_PROGRESS_TOAST_ID);
      return;
    }
    toast.custom(
      () => (
        <Toast
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          iconVariant="loading"
          processList={pending.map((x) => ({
            id: x.key,
            icon: getIcon(x.file),
            text: x.file.name,
            status: 'processing',
          }))}
        >
          Saving {pendingCount} new item{pendingCount > 1 ? 's' : ''} to your{' '}
          {projectId ? 'space' : 'repository'}...
        </Toast>
      ),
      {
        duration: Number.POSITIVE_INFINITY,
        id: UPLOAD_PROGRESS_TOAST_ID,
        style: css({
          '--max-width': '25rem',
          '--initial-height': isOpen
            ? `${Math.min(calcHeight(pending.length), calcHeight(maxItemsDisplayed))}px`
            : `${collapsibleHeaderCollapsedHeight}px`,
        }),
      }
    );
  }, [pendingCount, isOpen]);

  return {
    dragActive,
    setDragActive,
    handleChange,
    handleDrag,
    handleDrop,
    uploadIsInProgress: isLoading,
  };
};
